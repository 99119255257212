import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal, Col, Button, Tooltip } from "reactstrap";

const Modals = ({ id, question, prompt, title }) => {
  const [toggle, setToggle] = useState(false);
  const [tooltipToogle, setTooltipToogle] = useState(false);
  const [text, setText] = useState("");
  const [formattedQuestion, setFormattedQuestion] = useState("");
  useEffect(() => {
    let patternQuestion = /<question>(.*?)<question>/;
    let questionText = prompt.match(patternQuestion);
    if (questionText) {
      setFormattedQuestion(questionText[1]);
    } else {
      let pattern = /<pergunta>(.*?)<pergunta>/;
      let questionTranslated = prompt.match(pattern);
      if (questionTranslated) {
        setFormattedQuestion(questionTranslated[1]);
      } else {
        setFormattedQuestion(question);
      }
    }

    let pattern = /<.*?>/g;
    let cleanedText = prompt.replace(pattern, "");
    setText(cleanedText);
  }, [prompt]);

  return (
    <Col lg="12" style={{ padding: 0 }}>
      <span style={{ width: "100%" }}>
        <Tooltip
          style={{ width: "100%" }}
          placement="top"
          isOpen={tooltipToogle}
          toggle={() => setTooltipToogle((v) => !v)}
          target={`prompt-question-${id}`}
        >
          {formattedQuestion}
        </Tooltip>
        <label
          className="form-control-label"
          style={{
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          id={`prompt-question-${id}`}
          onClick={() => setToggle(true)}
        >
          {title ? title : formattedQuestion}
        </label>
      </span>
      <Modal
        className="modal-dialog modal-xl"
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h3>Prompt utilizado</h3>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <textarea
            className="styled-scroll"
            style={{
              display: "block",
              width: "100%",
              maxHeight: "600px",
              height: "600px",
              resize: "none",
              padding: "0.625rem 0.75rem",
              fontSize: "0.875rem",
              fontWeight: "400",
              lineHeight: "1.5",
              color: "#8898aa",
              backgroundColor: "#fff",
              backgroundClip: "padding-box",
              border: "1px solid #cad1d7",
              borderRadius: "0.375rem",
              boxShadow: "none",
              transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
            }}
            disabled
            value={text}
          ></textarea>
          <hr className="my-4" />
          <CopyToClipboard
            text={text}
            onCopy={() => toast.success("Prompt copiado com sucesso!")}
          >
            <Button type="button" color="primary">
              Copiar
            </Button>
          </CopyToClipboard>
          <Button type="button" onClick={() => setToggle((v) => !v)}>
            Fechar
          </Button>
        </div>
      </Modal>
    </Col>
  );
};

export default Modals;
