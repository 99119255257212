import { Modal, Col, Button, FormGroup, Row } from "reactstrap";
import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import qualityApi from "service/qualityApi";
import moment from "moment";
import RenderContestationAction from "components/Action/RenderContestationAction";
import LifecycleStage from "components/LifecycleStage/LifecycleStage";

const ModalContestationStatus = ({
  contestation,
  updateAttendance,
  setUpdateAttendance,
  promptId,
  evaluableEventId,
  clientId,
}) => {
  const [toggle, setToggle] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [nextOrigin, setNextOrigin] = useState("dispute");
  const [score, setScore] = useState("0");
  const [loading, setLoading] = useState(false);
  const permissions = localStorage.getItem("permissions");
  const [currentStage, setCurrentStage] = useState("");
  const [status, setStatus] = useState("");
  const [origins, setOrigins] = useState([]);
  const [showFullResponse, setShowFullResponse] = useState(false);

  const toggleResponse = () => {
    setShowFullResponse(!showFullResponse);
  };

  const contestationStatus = {
    PENDING: "Pendente",
    APPROVED: "Aprovado",
    REJECTED: "Rejeitado",
  };

  const contestationTooltip = {
    PENDING: "Pendente de aprovação",
    APPROVED: "Aprovada",
    REJECTED: "Rejeitada",
  };

  const originMap = {
    dispute: "Contestação",
    initial_decision: "Decisão Inicial",
    reply: "Réplica",
    reply_response: "Tréplica",
    second_reply: "Segunda Réplica",
    final_decision: "Decisão Final",
  };

  const originOrder = [
    "dispute",
    "initial_decision",
    "reply",
    "reply_response",
    "second_reply",
    "final_decision",
  ];

  const lifecycleStages = [
    "Contestação",
    "Decisão Inicial",
    "Réplica",
    "Tréplica",
    "Segunda Réplica",
    "Decisão Final",
  ];

  const getNextOrigin = (lastOrigin) => {
    const currentIndex = originOrder.indexOf(lastOrigin);
    return currentIndex >= 0 && currentIndex < originOrder.length - 1
      ? originOrder[currentIndex + 1]
      : null;
  };

  const changeScore = useCallback((e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setScore(value);
    }
  }, []);

  const onSubmit = (status) => {
    setLoading(true);
    if (newMessage === "") {
      toast.error("A resposta não pode ser vazia");
      setLoading(false);
      return;
    }
    if (score === "" && status === "APPROVED") {
      toast.error("A nota não pode ser vazia");
      setLoading(false);
      return;
    }
    const data = {
      message: newMessage,
      score: status === "APPROVED" ? score : "0",
      status,
      promptId,
      evaluableEventId,
      clientId,
    };
    qualityApi
      .patch(`/contestation/${contestation.id}`, data)
      .then((res) => {
        toast.success("Contestação respondida com sucesso");
        setUpdateAttendance(!updateAttendance);
        setNewMessage("");
        setLoading(false);
        setToggle(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Erro ao responder contestação");
      });
  };

  useEffect(() => {
    const sortedMessages = contestation?.messages.sort((a, b) => {
      return originOrder.indexOf(a.origin) - originOrder.indexOf(b.origin);
    });
    setMessages(sortedMessages);
    const lastMessage = sortedMessages[sortedMessages.length - 1];
    const nextOrigin = lastMessage
      ? getNextOrigin(lastMessage.origin)
      : "dispute";
    setOrigins(sortedMessages);
    setNextOrigin(nextOrigin);
    setStatus(contestation?.status);
    setCurrentStage(contestation?.lifecycleStage);
  }, [contestation]);

  const renderResponse = () => {
    const response = contestation.suggestedResponse;
    if (response.length <= 200) {
      return response;
    }
    if (showFullResponse) {
      return (
        <>
          {response}
          <button onClick={toggleResponse} className="btn btn-link">
            Exibir menos
          </button>
        </>
      );
    }
    return (
      <>
        {response.substring(0, 200)}...
        <button onClick={toggleResponse} className="btn btn-link">
          Exibir mais
        </button>
      </>
    );
  };

  return (
    <Col
      md="4"
      style={{
        padding: 0,
      }}
    >
      <Button
        className="ml-3 mb-2 btn btn-success"
        size="sm"
        color="default"
        id="tooltip877016198"
        onClick={() => setToggle(true)}
        title={contestationTooltip[contestation?.status]}
      >
        {contestation?.status === "PENDING" && (
          <i className="fa fa-clock text-yellow"></i>
        )}
        {contestation?.status === "APPROVED" && (
          <i className="fa fa-check text-green"></i>
        )}
        {contestation?.status === "REJECTED" && (
          <i className="fa fa-times text-red"></i>
        )}
      </Button>
      <Modal
        className="modal-dialog modal-lg"
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="modal-header pr-1 pb-0">
            <div className="modal-title">
              <h3>Conteúdo da contestação</h3>
            </div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setToggle((v) => !v)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body pt-0 d-flex row">
            <div>
              <div className="pl-2">
                <b>Status:</b> {contestationStatus[contestation?.status]}
                <br />
                <b>Data da contestação: </b>
                {new Date(contestation?.createdAt).toLocaleString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                {contestation?.status !== "PENDING" && (
                  <>
                    <br />
                    <b>Nota: </b>
                    {contestation?.receivedScore}
                  </>
                )}
              </div>
              <div className="pl-2">
                <b>Resposta sugerida: </b>
                {renderResponse()}
              </div>
            </div>
            <LifecycleStage
              currentStage={currentStage}
              stages={lifecycleStages}
              origins={origins}
            />
            <div className="chat-container">
              <div className="chat-body">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`chat-message ${
                      index % 2 !== 0 ? "own-message" : "other-message"
                    }`}
                  >
                    <div className="message-header">
                      <strong>{originMap[msg.origin]}</strong> - Usuário:{" "}
                      {msg.userName} -{" "}
                      {moment(msg.createdAt).format("DD/MM/YYYY HH:mm")}
                    </div>
                    <p>{msg.message}</p>
                  </div>
                ))}
              </div>
              <RenderContestationAction
                currentStage={currentStage}
                status={status}
                permissions={permissions}
                newMessage={newMessage}
                setNewMessage={setNewMessage}
                score={score}
                changeScore={changeScore}
                contestation={contestation}
                loading={loading}
                onSubmit={onSubmit}
                nextOrigin={nextOrigin}
                originMap={originMap}
              />
            </div>
          </div>
        </div>
      </Modal>
    </Col>
  );
};

export default ModalContestationStatus;
