import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import api from "service/api";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const UploadMetrics = () => {
  const [, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFileName, setSelectedFileName] = useState(null);
  const inputFileRef = useRef(null);

  const submit = () => {
    setLoading(true);
    if (!selectedClientId) return toast.error("Selecione um cliente!");
    if (!selectedFile) return toast.error("Selecione um arquivo!");

    if (selectedFile) {
      let formData = new FormData();

      formData.append("file", selectedFile);
      const headers = {
        "Content-Type": "multipart/form-data",
      };

      api
        .post("/metric-file?clientId=" + selectedClientId, formData, {
          headers,
        })
        .then(() => {
          toast.success("Arquivo carregado com sucesso!");
          setSelectedFile(null);
          setSelectedFileName(null);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error.response?.data?.message);
          toast.error("Erro ao realizar upload do arquivo.");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    api
      .get("/clients?page=1&limit=100")
      .then((response) => {
        setClients(response.data.clients);
      })
      .catch((error) => {
        if (error.response?.status === 401) toast.error("Sessão expirada!");
        else toast.error("Erro ao recuperar clientes");
      });
  }, []);

  const handleUploadedFile = async (files) => {
    setSelectedFileName(files[0].name);
    setSelectedFile(files[0]);
  };

  return (
    <Container className="mt-7" fluid>
      <Col className="" xl="10">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Carregar arquivo</h3>
              </Col>
              <Col className="text-right" xs="4"></Col>
            </Row>
          </CardHeader>
          <CardBody
            style={{
              position: "relative",
            }}
          >
            <div className="pl-lg-12">
              <Col pl="12" lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="client">
                    Cliente
                  </label>
                  <select
                    className="form-control-alternative form-control"
                    id="client"
                    type="text"
                    onChange={(e) => {
                      setSelectedClientId(e.target.value);
                    }}
                    placeholder="Cliente"
                    required
                  >
                    <option value="">Selecione um cliente</option>
                    {clients.map((client) => (
                      <option key={client.id} value={client.id}>
                        {client.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Carregar arquivo
                  </label>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#fff",
                      border: "1px dashed #cecece",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      inputFileRef.current?.click();
                    }}
                    onDrop={(e) => {
                      if (!e.target.files || !e.target.files.length) {
                        return toast.error("Arquivos não encontrados!");
                      }
                      handleUploadedFile(e.target.files);
                    }}
                  >
                    <div
                      style={{
                        width: "80px",
                        height: "50px",
                        backgroundColor: "#f7fafc",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.7em",
                      }}
                    >
                      <i className={"ni ni-cloud-upload-96 text-#404040"} />
                    </div>
                    <div
                      style={{
                        fontSize: "0.8em",
                      }}
                    >
                      {selectedFileName
                        ? selectedFileName
                        : "Escolha um arquivo ou arraste para carregar"}
                    </div>
                  </div>
                  <input
                    type="file"
                    ref={inputFileRef}
                    id="input-file"
                    style={{
                      display: "none",
                    }}
                    onChange={(e) => {
                      if (!e.target.files || !e.target.files.length) {
                        return toast.error("Arquivos não encontrados!");
                      }
                      handleUploadedFile(e.target.files);
                      if (
                        inputFileRef &&
                        inputFileRef.current &&
                        inputFileRef.current.value
                      )
                        inputFileRef.current.value = "";
                    }}
                  />
                </FormGroup>
              </Col>
            </div>
            <hr className="my-4" />
            <Button type="button" color="success" onClick={submit}>
              Carregar Arquivo
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default UploadMetrics;
