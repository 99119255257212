import { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import qs from "query-string";
import { toast } from "react-toastify";
import api from "service/api";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Badge,
  Col,
  Table,
} from "reactstrap";
import Select from "react-select";
import ModalEditProviderCredentials from "components/Modal/ModalEditProviderCredentials";
import ModalEditProviderModel from "components/Modal/ModalEditProviderModel";
import ModalEditBudget from "components/Modal/ModalEditBudget";

const ClientForm = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);
  const emptyClient = {
    name: "",
  };
  const [client, setClient] = useState(emptyClient);
  const [checkedFeatures, setCheckedFeatures] = useState({
    chat: false,
    chat_history: false,
    content_edit: false,
    feedback: false,
    favorites: false,
    top_favorites: false,
    notes: false,
  });
  const [availableIpGroup, setAvailableIpGroup] = useState([]);
  const [selectedIpGroup, setSelectedIpGroup] = useState([]);
  const [clientIpGroups, setClientIpGroups] = useState([]);
  const [providers, setProviders] = useState([]);
  const [clientProviderConfig, setClientProviderConfig] = useState([]);
  const [updated, setUpdated] = useState(false);
  const permissions = localStorage.getItem("permissions");

  const updateClientConfig = (clientProviderConfigToChange) => {
    setClientProviderConfig((clientProviderConfig) => {
      return clientProviderConfig.map((config) => {
        if (config.id == clientProviderConfigToChange.providerId) {
          return { ...clientProviderConfigToChange, id: config.id };
        }

        return config;
      });
    });

    setProviders([]);
  };
  const onSubmit = (data) => {
    setLoading(true);

    if (hasId) {
      const features = [];
      if (checkedFeatures.chat) features.push("chat");
      if (checkedFeatures.chat_history) features.push("chat_history");
      if (checkedFeatures.content_edit) features.push("content_edit");
      if (checkedFeatures.feedback) features.push("feedback");
      if (checkedFeatures.favorites) features.push("favorites");
      if (checkedFeatures.top_favorites) features.push("top_favorites");
      if (checkedFeatures.notes) features.push("notes");
      if (checkedFeatures.meu_sputi) features.push("meu_sputi");
      if (checkedFeatures.attendance_feedback)
        features.push("attendance_feedback");

      const newData = {
        name: data.name,
        ipGroup: selectedIpGroup.map((ipGroup) => ipGroup.value),
        features: features,
        webhook: data.webhook,
      };

      api
        .put(`/clients/${getParamsId()}`, newData)
        .then(() => {
          toast.success("Cliente atualizado com sucesso!");
          setLoading(false);
        })
        .catch(() => {
          toast.error("Erro ao atualizar cliente!");
          setLoading(false);
        });
    } else {
      const features = [];
      if (checkedFeatures.chat) features.push("chat");
      if (checkedFeatures.chat_history) features.push("chat_history");
      if (checkedFeatures.content_edit) features.push("content_edit");
      if (checkedFeatures.feedback) features.push("feedback");
      if (checkedFeatures.favorites) features.push("favorites");
      if (checkedFeatures.top_favorites) features.push("top_favorites");
      if (checkedFeatures.notes) features.push("notes");
      if (checkedFeatures.meu_sputi) features.push("meu_sputi");
      if (checkedFeatures.attendance_feedback)
        features.push("attendance_feedback");

      data = {
        name: data.name,
        ipGroup: selectedIpGroup.map((ipGroup) => ipGroup.value),
        features: features,
      };
      api
        .post("/clients", data)
        .then(() => {
          toast.success("Cliente cadastrado com sucesso!");
          setLoading(false);
          reset();
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response?.data?.message));
          setLoading(false);
        });
    }
  };

  function getParamsId() {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  }

  function getIpGroups() {
    api
      .get("/ip-groups")
      .then((response) => {
        const availableIpGroup = response.data.ipGroups.map((ipGroup) => {
          return {
            value: ipGroup.id,
            label: ipGroup.name,
          };
        });
        setAvailableIpGroup(availableIpGroup);
        return availableIpGroup;
      })
      .catch((e) => {
        toast.error("Erro ao recuperar grupos de IPs!");
        return;
      });
  }

  function getAiProviders() {
    api.get("/providers").then((response) => {
      setProviders(
        response.data.aiProviders.map((provider) => {
          return {
            id: provider.id,
            name: provider.name,
            default: provider.default,
            models: provider.aiModel.map((model) => {
              return {
                id: model.id,
                name: model.name,
                selected: false,
              };
            }),
          };
        }),
      );
    });
  }

  function getClientProviderConfiguration() {
    api.get("/providers/configuration/" + getParamsId()).then((response) => {
      setClientProviderConfig(response.data);
    });
  }

  useEffect(() => {
    getIpGroups();
    const id = getParamsId();
    if (permissions.includes("read:provider_config")) {
      getClientProviderConfiguration();
    }
    if (id) {
      api
        .get(`/clients/${id}`)
        .then((response) => {
          setClient(response.data);
          setValue("name", response.data.name);
          if (response.data?.clientIpGroups?.length) {
            const selectedIpGroup = response.data?.clientIpGroups
              .map((ipGroup) => ipGroup.ipGroup)
              .map((ipGroup) => ({
                value: ipGroup.id,
                label: ipGroup.name,
              }));
            setSelectedIpGroup(selectedIpGroup);
          }
          setCheckedFeatures({
            chat: response.data.features.includes("chat"),
            chat_history: response.data.features.includes("chat_history"),
            content_edit: response.data.features.includes("content_edit"),
            feedback: response.data.features.includes("feedback"),
            favorites: response.data.features.includes("favorites"),
            top_favorites: response.data.features.includes("top_favorites"),
            notes: response.data.features.includes("notes"),
            meu_sputi: response.data.features.includes("meu_sputi"),
            attendance_feedback: response.data.features.includes(
              "attendance_feedback",
            ),
          });
        })
        .catch((e) => {
          toast.error("Erro ao recuperar cliente!");
          return;
        });
      if (permissions.includes("read:provider_config")) {
        getAiProviders();
      }
      setHasId(true);
    }
  }, [updated]);

  const handleOnChanged = (event) => {
    setCheckedFeatures({
      ...checkedFeatures,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <Container className="mt-7" fluid>
        <Col xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">
                    {hasId ? "Editar Cliente" : "Cadastrar Cliente"}
                  </h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Nome
                        </label>
                        <input
                          style={{
                            display: "block",
                            width: "100%",
                            height: "calc(1.5em + 1.25rem + 2px)",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                            transition:
                              "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                          }}
                          defaultValue={client.name}
                          className="form-control-alternative"
                          id="username"
                          placeholder="Nome"
                          type="text"
                          {...register("name")}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Grupo de ips
                        </label>
                        <Select
                          defaultValue={selectedIpGroup}
                          isMulti
                          name="colors"
                          options={availableIpGroup}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Atribuir grupo de ips"
                          value={selectedIpGroup}
                          onChange={setSelectedIpGroup}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-webhook"
                        >
                          Webhook ( para receber status de análises )
                        </label>
                        <input
                          style={{
                            display: "block",
                            width: "100%",
                            height: "calc(1.5em + 1.25rem + 2px)",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                            transition:
                              "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                          }}
                          defaultValue={client.webhook}
                          className="form-control-alternative"
                          id="webhook"
                          placeholder="Webhook"
                          type="text"
                          {...register("webhook")}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Funcionalidades:
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          name="chat"
                          checked={checkedFeatures.chat}
                          {...register("chat")}
                          onChange={handleOnChanged}
                        />
                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-topic"
                        >
                          Chat
                        </label>
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          name="addTopic"
                          checked={checkedFeatures.chat_history}
                          {...register("chat_history")}
                          onChange={handleOnChanged}
                        />
                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-topic"
                        >
                          Historico de mensagens
                        </label>
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          name="addTopic"
                          checked={checkedFeatures.content_edit}
                          {...register("content_edit")}
                          onChange={handleOnChanged}
                        />
                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-topic"
                        >
                          Ediçao de conteúdo
                        </label>
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          name="addTopic"
                          checked={checkedFeatures.feedback}
                          {...register("feedback")}
                          onChange={handleOnChanged}
                        />
                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-topic"
                        >
                          Avaliação de conteúdo
                        </label>
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          name="addTopic"
                          checked={checkedFeatures.favorites}
                          {...register("favorites")}
                          onChange={handleOnChanged}
                        />
                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-topic"
                        >
                          Favoritos
                        </label>
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          name="addTopic"
                          checked={checkedFeatures.top_favorites}
                          {...register("top_favorites")}
                          onChange={handleOnChanged}
                        />
                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-topic"
                        >
                          Top Favoritos
                        </label>
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          name="addTopic"
                          checked={checkedFeatures.notes}
                          {...register("notes")}
                          onChange={handleOnChanged}
                        />

                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-topic"
                        >
                          Notas
                        </label>
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          name="addTopic"
                          checked={checkedFeatures.meu_sputi}
                          {...register("meu_sputi")}
                          onChange={handleOnChanged}
                        />

                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-topic"
                        >
                          Meu Sputi
                        </label>
                      </FormGroup>{" "}
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          name="addTopic"
                          checked={checkedFeatures.attendance_feedback}
                          {...register("attendance_feedback")}
                          onChange={handleOnChanged}
                        />

                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-topic"
                        >
                          Confirmação de feedback
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <Button type="submit">Adicionar</Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
      {hasId && permissions.includes("read:provider_config") ? (
        <Container className="mt-4" fluid>
          <Col className="" xl="10">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Configuração de Provedor</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Provedor</th>
                      <th scope="col">Configurações</th>
                      <th scope="col">Selecionado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {providers?.map((provider, index) => {
                      const clientConfig = clientProviderConfig?.find(
                        (config) => config.provider.id === provider.id,
                      );
                      const isDefaultProvider = clientConfig?.isDefault;
                      const isConfigured = clientProviderConfig?.some(
                        (config) => config.provider.id === provider.id,
                      );
                      return (
                        <tr key={index}>
                          <td scope="row">{provider.name}</td>
                          <td scope="row">
                            <ModalEditProviderCredentials
                              id={provider.id}
                              name={provider.name}
                              models={provider.models}
                              clientConfig={clientConfig}
                              updateClientConfig={updateClientConfig}
                              client={client}
                              setUpdated={setUpdated}
                              updated={updated}
                            />
                            {isConfigured && (
                              <ModalEditProviderModel
                                id={provider.id}
                                name={provider.name}
                                models={provider.models}
                                client={client}
                                setUpdated={setUpdated}
                                updated={updated}
                                isDefault={clientConfig.isDefault}
                                clientConfig={clientConfig}
                              />
                            )}
                            <ModalEditBudget
                              id={provider.id}
                              setUpdated={setUpdated}
                              updateClientConfig={updateClientConfig}
                              updated={updated}
                              clientConfig={clientConfig}
                              name={provider.name}
                              client={client}
                            />
                          </td>
                          <td scope="row">
                            {isDefaultProvider ? (
                              <Badge className="badge-default" pill>
                                Provedor padrão
                              </Badge>
                            ) : (
                              ""
                            )}
                          </td>
                          {!isConfigured && (
                            <td scope="row">
                              <Badge color="warning" pill>
                                Não configurado
                              </Badge>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default ClientForm;
