import { Modal, Col, ButtonGroup, Button, FormGroup } from "reactstrap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import qualityApi from "service/qualityApi";

const ModalContestationForm = ({
  attendanceId,
  analysisId,
  segmentId,
  attendanceName,
  response,
  updateAttendance,
  setUpdateAttendance,
}) => {
  const { register, handleSubmit, setValue } = useForm();
  const [toggle, setToggle] = useState(false);

  const onSubmit = (data) => {
    const contestationData = {
      message: data.message,
      suggestedResponse: data.suggestedResponse,
      attendanceId,
      analysisId,
      segmentId,
      attendanceName,
      response,
    };

    if (
      !contestationData.message ||
      !contestationData.suggestedResponse ||
      !contestationData.attendanceId ||
      !contestationData.analysisId ||
      !contestationData.segmentId ||
      !contestationData.attendanceName ||
      !contestationData.response
    ) {
      toast.error("Informações insuficientes para iniciar uma contestação.");
      return;
    } else {
      qualityApi
        .post("/contestation", contestationData)
        .then(() => {
          toast.success("Contestação enviada com sucesso!");
          setUpdateAttendance(!updateAttendance);
          setToggle(false);
        })
        .catch((e) => {
          console.error(e);
          if (e.response.data.message === "Contestation already exists") {
            toast.error("Já existe uma contestação para este atendimento.");
          } else {
            toast.error("Erro ao enviar contestação. Tente novamente.");
          }
        });
    }
  };

  return (
    <Col
      md="4"
      style={{
        padding: 0,
      }}
    >
      <Button
        className="ml-3 mb-2 btn btn-success"
        size="sm"
        color="default"
        onClick={() => setToggle(true)}
      >
        <i className="fa fa-plus-circle "></i>
      </Button>
      <Modal
        className="modal-dialog modal-lg"
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header pb-0">
          <div className="modal-title">
            <h3>Contestação</h3>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <div>
            <p>
              Detalhe os itens que você deseja contestar, incluindo as respostas
              ideais para cada um e a justificativa para a contestação.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pl-lg-2">
              <FormGroup></FormGroup>
            </div>
            <div className="pl-lg-2">
              <FormGroup className="">
                <label className="form-control-label">
                  Descrição da contestação:
                </label>
                <textarea
                  className="form-control-alternative input-text-area"
                  id="response"
                  type="text"
                  {...register("message")}
                  autoComplete="off"
                  required
                  style={{ resize: "none" }}
                />
                <label className="form-control-label mt-2">
                  Resposta sugerida:
                </label>
                <textarea
                  className="form-control-alternative input-text-area"
                  id="response"
                  type="text"
                  {...register("suggestedResponse")}
                  autoComplete="off"
                  required
                  style={{ resize: "none" }}
                />
              </FormGroup>
            </div>
            <hr className="my-4" />
            <Button type="submit" color="primary">
              Contestar
            </Button>
          </form>
        </div>
      </Modal>
    </Col>
  );
};

export default ModalContestationForm;
