import { useState } from "react";
import { useForm } from "react-hook-form";
import api from "service/api";
import { toast } from "react-toastify";
import { Button, FormGroup, Modal, Row, Col, CardHeader } from "reactstrap";

const ModalEditMetric = (props) => {
  const { id, type, name, update, setUpdate } = props;
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const { register, handleSubmit } = useForm();

  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  const onSubmit = (data) => {
    api
      .put(`/metrics/${id}`, data)
      .then(() => {
        toast.success("Métrica editada com sucesso!");
      })
      .catch(() => {
        toast.error("Erro ao editar métrica!");
      });
    setUpdate(!update);
  };

  return (
    <>
      <Row>
        <Col md="4">
          <Button
            block
            color="primary"
            type="button"
            style={{ width: "auto" }}
            onClick={() => toggleModal("defaultModal")}
          >
            Editar Métrica
          </Button>
          <Modal
            className="modal-dialog-centered"
            isOpen={defaultModal}
            toggle={() => toggleModal("defaultModal")}
          >
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close pb-0"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal("defaultModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <CardHeader className="border-0 pb-0">Editar Métrica</CardHeader>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Nome
                        </label>
                        <input
                          className="form-control-alternative custom-input "
                          defaultValue={name}
                          id="name"
                          placeholder="Nome"
                          type="text"
                          {...register("name")}
                          autoComplete="off"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Tipo
                        </label>
                        <select
                          name="type"
                          id="type"
                          className="Input custom-input"
                          autoComplete="off"
                          defaultValue={type}
                          required
                          {...register("type")}
                        >
                          <option value="Number">Numero inteiro</option>
                          <option value="Text">Texto</option>
                          <option value="Percent">Porcentagem</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <Button type="submit">Atualizar</Button>
              </form>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default ModalEditMetric;
