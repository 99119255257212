import usePagination from "hooks/usepagination";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationComponent = ({ total, setOffset, maxReached = false }) => {
  const { setActualPage, actualPage } = usePagination();
  const current = actualPage;
  const totalPages = total;

  let startPage;
  let endPage;

  if (totalPages <= 9) {
    startPage = 1;
    endPage = totalPages;
  } else if (current <= 5) {
    startPage = 1;
    endPage = 9;
  } else if (current + 4 >= totalPages) {
    startPage = totalPages - 8;
    endPage = totalPages;
  } else {
    startPage = current - 4;
    endPage = current + 4;
  }

  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index,
  );

  function onPageChange(page) {
    setOffset(page);
    setActualPage(page);
  }

  return (
    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <ul className="pagination">
        <PaginationItem className={String(current === 1 && "disabled")}>
          <PaginationLink
            onClick={() => onPageChange(current - 1)}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {pageNumbers.map((page) => {
          if (page <= total) {
            return (
              <PaginationItem
                className={String(page === current && "active")}
                key={page}
              >
                <PaginationLink onClick={() => onPageChange(page)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            );
          }
        })}

        <PaginationItem className={String(maxReached && "disabled")}>
          <PaginationLink
            onClick={() => onPageChange(current + 1)}
            disabled={current === total}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </ul>
    </Pagination>
  );
};

export default PaginationComponent;
