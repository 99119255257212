import { useEffect, useState } from "react";
import qs from "query-string";
import api from "service/api";
import { toast } from "react-toastify";
import RuleExample from "./ModalRulesExample";
import { NewEditor } from "components/Editor";
import { Button, FormGroup, Modal, Row, Col, CardHeader } from "reactstrap";

const ModalRules = (props) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [metrics, setMetrics] = useState([{}]);
  const [formCount, setFormCount] = useState(1);
  const [formData, setFormData] = useState([]);
  const [conditionalIndex, setConditionalIndex] = useState([]);
  const [, setRuleMessage] = useState("");
  const [ruleName, setRuleName] = useState("");
  const [removeButtonIndex, setRemoveButtonIndex] = useState([]);
  const [textContent, setTextContent] = useState("");

  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  function handleRuleName(event) {
    event.preventDefault();
    setRuleName(event.target.value);
  }

  function getParamsClient() {
    const queryParams = qs.parse(window.location.search);
    const client = queryParams.client;
    return client ? client : undefined;
  }

  const onSubmit = (data) => {
    const client = getParamsClient();
    if (data.rule.length === 0) {
      toast.error("Regra vazia!");
      return;
    }

    if (textContent.length === 0) {
      toast.error("Mensagem vazia!");
      return;
    }

    const ruleDto = {
      rule: data.rule,
      clientName: client,
      message: textContent,
      name: ruleName,
      metricIds: data.metricIds,
    };

    api
      .post("/rules", ruleDto)
      .then(() => {
        toast.success("Regra adicionada com sucesso!");
        toggleModal("defaultModal");
        props.setUpdate(!props.update);
        setRuleMessage("");
      })
      .catch((error) => {
        console.error(error.response?.data?.message);
        toast.error("Erro ao adicionar regra!");
      });
  };

  useEffect(() => {
    api
      .get("/metrics")
      .then((response) => {
        setMetrics(response.data.metrics);
      })
      .catch((error) => {
        console.error(error.response?.data?.message);
        toast.error("Erro ao carregar as metricas");
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const index = name.split(".")[1] || "message";
    const field = name.split(".")[0];
    formData[index] = {
      ...formData[index],
      [field]:
        field === "condition"
          ? value.toUpperCase().split(" ").join("_")
          : value,
    };
    setFormData(formData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let outputArray = [];
    const metricIds = [];
    formData.map((item) => {
      if (item.metric) {
        metricIds.push(item.metric);
      }
      return item;
    });

    for (let i = 0; i < formData.length; i++) {
      const item = formData[i];

      if (i === 0) {
        outputArray.push([
          { metricId: item.metric, type: item.condition, value: item.value },
        ]);
      } else {
        const previousItem = formData[i - 1];
        const previousCondition = previousItem.conditional;

        if (previousCondition === "OR") {
          outputArray.push([
            { metricId: item.metric, type: item.condition, value: item.value },
          ]);
        } else if (previousCondition === "AND") {
          outputArray[outputArray.length - 1].push({
            metricId: item.metric,
            type: item.condition,
            value: item.value,
          });
        }
      }
    }

    const ruleDTO = {
      metricIds: metricIds,
      rule: outputArray,
    };
    onSubmit(ruleDTO);
  };

  const handleConditional = (index) => {
    conditionalIndex.push(index);
    if (conditionalIndex.includes(index - 1)) {
      setRemoveButtonIndex([...removeButtonIndex, index - 1]);
    }
    setConditionalIndex(conditionalIndex);
    setFormCount(formCount + 1);
    if (Array.isArray(formData)) {
      setFormData([...formData, ""]);
    } else {
      setFormData([""]);
    }
  };

  const handleRemoveConditional = (index) => {
    conditionalIndex.splice(index, 1);
    setConditionalIndex(conditionalIndex);
    setRemoveButtonIndex(
      removeButtonIndex.filter((item) => item !== index - 1),
    );
    setFormCount(formCount - 1);
    if (Array.isArray(formData)) {
      setFormData([...formData, ""]);
    } else {
      setFormData([""]);
    }
  };

  return (
    <Row>
      <Col md="4">
        <Button
          block
          color="primary"
          type="button"
          className="ml-3 mb-2"
          style={{ width: "200px" }}
          onClick={() => toggleModal("defaultModal")}
        >
          Nova Regra
        </Button>
        <Modal
          className="modal-dialog-centered modal-lg"
          isOpen={defaultModal}
          toggle={() => toggleModal("defaultModal")}
        >
          <div className="modal-header ">
            <CardHeader className="bg-white border-0 p-0">
              <Row>
                <Col>
                  <h3 className="mb-0">Cadastrar Regra</h3> <RuleExample />
                </Col>
              </Row>
            </CardHeader>
            <button
              aria-label="Close"
              className="close pb-0"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="pl-lg-4">
                <FormGroup className="d-flex">
                  <input
                    className="form-control-alternative custom-input "
                    id="name"
                    placeholder="Nome da regra"
                    type="text"
                    name="name"
                    onChange={handleRuleName}
                    autoComplete="off"
                  />
                </FormGroup>
                <hr className="my-3 pb-3" />
                <Row className="justify-content-center">
                  {Array.from({ length: formCount }).map((_, index) => {
                    return (
                      <div
                        key={index}
                        className="transition-ease transition-duration-2s"
                      >
                        <div className="d-flex">
                          <FormGroup className="d-flex mr-2">
                            <select
                              name={`metric.${index}`}
                              id="metric1"
                              className="form-control-alternative custom-input"
                              required
                              value={formData[`metric.${index}`]}
                              onChange={handleInputChange}
                            >
                              <option value="">Métrica</option>
                              {metrics.map((item, index) => {
                                return (
                                  <option value={item.id} key={index}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </FormGroup>
                          <FormGroup className="d-flex mr-2">
                            <select
                              name={`condition.${index}`}
                              id="condition"
                              placeholder="Condição"
                              className="form-control-alternative custom-input"
                              required
                              onChange={handleInputChange}
                            >
                              <option value="">Condição</option>
                              <option value="Greater than or Equal">
                                Maior ou Igual
                              </option>
                              <option value="Less than or Equal">
                                Menor ou Igual
                              </option>
                              <option value="Greater than">Maior que</option>
                              <option value="Less than">Menor que</option>
                              <option value="Equal to">Igual a</option>
                              <option value="Not Equal to">Diferente de</option>
                              <option value="Null">Nulo ou Vazio</option>
                            </select>
                          </FormGroup>
                          <FormGroup className="d-flex mr-2">
                            <input
                              className="form-control-alternative custom-input ml-2"
                              id="value"
                              placeholder="Valor"
                              type="text"
                              name={`value.${index}`}
                              onChange={handleInputChange}
                              autoComplete="off"
                              required
                            />
                          </FormGroup>
                        </div>
                        <FormGroup className="d-flex justify-content-center">
                          {conditionalIndex.includes(index) && (
                            <div className="d-flex justify-content-center ">
                              <select
                                className="custom-input"
                                name={`conditional.${index}`}
                                value={formData[`conditional.${index}`]}
                                onChange={handleInputChange}
                              >
                                <option value=""></option>
                                <option value="AND">E</option>
                                <option value="OR">OU</option>
                              </select>
                              {!removeButtonIndex.includes(index) && (
                                <Button
                                  color="danger"
                                  className="ml-2 remove-button"
                                  onClick={() => handleRemoveConditional(index)}
                                >
                                  -
                                </Button>
                              )}
                            </div>
                          )}
                        </FormGroup>
                        <div className="d-flex justify-content-center condition-btn">
                          {!conditionalIndex.includes(index) && (
                            <Button
                              color="default"
                              className="add-button"
                              onClick={() => handleConditional(index)}
                            >
                              {" "}
                              + Adicionar Condicional
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <FormGroup>
                    <NewEditor
                      initialContent=""
                      contentValue={textContent}
                      setContentValue={setTextContent}
                    />
                  </FormGroup>
                </Row>
              </div>
              <Button className="" type="submit" color="success">
                Salvar regra
              </Button>
            </form>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default ModalRules;
