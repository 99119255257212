import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import Loading from "components/Loading";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  CardTitle,
  Col,
  CardBody,
  FormGroup,
} from "reactstrap";
import qualityApi from "service/qualityApi";

const QualityContestation = () => {
  const { register, handleSubmit, reset } = useForm();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [onLoad, setOnLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);

  const permissions = localStorage.getItem("permissions");

  useEffect(() => {
    setOnLoad(true);

    if (permissions.includes("read:quality:pending_contestation")) {
      qualityApi
        .get(
          `/contestation?page=${offset}&limit=${Number(
            limit,
          )}&status=PENDING&${search}`,
        )
        .then((response) => response.data)
        .then((response) => {
          setRange(response.totalPages ?? 0);
          setTotalCount(response.totalCount ?? 0);
          setInfo(response.contestations ?? []);
        })
        .catch(() => {
          toast.error("Erro ao recuperar as contestações pendentes!");
          return;
        });
    }

    api
      .get(`/segments?page=1&limit=10000`)
      .then((response) => response.data)
      .then((response) => {
        setSegments(
          response.segments.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar segmentos!");
        return;
      });

    api
      .get(`/clients?page=1&limit=10000`)
      .then((response) => response.data)
      .then((response) => {
        setClients(
          response.clients.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        );
      });
    setOnLoad(false);
  }, [offset, search, limit]);

  const onSubmit = (data) => {
    const { attendance, contestingUsername } = data;
    let query = "";

    if (attendance) {
      query += `attendanceName=${attendance}&`;
    }

    if (selectedSegment?.value) {
      query += `segmentId=${selectedSegment.value}&`;
    }
    if (contestingUsername) {
      query += `contestingUsername=${contestingUsername}&`;
    }
    if (selectedClient?.value) {
      query += `clientId=${selectedClient.value}&`;
    }
    setSearch(query);
  };

  const clearSearch = () => {
    let query = "";
    setSearch(query);
    setSelectedClient([]);
    setSelectedSegment([]);
    reset();
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-3 d-flex align-items-center justify-content-center flex-column ">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={3}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Segmentos</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Escolha um segmento"
                      value={selectedSegment}
                      onChange={setSelectedSegment}
                      isClearable={true}
                      isSearchable={true}
                      options={segments}
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Cliente</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Escolha um cliente"
                      value={selectedClient}
                      onChange={setSelectedClient}
                      isClearable={true}
                      isSearchable={true}
                      options={clients}
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Atendimento</label>
                    <input
                      name="attendance"
                      id="code"
                      style={{
                        height: "calc(1.25em + 1.20rem + 1px)",
                      }}
                      className="custom-input"
                      {...register("attendance")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Row className="align-items-center ml-2 mb-3">
                  <Button
                    className="Button ml-2"
                    type="submit"
                    style={{ height: "42px", marginTop: "7px" }}
                  >
                    Buscar
                  </Button>
                  <Button
                    className="Button ml-2"
                    onClick={clearSearch}
                    style={{ height: "42px", marginTop: "7px" }}
                  >
                    Limpar
                  </Button>
                </Row>
              </Row>
              <Row>
                <Col lg="2" xl="2">
                  <Card className="card-stats">
                    <CardBody className="">
                      <Row>
                        <div className="d-flex flex-row align-items-center">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 mr-2"
                          >
                            Contestações
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalCount}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="2" xl="2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody className="">
                      <Row>
                        <div className="d-flex flex-row align-items-center">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 mr-2"
                          >
                            Paginas
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {range}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                {onLoad ? <Loading /> : ""}
                <thead className="thead-light">
                  <tr>
                    <th>Atendimento</th>
                    <th>Segmento</th>
                    <th>Cliente</th>
                    <th>Registro</th>
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.analysisId}>
                      <td>
                        <NavLink
                          to={`/admin/quality-attendance/${item.attendanceId}`}
                          activeClassName="active"
                        >
                          {item.attendanceName}
                        </NavLink>
                      </td>
                      <td>
                        {segments.length > 1 &&
                          segments.find(
                            (segment) => segment.value === item.segmentId,
                          )?.label}
                      </td>
                      <td>
                        {clients.length > 1 &&
                          clients.find(
                            (client) => client.value === item.clientId,
                          )?.label}
                      </td>
                      <td>
                        {new Date(item.createdAt).toLocaleString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default QualityContestation;
