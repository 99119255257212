import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, CardBody, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import api from "service/api";
import Loading from "components/Loading";

const ModalEditProviderModel = (props) => {
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(props.models);
  const [isDefault, setIsDefault] = useState(props.isDefault || false);
  const { register, handleSubmit, reset, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const permissions = localStorage.getItem("permissions");

  const onSubmit = (data) => {
    setLoading(true);
    const providerConfig = {
      providerId: props.id,
      modelId: data.modelId,
      isDefault: data.isDefault,
    };
    api
      .patch(
        "/providers/configuration/model/" + props.client.id,
        providerConfig,
      )
      .then((response) => {
        toast.success("Configuração de provedor registrada com sucesso!");
        props.setUpdated(!props.updated);
        setOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro ao registrar configuração de provedor!");
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        color="primary"
        type="button"
        size="sm"
        onClick={() => setOpen(!open)}
      >
        Editar Modelo
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={() => setOpen(!open)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Editar Modelo - {props.name}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setOpen(!open)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pl-lg-4">
              <Row>
                <Col md="12" lg="8">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="client">
                      Modelo
                    </label>
                    <select
                      style={{
                        display: "block",
                        width: "100%",
                        height: "calc(1.5em + 1.25rem + 2px)",
                        padding: "0.625rem 0.75rem",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      value={model.id}
                      className="form-control-alternative"
                      id="modelId"
                      type="text"
                      disabled={!permissions.includes("update:provider_config")}
                      {...register("modelId")}
                      defaultValue={props.clientConfig.model.id}
                      autoComplete="off"
                      onChange={(e) =>
                        setModel(
                          props.models.map((model) => {
                            if (model.id == e.target.value) {
                              return model;
                            }
                          }),
                        )
                      }
                    >
                      {props.models.map((model) => {
                        return (
                          <option key={model.id} value={model.id}>
                            {model.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <FormGroup className="mb-1 ml-4">
              <input
                type="checkbox"
                name="isDefault"
                checked={isDefault}
                onClick={() => {
                  setIsDefault(!isDefault);
                }}
                {...register("isDefault")}
              />
              <label className="form-control-label ml-2" htmlFor="input-topic">
                Provedor padrão
              </label>
            </FormGroup>
            <div className="modal-footer d-block">
              <Button type="submit" disabled={loading}>
                {loading ? (
                  <Loading
                    divStyle={{
                      width: "105px",
                    }}
                    loadingStyle={{
                      width: 30,
                      height: 20,
                    }}
                  />
                ) : (
                  "Atualizar"
                )}
              </Button>
            </div>
          </form>
        </CardBody>
      </Modal>
    </>
  );
};

export default ModalEditProviderModel;
