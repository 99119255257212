import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import qs from "query-string";
import ModalRules from "components/Modal/ModalRules";
import ModalProcessRules from "components/Modal/ModelProcessRules";
import ModalCustomMessage from "components/Modal/ModalCustomMessage";
import ModalConfirm from "../components/Modal/ModalConfirm";
import ModalEditRules from "components/Modal/ModalEditRules";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  CardTitle,
  Col,
  CardBody,
} from "reactstrap";
import ModalUserMetric from "components/Modal/ModalUserMetric";

const Rules = () => {
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(100);
  const [rules, setRules] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [client, setClient] = useState(null);
  const [update, setUpdate] = useState(false);
  const permissions = localStorage.getItem("permissions");

  function getParamsClient() {
    const queryParams = qs.parse(window.location.search);
    const client = queryParams.client;
    return client ? client : undefined;
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const reloadRules = () => {
    api
      .get(`/rules?limit=${limit}&offset=${offset}&client=${getParamsClient()}`)
      .then((response) => {
        setInfo(response.data.rules);
        setRange(response.data.totalPages);
        setTotalCount(response.data.totalCount);
        if (!client && response.data.rules[0]?.client) {
          setClient(response.data.rules[0].client);
        }
      })
      .catch(() => {
        toast.error("Erro ao carregar as regras");
      });
  };

  useEffect(() => {
    api
      .get(`/metrics`)
      .then((response) => {
        setMetrics(response.data.metrics);
      })
      .catch(() => {
        toast.error("Erro ao carregar as metricas");
      });
    reloadRules();
  }, [offset, limit, update]);

  useEffect(() => {
    if (info.length > 0 && metrics.length > 0) {
      const newRules = info.map((rule) => {
        const formattedRules = formattingRules(rule.rule);
        return {
          id: rule.id,
          name: rule.name,
          rules: [formattedRules],
          message: rule.customMessage,
        };
      });
      setRules(newRules);
    }
  }, [info, metrics, update]);

  function formattingRules(rules) {
    const conditionalRules = Array.isArray(rules)
      ? rules.length > 1
        ? [{ rules, conditional: "OR" }]
        : [{ rules, conditional: "AND" }]
      : [];
    if (conditionalRules[0].conditional === "OR") {
      const orArray = conditionalRules[0].rules.map((ruleGroup) =>
        ruleGroup
          .map((rule) => {
            const metricFiltered = metrics.find(
              (metric) => metric.id === rule.metricId,
            );
            return `${metricFiltered?.name} ${translateCondition(rule.type)} ${
              rule.value
            }`;
          })
          .join(" E "),
      );
      return orArray.join(" OU ");
    } else {
      const andArray = conditionalRules[0].rules.map((ruleGroup) =>
        ruleGroup
          .map((rule) => {
            const metricFiltered = metrics.find(
              (metric) => metric.id === rule.metricId,
            );
            return `${metricFiltered?.name} ${translateCondition(rule.type)} ${
              rule.value
            }`;
          })
          .join(" E "),
      );
      return andArray.join(" E ");
    }
  }

  function translateCondition(condition) {
    const conditionMap = {
      GREATER_THAN_OR_EQUAL: "Maior ou igual a",
      LESS_THAN_OR_EQUAL: "Menor ou igual a",
      GREATER_THAN: "Maior que",
      LESS_THAN: "Menor que",
      EQUAL_TO: "Igual a",
      NOT_EQUAL_TO: "Diferente de",
      NULL: "Nulo ou vazio",
    };

    return conditionMap[condition] || "";
  }

  const handleDelete = (id) => {
    api
      .delete(`/rules/${id}`)
      .then(() => {
        toast.success("Regra deletada com sucesso!");
        setRules(rules.filter((rule) => rule.id !== id));
      })
      .catch(() => {
        toast.error("Erro ao deletar regra!");
      });
  };

  return (
    <>
      <Container className="mt-1" fluid>
        <div className="header bg-gradient-info pb-8 pt-0 pt-md-0 d-flex align-items-center justify-content-center flex-column "></div>
      </Container>
      <Container className="mt--7" fluid>
        <div className="container-fluid mb-1">
          <Row>
            <Col lg="6" xl="3">
              <Card className="card-stats ">
                <CardBody className="pb-1">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h6"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Regras
                      </CardTitle>
                      <span className="h2 mb-0">{totalCount}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0 ">
                <CardBody className="pb-1 ">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Paginas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{range}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Row
                style={{ marginLeft: "20px", display: "flex", width: "auto" }}
              >
                {permissions?.includes("create:rule") && (
                  <ModalRules setUpdate={setUpdate} update={update} />
                )}
                {permissions?.includes("process:rule") && (
                  <ModalProcessRules client={client} />
                )}
                <ModalUserMetric clientId={client?.id} />
              </Row>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Regras</th>
                    <th scope="col">Mensagem</th>
                    {permissions?.includes("update:rule") && (
                      <th scope="col">Editar</th>
                    )}
                    {permissions?.includes("remove:rule") && (
                      <th scope="col">Excluir</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {rules.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.rules}</td>
                      <td>
                        <ModalCustomMessage
                          titleModal="Mensagem"
                          message={item.message}
                          buttonName={item.message
                            .replace(/(<([^>]+)>)/gi, "")
                            .substring(0, 20)}
                        />
                      </td>
                      {permissions?.includes("update:rule") && (
                        <td>
                          <ModalEditRules
                            ruleId={item.id}
                            ruleView={item.rules}
                            setUpdate={setUpdate}
                            update={update}
                          />
                        </td>
                      )}
                      {permissions?.includes("remove:rule") && (
                        <td>
                          <ModalConfirm
                            id={item.id}
                            modalText={`Esta ação irá excluir a regra ${item.name}, você tem certeza?`}
                            handler={() => {
                              handleDelete(item.id);
                            }}
                            icon={"fas fa-trash"}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Rules;
