import { ReactComponent as AtomLoadingSVG } from "../../assets/img/icons/common/atom-loading.svg";

const Loading = (props) => {
  const defaultStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "0",
    left: "0",
    fontSize: "3.5em",
    zIndex: "2",
  };
  const style = props.divStyle
    ? { ...props.divStyle }
    : { ...defaultStyle, ...props.style };

  return (
    <div style={style}>
      <AtomLoadingSVG
        style={props.loadingStyle ? props.loadingStyle : { width: 90 }}
      />
    </div>
  );
};

export default Loading;
