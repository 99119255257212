import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "service/api";
import { toast } from "react-toastify";
import {
  Card,
  Table,
  Container,
  Row,
  Form,
  Button,
  Badge,
  CardBody,
  CardTitle,
  Col,
  CardHeader,
  CardFooter,
} from "reactstrap";
import ModalEditIpGroups from "components/Modal/ModalEditIpGroups";
import ModalCreateIpGroups from "components/Modal/ModalCreateIpGroups";
import usePagination from "hooks/usepagination";
import PaginationComponent from "components/Pagination/Pagination";
import ModalConfirm from "../components/Modal/ModalConfirm";

const IpGroup = () => {
  const { register, handleSubmit, reset } = useForm();
  const [info, setInfo] = useState([]);
  const [, setOnLoad] = useState(false);
  const [update, setUpdate] = useState(false);
  const [range, setRange] = useState(0);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const permissions = localStorage.getItem("permissions");

  useEffect(() => {
    api
      .get(`/ip-groups?page=${offset}&limit=${Number(limit)}&${search}`)
      .then((response) => {
        setRange(response.data.totalPages);
        setInfo(response.data.ipGroups);
        setOnLoad(true);
      });
  }, [update, offset, limit, search]);

  const handleDelete = (id) => {
    api
      .delete(`/ip-groups/${id}`)
      .then(() => {
        toast.success("Grupo de ips deletado com sucesso!");
        setUpdate(!update);
      })
      .catch(() => {
        toast.error("Erro ao deletar grupo de ips!");
      });
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleOnSearch = (data) => {
    const { group } = data;
    let query = "";
    if (group) {
      query += `group=${group}&`;
    }
    setSearch(query);
  };

  const clearSearch = () => {
    let query = "";
    setSearch(query);
    reset();
  };

  return (
    <>
      <Container className="mt-0" fluid>
        <div className="header bg-gradient-info pb-8 pt-3 pt-md-3 d-flex align-items-center justify-content-center flex-column">
          <div className="container-fluid ">
            <Form onSubmit={handleSubmit(handleOnSearch)} className="d-flex">
              <input
                id="user"
                name="user"
                placeholder="Filtrar por nome do grupo"
                className="Input"
                style={{
                  display: "block",
                  width: "200px",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #cad1d7",
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                  marginRight: "10px",
                }}
                {...register("group")}
              />
              <Button
                className="Button ml-2"
                type="submit"
                style={{ height: "42px" }}
              >
                Buscar
              </Button>
              <Button
                className="Button ml-2"
                onClick={clearSearch}
                style={{ height: "42px" }}
              >
                Limpar
              </Button>
            </Form>
          </div>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <div className="container-fluid mb-3">
          <Row>
            <Col lg="6" xl="3">
              <Card className="card-stats ">
                <CardBody className="pb-1">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h6"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Grupos
                      </CardTitle>
                      <span className="h2 mb-0">{info.length}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0 ">
                <CardBody className="pb-1 ">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Páginas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{range}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <Row
                  style={{ marginLeft: "20px", display: "flex", width: "auto" }}
                >
                  {permissions?.includes("create:ip_group") && (
                    <ModalCreateIpGroups
                      setUpdate={setUpdate}
                      update={update}
                    />
                  )}
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Ips</th>
                    {permissions?.includes("update:ip_group") && (
                      <th scope="col">Editar</th>
                    )}
                    {permissions?.includes("remove:ip_group") && (
                      <th scope="col">Excluir</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "95px",
                          }}
                        >
                          {item.ipAddresses.split(",").map((feature, index) => (
                            <Badge
                              color="success"
                              href="#pablo"
                              key={index}
                              style={{ marginTop: "5px" }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {feature}
                            </Badge>
                          ))}
                        </div>
                      </td>
                      {permissions?.includes("update:ip_group") && (
                        <td>
                          <ModalEditIpGroups
                            id={item.id}
                            name={item.name}
                            ipAddresses={item.ipAddresses}
                            setUpdate={setUpdate}
                            update={update}
                          />
                        </td>
                      )}
                      {permissions?.includes("remove:ip_group") && (
                        <td>
                          <ModalConfirm
                            id={item.id}
                            modalText={`Esta ação irá excluir o grupo de ips ${item.name}, você tem certeza?`}
                            handler={() => {
                              handleDelete(item.id);
                            }}
                            icon={"fas fa-trash"}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default IpGroup;
