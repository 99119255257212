import { useEffect, useState } from "react";
import api from "service/api";
import { NavLink } from "react-router-dom";
import usePagination from "hooks/usepagination";
import { Card, Container, Row, Button, CardTitle, CardBody } from "reactstrap";

const TopFavorites = () => {
  const { actualPage } = usePagination();
  const [offset] = useState(actualPage || 1);
  const [clients, setClients] = useState([]);
  const [search] = useState("");
  const [limit] = useState(100);
  const [updated] = useState(false);
  const [openClients, setOpenClients] = useState(false);

  useEffect(() => {
    api.get("/clients").then((response) => {
      const clients = response.data;
      const clientOptions = [...clients.clients];
      setClients(clientOptions);
      setOpenClients(true);
    });
  }, [offset, search, limit, updated]);

  return (
    <Container className="mt-7" fluid>
      <h1 className="text-center">Selecione um Cliente</h1>
      <Row>
        {openClients &&
          clients.map((item, index) => {
            return (
              <Card
                className="mt-5 mr-2"
                style={{ width: "18rem" }}
                key={index}
              >
                <CardBody>
                  <CardTitle>{item.name}</CardTitle>
                  <NavLink
                    to={`/admin/users-clients?client=${item.name}`}
                    activeClassName="active"
                    style={{ color: "white" }}
                  >
                    <Button color="primary">Ver usuários</Button>
                  </NavLink>
                </CardBody>
              </Card>
            );
          })}
      </Row>
    </Container>
  );
};

export default TopFavorites;
