import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "service/api";
import { NewEditor } from "components/Editor";
import { Button, FormGroup, Modal, Row, Col } from "reactstrap";

const Modals = (props) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const { register, handleSubmit } = useForm();
  const [, setLoading] = useState(false);
  const [textContent, setTextContent] = useState(
    props.content.description ?? "",
  );
  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    data.description = textContent.trim();
    data.category = data.category.trim();

    api
      .put(`/favorites/${props.content.id}`, data)
      .then(() => {
        toast.success("Favorito atualizado com sucesso!");
        props.setUpdated(!props.updated);
        setLoading(false);
      })
      .catch(() => {
        toast.error("Erro ao atualizar favorito!");
        setLoading(false);
      });
  };

  return (
    <Row>
      <Col md="4">
        <Button
          block
          className="mb-3"
          color="primary"
          type="button"
          style={{ width: "auto" }}
          onClick={() => toggleModal("defaultModal")}
        >
          Conteúdo Favorito
        </Button>
        <Modal
          className="modal-dialog-centered"
          style={{ maxWidth: "640px" }}
          isOpen={defaultModal}
          toggle={() => toggleModal("defaultModal")}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="pl-lg-4">
                <Row>
                  <Col md="12" lg="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-topic"
                      >
                        Titulo
                      </label>
                      <input
                        style={{
                          display: "block",
                          width: "100%",
                          height: "calc(1.5em + 1.25rem + 2px)",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        className="form-control-alternative"
                        defaultValue={props.content.title}
                        id="title"
                        placeholder="Titulo"
                        type="text"
                        {...register("title")}
                        autoComplete="off"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-topic"
                      >
                        Categoria
                      </label>
                      <input
                        style={{
                          display: "block",
                          width: "100%",
                          height: "calc(1.5em + 1.25rem + 2px)",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        className="form-control-alternative"
                        defaultValue={props.content.category}
                        id="category"
                        placeholder="category"
                        type="text"
                        {...register("category")}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-content">
                    Conteúdo
                  </label>
                  <NewEditor
                    initialContent={props.content.description}
                    contentValue={textContent}
                    setContentValue={setTextContent}
                  />
                </FormGroup>
              </div>
              <Button type="submit">Atualizar</Button>
            </form>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default Modals;
