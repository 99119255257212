import "../../assets/css/ScoreRules.css";
import { useEffect, useState } from "react";
import { Button, Row, Col, FormGroup } from "reactstrap";
import { ReactComponent as PlusIcon } from "assets/icons/plus_icon.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/remove_icon.svg";
import { ReactComponent as ConditionalIcon } from "assets/icons/conditional_icon.svg";
import { useFieldArray, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

const newEmptyField = (type, groupId, conditional = false, score = 0) => {
  if (!conditional)
    return {
      id: generateRandomId(),
      groupId: groupId ?? generateRandomId(),
      type,
      key: "",
      condition: "",
      value: "",
      score,
      priority: false,
    };

  return {
    id: generateRandomId(),
    groupId: groupId,
    score,
    type,
    conditional: "",
  };
};

function generateRandomId() {
  return uuidv4();
}

const ScoreRule = ({
  promptOrEventId,
  type,
  index,
  field,
  keys,
  register,
  append,
  insert,
  errors,
  length,
  lastRule,
  handleRemoveScoreRule,
}) => {
  return (
    <>
      <Row key={index} className="pl-lg-12 mb-2">
        {"key" in field && (
          <Col xl="3" className="pr-0">
            <label
              htmlFor={`${promptOrEventId}.${index}.key`}
              style={{
                fontSize: "0.9em",
              }}
            >
              Chave
            </label>

            <select
              className="form-control"
              style={{
                borderColor:
                  errors[promptOrEventId] &&
                  errors[promptOrEventId][index] &&
                  errors[promptOrEventId][index].key
                    ? "red"
                    : "#cad1d7",
              }}
              {...register(`${promptOrEventId}.${index}.key`, {
                required: "Necessário informar a chave.",
              })}
            >
              <option></option>
              {type === "prompt" ? (
                keys.map((key) => (
                  <option value={key} key={`${field.id}.${key}`}>
                    {key}
                  </option>
                ))
              ) : (
                <>
                  <option value="response" key={`${field.id}.response`}>
                    response
                  </option>
                  <option value="checklist" key={`${field.id}.checklist`}>
                    checklist
                  </option>
                </>
              )}
            </select>
          </Col>
        )}
        {"condition" in field && (
          <Col xl="3" className="pr-0">
            <label
              htmlFor={`${promptOrEventId}.${index}.condition`}
              style={{
                fontSize: "0.9em",
              }}
            >
              Condição
            </label>
            <select
              className="form-control"
              style={{
                borderColor:
                  errors[promptOrEventId] &&
                  errors[promptOrEventId][index] &&
                  errors[promptOrEventId][index].condition
                    ? "red"
                    : "#cad1d7",
              }}
              {...register(`${promptOrEventId}.${index}.condition`, {
                required: "Necessário informar uma condição.",
              })}
            >
              <option></option>
              <option value={"GREATER_THAN_OR_EQUAL"}>Maior ou igual a</option>
              <option value={"LESS_THAN_OR_EQUAL"}>Menor ou igual a</option>
              <option value={"GREATER_THAN"}>Maior que</option>
              <option value={"LESS_THAN"}>Menor que</option>
              <option value={"EQUAL_TO"}>Igual a</option>
              <option value={"NOT_EQUAL_TO"}>Diferente de</option>
            </select>
          </Col>
        )}
        {"value" in field && (
          <Col xl="2" className="pr-0">
            <label
              htmlFor={`${promptOrEventId}.${index}.value`}
              style={{
                fontSize: "0.9em",
              }}
            >
              Valor
            </label>
            <input
              type="text"
              className="form-control"
              style={{
                borderColor:
                  errors[promptOrEventId] &&
                  errors[promptOrEventId][index] &&
                  errors[promptOrEventId][index].value
                    ? "red"
                    : "#cad1d7",
              }}
              {...register(`${promptOrEventId}.${index}.value`, {
                required: "Necessário informar o valor.",
              })}
            />
          </Col>
        )}
      </Row>
      <Row key={`${field.id}.${index}`} className="pl-lg-12 mb-2">
        {lastRule && !("conditional" in field) && "score" in field && (
          <Col xl="2" className="pr-0">
            <label
              htmlFor={`${promptOrEventId}.${index}.score`}
              style={{
                fontSize: "0.9em",
              }}
            >
              Peso
            </label>
            <input
              type="number"
              min="0"
              className="form-control"
              style={{
                borderColor:
                  errors[promptOrEventId] &&
                  errors[promptOrEventId][index] &&
                  errors[promptOrEventId][index].score
                    ? "red"
                    : "#cad1d7",
              }}
              {...register(`${promptOrEventId}.${index}.score`, {
                required: "Necessário informar um peso.",
              })}
            />
          </Col>
        )}
        {lastRule && !("conditional" in field) && (
          <Col xl="1" className="pr-0">
            <label
              htmlFor={`${promptOrEventId}.${index}.priority`}
              style={{
                fontSize: "0.9em",
              }}
            >
              Prioridade <br />
              Absoluta
            </label>
            <input
              type="checkbox"
              className="form-control"
              style={{
                fontSize: "0.1em",
                width: "20px",
                marginLeft: "4px",
                borderColor:
                  errors[promptOrEventId] &&
                  errors[promptOrEventId][index] &&
                  errors[promptOrEventId][index].priority
                    ? "red"
                    : "#cad1d7",
              }}
              {...register(`${promptOrEventId}.${index}.priority`)}
            />
          </Col>
        )}
        {"conditional" in field && (
          <Col xl="3" className="pr-0">
            <label
              htmlFor={`${promptOrEventId}.${index}.conditional`}
              style={{
                fontSize: "0.9em",
              }}
            >
              Condicional
            </label>
            <select
              className="form-control"
              style={{
                borderColor:
                  errors[promptOrEventId] &&
                  errors[promptOrEventId][index] &&
                  errors[promptOrEventId][index].conditional
                    ? "red"
                    : "#cad1d7",
              }}
              {...register(`${promptOrEventId}.${index}.conditional`, {
                required: "Necessário informar uma condição.",
              })}
            >
              <option></option>
              <option value={"AND"}>E</option>
              <option value={"OR"}>OU</option>
            </select>
          </Col>
        )}
        <Col
          sm="1"
          className="align-items-center d-flex justify-content-start "
        >
          {lastRule && !("conditional" in field) && (
            <Button
              color="danger"
              size="sm"
              className="mt-4"
              title={
                "conditional" in field ? "Remover condicional" : "Remover regra"
              }
              onClick={() => handleRemoveScoreRule(field.groupId)}
            >
              <RemoveIcon style={{ width: "14px", height: "14px" }} />
            </Button>
          )}
          {!("conditional" in field) && lastRule && (
            <Button
              color="primary"
              size="sm"
              className="mt-4"
              title="Adicionar condicional"
              onClick={() => {
                const groupId = "groupId" in field ? field.groupId : field.id;
                if (length > index) {
                  insert(index + 1, [
                    newEmptyField(type, groupId, true),
                    newEmptyField(type, groupId, false, field.score),
                  ]);
                } else {
                  append([
                    newEmptyField(type, groupId, true),
                    newEmptyField(type, groupId, false, field.score),
                  ]);
                }
              }}
            >
              <ConditionalIcon style={{ width: "16px", height: "16px" }} />
            </Button>
          )}
          {!("conditional" in field) && lastRule && (
            <Button
              color="success"
              size="sm"
              className="mt-4"
              title="Adicionar nova regra"
              onClick={() => {
                if (length > index) {
                  insert(index + 1, newEmptyField(type));
                } else {
                  append(newEmptyField(type));
                }
              }}
            >
              <PlusIcon style={{ width: "12px" }} />
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

const ScoreRules = ({
  promptOrEvent,
  rules,
  type,
  setConfiguredRules,
  open,
}) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [promptOrEvent.value]: [newEmptyField(type)],
    },
  });
  const { fields, replace, append, insert, remove } = useFieldArray({
    control,
    name: promptOrEvent.value,
  });

  const response =
    type === "prompt"
      ? createJsonString(
          promptOrEvent.content?.map((section) => ({
            key: section.key,
            value: section.value,
          })),
        )
      : promptOrEvent.content;

  function createJsonString(pairs) {
    const jsonObject = {};
    pairs.forEach((pair) => {
      jsonObject[pair.key] = pair.value;
    });
    return JSON.stringify(jsonObject, null, 4);
  }

  useEffect(() => {
    const subscription = watch((value) => {
      const fieldsData = value[promptOrEvent.value];
      const scoreRulesString = JSON.stringify(fieldsData);

      if (
        fieldsData.some((field) => field.key && field.condition && field.value)
      ) {
        setConfiguredRules((prev) => ({
          ...prev,
          [promptOrEvent.value]: fieldsData,
        }));
      } else {
        setConfiguredRules((prev) => {
          const updatedRules = { ...prev };
          delete updatedRules[promptOrEvent.value];
          return updatedRules;
        });
      }

      const now = new Date().getTime();
      const expiryTime = now + 24 * 60 * 60 * 1000; // 24 HOURS IN MILLISECONDS
      localStorage.setItem(`score_rule.expiration`, expiryTime);

      localStorage.setItem(
        `score_rules.${promptOrEvent.value}`,
        scoreRulesString,
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (rules?.length) {
      const newFields = [];
      rules.forEach((ruleGroup, index) => {
        const groupId = generateRandomId();
        if (ruleGroup.rule[0].length || ruleGroup.rule.length) {
          ruleGroup.rule.forEach((r, i) => {
            if (ruleGroup.rule?.length > 1 && r.length) {
              return r.forEach((specificRule) => {
                newFields.push({
                  id: generateRandomId(),
                  groupId,
                  type,
                  key: specificRule.key,
                  condition: specificRule.type,
                  value: specificRule.value,
                  score: specificRule.score,
                  priority: specificRule.priority,
                });
                if (
                  ruleGroup.rule?.length >= newFields?.length - 1 &&
                  ruleGroup.rule[i + 1]?.length
                ) {
                  newFields.push({
                    id: generateRandomId(),
                    groupId,
                    type,
                    conditional: "AND",
                  });
                } else if (
                  ruleGroup.rule?.length >= newFields?.length &&
                  !ruleGroup.rule[i + 1]?.length
                ) {
                  newFields.push({
                    id: generateRandomId(),
                    groupId,
                    type,
                    conditional: "OR",
                  });
                }
              });
            } else if (r.length) {
              r.forEach((specificRule, index) => {
                console.log(newFields);

                if (!specificRule.length) {
                  newFields.push({
                    id: generateRandomId(),
                    groupId,
                    type,
                    key: specificRule.key,
                    condition: specificRule.type,
                    value: specificRule.value,
                    score: specificRule.score,
                    priority: specificRule.priority,
                  });
                } else {
                  newFields.push({
                    id: generateRandomId(),
                    groupId,
                    type,
                    key: specificRule[0].key,
                    condition: specificRule[0].type,
                    value: specificRule[0].value,
                    score: specificRule[0].score,
                    priority: specificRule[0].priority,
                  });
                }
                console.log(r[index]);
                if (
                  ruleGroup.rule[0]?.length >= newFields?.length &&
                  r[index + 1] &&
                  !r[index + 1].length
                ) {
                  newFields.push({
                    id: generateRandomId(),
                    groupId,
                    type,
                    conditional: "OR",
                  });
                } else if (
                  ruleGroup.rule[0]?.length >= newFields?.length &&
                  r[index + 1] &&
                  r[index + 1].length
                ) {
                  newFields.push({
                    id: generateRandomId(),
                    groupId,
                    type,
                    conditional: "AND",
                  });
                }
              });
            } else {
              newFields.push({
                id: generateRandomId(),
                groupId,
                type,
                key: r.key,
                condition: r.type,
                value: r.value,
                score: r.score,
                priority: r.priority,
              });
            }
          });
        } else {
          newFields.push({
            id: generateRandomId(),
            groupId,
            type,
            key: ruleGroup.rule[0].key,
            condition: ruleGroup.rule[0].type,
            value: ruleGroup.rule[0].value,
            score: ruleGroup.score,
            priority: ruleGroup.priority,
          });
        }
      });
      replace(newFields);
    }
    const scoreRulesString = localStorage.getItem(
      `score_rules.${promptOrEvent.value}`,
    );

    if (scoreRulesString) {
      const fieldsData = JSON.parse(scoreRulesString);
      if (
        fieldsData.some(
          (field) => field.key && field.condition && field.value && field.score,
        )
      ) {
        setConfiguredRules((prev) => ({
          ...prev,
          [promptOrEvent.value]: fieldsData,
        }));
      }
      replace(fieldsData);
    }
  }, []);

  useEffect(() => {
    const StoredScoreRules = localStorage.getItem(
      `score_rules.${promptOrEvent.value}`,
    );

    if (fields?.length == 0 && !StoredScoreRules) {
      append(newEmptyField(type));
    }
  }, [fields]);

  function handleRemoveScoreRule(groupId) {
    const groupIndexes = [];
    fields.forEach((field, index) => {
      if (field.groupId === groupId || field.id === groupId) {
        groupIndexes.push(index);
      }
    });
    if (groupIndexes?.length == fields?.length) {
      append(newEmptyField(type));
    }
    remove(groupIndexes);
  }

  return (
    open && (
      <>
        <div style={{ marginTop: "12px" }}>
          <label className="form-control-label" htmlFor="prompt">
            {type === "prompt"
              ? "Visualização de respostas pré-definidas:"
              : "Evento: "}
          </label>
          <textarea
            className="input-textarea"
            id="prompt"
            type="text"
            value={response ? response : promptOrEvent.content}
            disabled
          />
        </div>
        <div className="pl-lg-12 mt-2 box">
          <Col className="pl-lg-12">
            <FormGroup>
              <Row className="align-items-center mb-4 box">
                <Col className="d-flex justify-content-start">
                  <label className="form-control-label" htmlFor="prompt">
                    Regras de Score
                  </label>
                </Col>
              </Row>
              <FormGroup>
                {fields?.map((field, index) => {
                  const addDivisor =
                    !("conditional" in field) &&
                    index > 0 &&
                    !("conditional" in fields[index - 1])
                      ? true
                      : false;

                  const lastRule =
                    (fields[index + 1] &&
                      !("conditional" in fields[index + 1])) ||
                    index + 1 === fields?.length;
                  return (
                    <div key={`${field.id}.${index}`}>
                      {addDivisor && (
                        <hr style={{ borderTop: "2px solid gray" }} />
                      )}
                      <ScoreRule
                        promptOrEventId={promptOrEvent.value}
                        type={type}
                        index={index}
                        field={field}
                        keys={
                          type === "prompt"
                            ? promptOrEvent.content.map(
                                (section) => section.key,
                              )
                            : []
                        }
                        register={register}
                        append={append}
                        insert={insert}
                        remove={remove}
                        errors={errors}
                        length={fields.length}
                        lastRule={lastRule}
                        handleRemoveScoreRule={handleRemoveScoreRule}
                      />
                    </div>
                  );
                })}
              </FormGroup>
            </FormGroup>
          </Col>
        </div>
      </>
    )
  );
};

export const ScoreRuleGroup = ({
  promptsOrEvents,
  rules,
  type,
  configuredRules,
  setConfiguredRules,
}) => {
  const [promptsAccordions, setPromptsAccordions] = useState([]);

  function handlePromptsAccordions(prompt) {
    if (promptsAccordions.includes(prompt)) {
      setPromptsAccordions((promptsAccordions) =>
        promptsAccordions.filter((m) => m !== prompt),
      );
    } else {
      setPromptsAccordions((promptsAccordions) => [
        ...promptsAccordions,
        prompt,
      ]);
    }
  }

  return (
    <div className="score-group">
      {promptsOrEvents.map((promptOrEvent) => {
        const filteredRules = rules?.filter((rule) => {
          if (type === "prompt") return rule.promptId == promptOrEvent.value;
          return rule.evaluableEventId?.id == promptOrEvent.value;
        });

        return (
          <Col className="pl-lg-12" key={promptOrEvent.value}>
            <div
              className="pl-lg-12"
              style={{
                border: "1px solid #65779581",
                borderRadius: "5px",
                padding: "8px",
              }}
            >
              <Col className="pl-lg-12" style={{ padding: "0" }}>
                <button
                  type="button"
                  className="btn-accordion"
                  onClick={() => handlePromptsAccordions(promptOrEvent.value)}
                >
                  <label
                    className="form-control-label"
                    htmlFor="client"
                    title={promptOrEvent.value}
                  >
                    <b>
                      {type === "prompt" ? "Prompt: " : "Evento avaliável: "}{" "}
                    </b>
                    {promptOrEvent.label}
                  </label>
                  {configuredRules[promptOrEvent.value] &&
                    configuredRules[promptOrEvent.value].length > 0 && (
                      <span
                        title="Possui regras configuradas!"
                        className="icon-warning"
                      >
                        <i className="ni ni-bullet-list-67 text-orange" />
                      </span>
                    )}
                  <span
                    title="Minimizar/Expandir"
                    style={{
                      width: "36px",
                      margin: "0",
                      border: "1px solid #e9ecef",
                      borderRadius: "4px",
                      marginLeft: !configuredRules[promptOrEvent.value]
                        ? "auto"
                        : "",
                    }}
                  >
                    -
                  </span>
                </button>
              </Col>
              <ScoreRules
                promptOrEvent={promptOrEvent}
                rules={filteredRules}
                type={type}
                setConfiguredRules={setConfiguredRules}
                open={promptsAccordions.includes(promptOrEvent.value)}
              />
            </div>
          </Col>
        );
      })}
    </div>
  );
};

export default ScoreRuleGroup;
