import "../assets/css/PromptForm.css";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import qs from "query-string";
import { toast } from "react-toastify";
import api from "service/api";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import qualityApi from "service/qualityApi";

const EvaluableEventForm = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState({});

  const onSubmit = (data) => {
    setLoading(true);

    if (!data.title.trim()) {
      return toast.error("Informe o título");
    }

    if (!selectedSegment?.value) {
      return toast.error("Informe o segmento");
    }

    if (!data.prompt.trim()) {
      return toast.error("Informe o evento avaliável");
    }

    if (hasId) {
      qualityApi
        .patch(`/evaluable-events/${getParamsId()}`, {
          title: data.title,
          segment: selectedSegment?.value,
          prompt: data.prompt.trim(),
        })
        .then(() => {
          toast.success("Evento atualizado com sucesso!");
          setLoading(false);
        })
        .catch((error) => {
          if (
            error.response?.data?.message == "Evaluable event already exists."
          ) {
            toast.error(
              "Evento com o mesmo titulo já cadastrado para este segmento!",
            );
          } else if (
            error.response?.data?.message == "Evaluable event not found"
          ) {
            toast.error("Evento não encontrado!");
          } else {
            toast.error("Erro ao atualizar o evento!");
          }
          setLoading(false);
        });
    } else {
      qualityApi
        .post("/evaluable-events", {
          title: data.title,
          segment: selectedSegment?.value,
          prompt: data.prompt.trim(),
        })
        .then(() => {
          toast.success("Evento cadastrado com sucesso!");
          setLoading(false);
          reset();
        })
        .catch((error) => {
          if (
            error.response?.data?.message == "Evaluable event already exists."
          ) {
            toast.error(
              "Evento com o mesmo titulo já cadastrado para este segmento!",
            );
          } else if (
            error.response?.data?.message == "Evaluable event not found"
          ) {
            toast.error("Evento não encontrado!");
          } else {
            toast.error("Erro ao cadastrar o evento!");
          }
          setLoading(false);
        });
    }
  };

  function getParamsId() {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  }

  useEffect(() => {
    const id = getParamsId();
    if (id) {
      qualityApi
        .get(`/evaluable-events/${id}`)
        .then((response) => response.data)
        .then((response) => {
          setValue("title", response.title);
          setValue("prompt", response.prompt);
          setSelectedSegment({
            label: response.segment.title,
            value: response.segment.id,
          });
        })
        .catch(() => {
          toast.error("Erro ao recuperar o evento!");
          return;
        });
      setHasId(true);
    }

    api
      .get(`/segments?page=1&limit=10000`)
      .then((response) => {
        setSegments(
          response.data.segments.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar os segmentos!");
        return;
      });
  }, []);

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="" xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Cadastrar evento avaliável</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Alert color="primary">
                <i className="ni ni-chat-round"></i> <b>Eventos avaliáveis</b>{" "}
                são utilizados para inserir uma avaliação manualmente!
              </Alert>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Título
                      </label>
                      <input
                        style={{
                          display: "block",
                          width: "100%",
                          height: "calc(1.5em + 1.25rem + 2px)",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        className="form-control-alternative styled-input"
                        id="prompt-title"
                        placeholder="Título"
                        type="text"
                        {...register("title")}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="client">
                        Segmento
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Escolha um segmento"
                        value={selectedSegment}
                        onChange={setSelectedSegment}
                        isClearable={true}
                        isSearchable={true}
                        options={segments}
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <label className="form-control-label" htmlFor="prompt">
                      Evento
                    </label>
                    <FormGroup>
                      <textarea
                        style={{
                          display: "block",
                          width: "100%",
                          height: "100px",
                          resize: "none",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          fontStyle: "normal",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        className="form-control-alternative styled-scroll styled-input"
                        id="prompt"
                        type="text"
                        placeholder="Ex: O consultor utilizou o sistema externo para realizar a negociação de forma correta?"
                        {...register("prompt")}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </div>
                <hr className="my-4" />
                <Button type="submit">Adicionar</Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default EvaluableEventForm;
