// reactstrap components
import { Modal, Col, ButtonGroup, Button, FormGroup } from "reactstrap";

// core components
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Loading from "components/Loading";
import qualityApi from "service/qualityApi";

const Modals = ({
  attendanceId,
  analysisId,
  segmentId,
  update,
  setUpdate,
  evaluated,
  promptId,
}) => {
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [checklist, setChecklist] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  const onSubmit = (data) => {
    if (!data.response?.trim()) {
      return toast.error("Necessário informar a resposta da análise!");
    }

    if (typeof checklist !== "boolean") {
      return toast.error(
        "Necessário informar se a resposta é positiva ou negativa!",
      );
    }

    if (analysisId && evaluated) {
      qualityApi
        .patch(`/attendances/analysis/${analysisId}`, {
          evaluableEventId: promptId,
          response: data.response,
          checklist: checklist,
        })
        .then(() => {
          toast.success("Análise atualizada com sucesso!");
          setUpdate(!update);
          setToggle(false);
          return;
        })
        .catch(() => {
          toast.error("Houve um problema ao tentar atualizar a análise");
          return;
        });
      return;
    } else {
      qualityApi
        .post(`/attendances/${attendanceId}/analysis`, {
          evaluableEventId: data.prompt,
          response: data.response,
          checklist: checklist,
        })
        .then(() => {
          toast.success("Análise registrada com sucesso!");
          setUpdate(!update);
          setToggle(false);
          return;
        })
        .catch(() => {
          toast.error("Houve um problema ao tentar registrar a análise!");
          return;
        });
    }
  };

  const getPrompts = async () => {
    setLoading(true);
    qualityApi
      .get(`evaluable-events/${promptId}`)
      .then((response) => response.data)
      .then((response) => {
        if (response) {
          setSelectedPrompt(response);
          setValue("prompt", promptId);
        }
        setLoading(false);
        return;
      })
      .catch(() => {
        toast.error("Erro ao tentar recuperar os prompts!");
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (toggle) {
      getPrompts().then(() => {
        if (toggle && analysisId) {
          setLoading(true);
          qualityApi
            .get(`/attendances/analysis/${analysisId}`)
            .then((response) => response.data)
            .then((response) => {
              if (response) {
                setValue("prompt", response?.evaluableEvent?.id);
                setSelectedPrompt(response?.evaluableEvent);
                setValue("response", response.response);
                setChecklist(response.checklist === "Sim" ? true : false);
              }
              setLoading(false);
              return;
            })
            .catch(() => {
              toast.error("Erro ao tentar recuperar a analise!");
              setLoading(false);
            });
        }
      });
    }
  }, [toggle]);

  return (
    <Col
      md="4"
      style={{
        padding: 0,
      }}
    >
      <Button
        className="ml-3 mb-2 btn btn-success"
        size="sm"
        color={evaluated ? "primary" : "default"}
        onClick={() => setToggle(true)}
      >
        {evaluated ? (
          <i className="ni ni-ruler-pencil" title="Editar avaliação" />
        ) : (
          <i className="ni ni-fat-add" title="Adicionar avaliação" />
        )}
      </Button>
      <Modal
        className="modal-dialog modal-lg"
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h3>Avaliação</h3>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {loading ? <Loading /> : ""}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pl-lg-2">
              <FormGroup>
                <label className="form-control-label">Pergunta</label>
                <textarea
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100px",
                    resize: "none",
                    padding: "0.625rem 0.75rem",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "rgb(249, 249, 249)",
                    backgroundClip: "padding-box",
                    border: "none",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                  }}
                  className="form-control-alternative"
                  id="prompt-question"
                  type="text"
                  disabled={true}
                  value={selectedPrompt?.prompt}
                  autoComplete="off"
                />
              </FormGroup>
            </div>
            <div className="pl-lg-2">
              <FormGroup>
                <label className="form-control-label">Resposta</label>
                <textarea
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100px",
                    resize: "none",
                    padding: "0.625rem 0.75rem",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                  }}
                  className="form-control-alternative"
                  id="response"
                  type="text"
                  {...register("response")}
                  autoComplete="off"
                />
              </FormGroup>
            </div>
            <div className="pl-lg-2">
              <FormGroup>
                <label className="form-control-label">
                  Checklist ( informar se a resposta foi positiva ou negativa )
                </label>
                <br />
                <ButtonGroup>
                  <Button
                    color="primary"
                    outline
                    onClick={() => setChecklist(true)}
                    active={checklist === true}
                  >
                    Positiva
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={() => setChecklist(false)}
                    active={checklist === false}
                  >
                    Negativa
                  </Button>
                </ButtonGroup>
              </FormGroup>
            </div>
            <hr className="my-4" />
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </form>
        </div>
      </Modal>
    </Col>
  );
};

export default Modals;
