import { useEffect, useState } from "react";
import qs from "query-string";
import api from "service/api";
import { toast } from "react-toastify";
import RuleExample from "./ModalRulesExample";
import { NewEditor } from "components/Editor";
import {
  Button,
  FormGroup,
  Modal,
  Row,
  Col,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";

const ModalUserMetric = ({ clientId }) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [userMetrics, setUserMetrics] = useState([{}]);

  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (clientId) {
      api
        .get(`/user-metrics?client_id=${clientId}`)
        .then((response) => {
          setUserMetrics(response.data);
        })
        .catch(() => {
          toast.error("Erro ao carregar as metricas de usuário");
        });
    }
  }, [clientId]);

  return (
    <Row>
      <Col md="4">
        <Button
          block
          color="primary"
          type="button"
          className="ml-3 mb-2"
          style={{ width: "200px" }}
          onClick={() => toggleModal("defaultModal")}
        >
          Metricas dos Usuários
        </Button>
        <Modal
          className="modal-dialog-centered modal-lg"
          isOpen={defaultModal}
          toggle={() => toggleModal("defaultModal")}
        >
          <div className="modal-header ">
            <CardBody>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">External_Id</th>
                    <th scope="col">Metricas</th>
                  </tr>
                </thead>
                <tbody>
                  {userMetrics &&
                    userMetrics?.map((metric, index) => (
                      <tr key={index}>
                        <td>{metric.external_id}</td>
                        <td>
                          <ol>
                            {metric?.data &&
                              Object.entries(metric?.data)
                                .sort()
                                .map(([key, value], index) => (
                                  <li key={index}>
                                    {key}: {value}
                                  </li>
                                ))}
                          </ol>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default ModalUserMetric;
