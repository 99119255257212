import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "service/api";
import qualityApi from "service/qualityApi";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import ModalConfirm from "../components/Modal/ModalConfirm";
import usePagination from "hooks/usepagination";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  CardTitle,
  Col,
  CardBody,
  FormGroup,
} from "reactstrap";
const QualityScoreRules = () => {
  const { register, handleSubmit, reset } = useForm();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [showActive, setShowActive] = useState(true);
  const [update, setUpdate] = useState(false);
  const permissions = localStorage.getItem("permissions");
  useEffect(() => {
    api
      .get(`/segments?page=1&limit=${new Date().getTime()}`)
      .then((response) => response.data)
      .then((response) => {
        setSegments(
          response.segments.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar segmentos!");
        return;
      });
    api
      .get(`/clients?page=1&limit=${new Date().getTime()}`)
      .then((response) => response.data)
      .then((response) => {
        setClients(
          response.clients.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar clientes!");
        return;
      });
  }, []);
  useEffect(() => {
    if (clients.length && segments.length) {
      qualityApi
        .get(
          `/score/rule/?page=${offset}&limit=${Number(limit)}&${search}&${
            showActive ? "active=true" : "active=false"
          }`,
        )
        .then((response) => response.data)
        .then((response) => {
          setRange(response.totalPages);
          setTotalCount(response.totalCount);
          const scoresRules = response.data.map((item) => ({
            id: item.id,
            title: item.title,
            segment: segments.find(
              (segment) => segment.value === item.segmentId,
            ),
            client: clients.find((client) => client.value === item.clientId),
          }));
          setInfo(scoresRules);
        })
        .catch(() => {
          toast.error("Erro ao recuperar regras de score!");
          return;
        });
    }
  }, [offset, search, limit, clients, segments, showActive, update]);
  const handleDelete = (id) => {
    qualityApi
      .delete(`/score/rule/${id}`)
      .then(() => {
        toast.success("Regra de score deletada com sucesso!");
        const infoFilter = info.filter((item) => item.id !== id);
        setInfo(infoFilter);
      })
      .catch(() => {
        toast.error("Erro ao deletar regra de score!");
      });
  };
  const handleActivate = (id) => {
    qualityApi
      .patch(`/score/rule/${id}`)
      .then(() => {
        setUpdate(!update);
        toast.success("Regra de score restaurada com sucesso!");
      })
      .catch((e) => {
        if (
          e.response.data.message ===
          "There is already an active score group for this segment"
        ) {
          toast.error(
            "Esta ação não é permitida, pois já existe uma regra ativa para este segmento!",
          );
        } else {
          toast.error("Erro ao restaurar regra de score!");
        }
      });
  };
  const onSubmit = (data) => {
    const { title } = data;
    let query = "";
    if (title) {
      query += `title=${title}&`;
    }
    if (selectedSegment?.value) {
      query += `segmentId=${selectedSegment.value}&`;
    }
    if (selectedClients?.value) {
      query += `clientId=${selectedClients.value}&`;
    }
    setSearch(query);
  };
  const handleDeleteAt = () => {
    setShowActive(!showActive);
  };
  const clearSearch = () => {
    let query = "";
    setSearch(query);
    reset();
  };
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-2 pt-md-2 d-flex align-items-center justify-content-center flex-column ">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={3}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Segmentos</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Escolha um segmento"
                      value={selectedSegment}
                      onChange={setSelectedSegment}
                      isClearable={true}
                      isSearchable={true}
                      options={segments}
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Clientes</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Escolha um cliente"
                      value={selectedClients}
                      onChange={setSelectedClients}
                      isClearable={true}
                      isSearchable={true}
                      options={clients}
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Título</label>
                    <input
                      name="title"
                      id="title"
                      className="Input"
                      style={{
                        display: "block",
                        width: "100%",
                        height: "calc(1.5em + 1.25rem + 2px)",
                        padding: "0.625rem 0.75rem",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      {...register("title")}
                    />
                  </FormGroup>
                </Col>
                <Col lg={1} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Button
                      className="Button ml-2"
                      type="submit"
                      style={{ height: "50px" }}
                    >
                      Buscar
                    </Button>
                  </FormGroup>
                </Col>
                <Col lg={1} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Button
                      className="Button ml-2"
                      onClick={clearSearch}
                      style={{ height: "50px" }}
                    >
                      Limpar
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody className="pb-1">
                      <Row>
                        <div className="d-flex flex-row align-items-center">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 mr-2"
                          >
                            Regras
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalCount}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody className="pb-1 ">
                      <Row>
                        <div className="d-flex flex-row align-items-center">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 mr-2"
                          >
                            Páginas
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {range}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Row>
                    <Button
                      className="Button"
                      style={{ height: "50px" }}
                      onClick={handleDeleteAt}
                    >
                      Exibir regras {showActive ? "inativas" : "ativas"}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Título</th>
                    <th>Segmento</th>
                    <th>Cliente</th>
                    {permissions?.includes("update:quality:score_rule") && (
                      <th>{showActive ? "Editar" : "-"}</th>
                    )}
                    {permissions?.includes("remove:quality:score_rule") && (
                      <th>{showActive ? "Excluir" : "Reativar"}</th>
                    )}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>{item.title}</td>
                      <td>{item.segment?.label}</td>
                      <td>{item.client?.label}</td>
                      {permissions?.includes("update:quality:score_rule") &
                      showActive ? (
                        <td>
                          <NavLink
                            to={`/admin/prompt-score-form?id=${item.id}`}
                            activeClassName="active"
                          >
                            Editar
                          </NavLink>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                      {permissions?.includes("remove:quality:score_rule") &
                      showActive ? (
                        <td>
                          <ModalConfirm
                            id={item.id}
                            modalText={`Esta ação irá excluir o grupo de regras ${item.title}, você tem certeza?`}
                            handler={() => {
                              handleDelete(item.id);
                            }}
                            icon={"fas fa-trash"}
                          />
                        </td>
                      ) : (
                        <td>
                          {" "}
                          <ModalConfirm
                            id={item.id}
                            modalText={`Só será possível reativar regras de score para segmentos que não possuem uma regra ativa`}
                            handler={() => {
                              handleActivate(item.id);
                            }}
                            buttonColor={"success"}
                            icon={"fas fa-trash-restore"}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>
  );
};
export default QualityScoreRules;
