import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { Card, Container, Row, Button, CardTitle, CardBody } from "reactstrap";

const MetricRuleClientList = () => {
  const [, setOnLoad] = useState(false);
  const [clients, setClients] = useState([]);
  const [search] = useState("");
  const [limit] = useState(100);
  const [updated] = useState(false);
  const [openClients, setOpenClients] = useState(false);

  useEffect(() => {
    setOnLoad(true);
    api
      .get("/clients?limit=100")
      .then((response) => {
        const clients = response.data;
        const clientOptions = [...clients.clients];
        setClients(clientOptions);
        setOpenClients(true);
      })
      .catch((error) => {
        if (error.response.status === 401) toast.error("Sessão expirada!");
        else toast.error("Erro ao recuperar clientes");
      });
  }, [search, limit, updated]);

  return (
    <>
      <Container className="mt-7" fluid>
        <h1 className="text-center">Selecione um Cliente</h1>
        <Row>
          {openClients &&
            clients.map((item, index) => {
              return (
                <Card
                  className="mt-5 mr-2"
                  style={{ width: "18rem" }}
                  key={index}
                >
                  <CardBody>
                    <CardTitle>{item.name}</CardTitle>
                    <NavLink
                      to={`/admin/rules?client=${item.name}`}
                      activeClassName="active"
                      style={{ color: "white" }}
                    >
                      <Button color="primary">Ver regras</Button>
                    </NavLink>
                  </CardBody>
                </Card>
              );
            })}
        </Row>
      </Container>
    </>
  );
};

export default MetricRuleClientList;
