import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "service/api";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import qs from "query-string";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  CardTitle,
  Col,
  CardBody,
} from "reactstrap";

const FavoritesUserByClient = () => {
  const { register, handleSubmit, reset } = useForm();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const [clients, setClient] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [topUsers, setTopUsers] = useState([]);

  useEffect(() => {
    const client = getParamsClient();
    setClient(client);
    api
      .get(
        `/users?page=${offset}&limit=${Number(
          limit,
        )}&clientName=${client}&${search}`,
      )
      .then((response) => response.data)
      .then((response) => {
        setRange(response.totalPages);
        setTotalCount(response.totalCount);
        const users = response.users.map((item) => {
          const created = new Date(item.createdAt).toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return {
            id: item.id,
            client: item.client.name,
            username: item.username,
            createdAt: created,
          };
        });
        setInfo(users);
      });

    api
      .get(
        `/top-favorites?page=${offset}&limit=${Number(
          10,
        )}&clientName=${client}&${search}`,
      )
      .then((response) => response.data)
      .then((response) => {
        const users = response.topFavorites.map((item) => {
          return item.user.username;
        });
        const uniqueUsernames = [...new Set(users)];

        setTopUsers(uniqueUsernames);
      });
  }, [offset, search, limit]);

  function getParamsClient() {
    const queryParams = qs.parse(window.location.search);
    const client = queryParams.client;
    return client ? client : undefined;
  }

  const onSubmit = (data) => {
    const { username, clientName } = data;
    let query = "";
    if (username) {
      query += `username=${username}&`;
    }
    if (clientName) {
      query += `clientName=${clientName}&`;
    }
    setSearch(query);
  };

  const clearSearch = () => {
    let query = "";
    setSearch(query);
    reset();
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-2 pt-md-5 d-flex align-items-center justify-content-center flex-column ">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)} className="d-flex">
              <label className="text-white mr-2 ml-2">Usuário: </label>
              <input
                name="topic"
                id="topic"
                className="Input"
                style={{
                  display: "block",
                  width: "200px",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #cad1d7",
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                }}
                {...register("username")}
              />
              <Button
                className="Button ml-2"
                type="submit"
                style={{ height: "50px" }}
              >
                Buscar
              </Button>
              <Button
                className="Button ml-2"
                onClick={clearSearch}
                style={{ height: "50px" }}
              >
                Limpar
              </Button>
            </Form>
          </div>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <div className="container-fluid mb-1">
          <Row>
            <Col lg="6" xl="3">
              <Card className="card-stats ">
                <CardBody className="pb-1">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h6"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Usuários
                      </CardTitle>
                      <span className="h2 mb-0">{totalCount}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0 ">
                <CardBody className="pb-1 ">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Paginas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{range}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <h2 className="ml-2">Top Usuários:</h2>
              <Table className="align-items-center table-dark" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th>Usuário</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {topUsers.map((item) => (
                    <tr key={item}>
                      <td>{item}</td>
                      <td>
                        <NavLink
                          to={`/admin/users-favorites?client=${clients}&username=${item}&top=true`}
                          activeClassName="active"
                          style={{ color: "white" }}
                        >
                          <Button color="primary">Ver usuário</Button>
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <h2 className="ml-2 mt-2">Usuários:</h2>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Usuário</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>{item.username}</td>
                      <td>
                        <NavLink
                          to={`/admin/users-favorites?client=${clients}&username=${item.username}`}
                          activeClassName="active"
                          style={{ color: "white" }}
                        >
                          <Button color="primary">Ver usuário</Button>
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default FavoritesUserByClient;
