import { useState } from "react";
import { Button, Modal, Row, Col, CardHeader, NavLink } from "reactstrap";
import { NavLink as NavLinkRRD } from "react-router-dom";

const ModalConfirm = ({
  modalText,
  handler,
  disableButton,
  buttonStyle,
  buttonColor,
  icon,
  buttonClassName,
  buttonText,
  sidebar,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Row>
      <Col md="4">
        {sidebar ? (
          <>
            <NavLink
              style={{
                fontSize: "0.84em",
              }}
              to={sidebar.layout + sidebar.path}
              tag={NavLinkRRD}
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
            >
              <i className={icon} /> {buttonText ? buttonText : ""}
            </NavLink>
          </>
        ) : (
          <Button
            block
            type="button"
            color={buttonColor ? buttonColor : "danger"}
            disabled={disableButton ? disableButton : false}
            className={
              buttonClassName ? buttonClassName : "ml-1 btn btn-success"
            }
            style={buttonStyle ? buttonStyle : { width: "60px" }}
            onClick={() => setOpen(!open)}
          >
            <i className={icon} /> {buttonText ? buttonText : ""}
          </Button>
        )}
        <Modal className="modal-dialog-centered modal-lg" isOpen={open}>
          <div className="modal-header ">
            <CardHeader className="bg-white border-0 p-0">
              <Row>
                <Col>
                  <h3 className="mb-2">Aviso</h3>
                  <p className="mb-0">{modalText}</p>
                </Col>
              </Row>
            </CardHeader>
            <button
              aria-label="Close"
              className="close pb-0"
              data-dismiss="modal"
              type="button"
              onClick={() => setOpen(!open)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body pt-0">
            <Button
              type="submit"
              className="btn btn-danger"
              onClick={() => {
                handler();
                setOpen(!open);
              }}
            >
              Confirmar
            </Button>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default ModalConfirm;
