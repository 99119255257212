import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import qs from "query-string";
import { toast } from "react-toastify";
import api from "service/api";
import Select, { components } from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const PreStructuredSessionComponent = ({
  i,
  component,
  register,
  remove,
  errors,
}) => {
  return (
    <Row key={i} className="pl-lg-12 mb-2">
      <Col xl="2">
        <label
          htmlFor={`pre_structured_components.${i}.key`}
          style={{
            fontSize: "0.8em",
          }}
        >
          Chave
        </label>
        <input
          type="text"
          className="form-control"
          style={{
            borderColor:
              errors.pre_structured_components &&
              errors.pre_structured_components[i] &&
              errors.pre_structured_components[i].key
                ? "red"
                : "#cad1d7",
          }}
          defaultValue={component.key}
          {...register(`pre_structured_components.${i}.key`, {
            required: "Necessário informar a chave.",
          })}
        />
      </Col>
      <Col xl="3">
        <label
          htmlFor={`pre_structured_components.${i}.value`}
          style={{
            fontSize: "0.8em",
          }}
        >
          Valor
        </label>
        <input
          type="text"
          className="form-control"
          style={{
            borderColor:
              errors.pre_structured_components &&
              errors.pre_structured_components[i] &&
              errors.pre_structured_components[i].value
                ? "red"
                : "#cad1d7",
          }}
          {...register(`pre_structured_components.${i}.value`, {
            required: "Necessário informar o valor.",
          })}
        />
      </Col>
      <Col xl="4">
        <label
          htmlFor={`pre_structured_components.${i}.description`}
          style={{
            fontSize: "0.8em",
          }}
        >
          Descrição
        </label>
        <input
          type="text"
          className="form-control"
          style={{
            borderColor:
              errors.pre_structured_components &&
              errors.pre_structured_components[i] &&
              errors.pre_structured_components[i].description
                ? "red"
                : "#cad1d7",
          }}
          {...register(`pre_structured_components.${i}.description`, {
            required: "Necessário informar a descrição.",
          })}
        />
      </Col>
      <Col xl="2">
        <label
          htmlFor={`pre_structured_components.${i}.type`}
          style={{
            fontSize: "0.8em",
          }}
        >
          Tipo
        </label>
        <select
          className="form-control"
          style={{
            borderColor:
              errors.pre_structured_components &&
              errors.pre_structured_components[i] &&
              errors.pre_structured_components[i].type
                ? "red"
                : "#cad1d7",
          }}
          {...register(`pre_structured_components.${i}.type`, {
            required: "Necessário informar o tipo.",
          })}
        >
          <option></option>
          <option value={"string"}>Texto</option>
          <option value={"integer"}>Numérico</option>
        </select>
      </Col>
      <Col sm="1" className="align-items-center d-flex justify-content-start">
        <Button
          color="danger"
          size="sm"
          className="mt-4"
          onClick={() => {
            remove(i);
          }}
        >
          X
        </Button>
      </Col>
    </Row>
  );
};
const PreStructuredSession = ({
  components,
  register,
  append,
  remove,
  errors,
}) => {
  return (
    <div className="pl-lg-12 mt-5">
      <Col className="pl-lg-12">
        <FormGroup>
          <Row
            className="align-items-center mb-4"
            style={{
              fontSize: "0.65rem",
              textTransform: "uppercase",
              letterSpacing: "1px",
              borderBottom: "1px solid #e9ecef",
              borderTop: "1px solid #e9ecef",
            }}
          >
            <Col className="d-flex justify-content-start">
              Componentes Pré estruturados
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                color="default"
                size="sm"
                style={{ marginRight: "17px" }}
                onClick={() => {
                  append({
                    id: Date.now(),
                    key: "",
                    value: "",
                    description: "",
                    type: "",
                  });
                }}
              >
                +
              </Button>
            </Col>
          </Row>

          <FormGroup>
            {components?.map((component, i) => {
              return (
                <PreStructuredSessionComponent
                  key={i}
                  i={i}
                  component={component}
                  register={register}
                  remove={remove}
                  errors={errors}
                />
              );
            })}
          </FormGroup>
        </FormGroup>
      </Col>
    </div>
  );
};
const { MultiValueLabel } = components;

const TemplateForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "pre_structured_components",
  });
  const [, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);
  const [sections, setSections] = useState([]);
  const [displayPreStructuredComponents, setDisplayPreStructuredComponents] =
    useState(false);

  const [selectedElement, setSelectedElement] = useState([]);
  const [elements, setElements] = useState([]);
  const [availableElements, setAvailableElements] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);

  const onSubmit = (data) => {
    setLoading(true);
    if (hasId) setDisplayPreStructuredComponents(true);
    if (!data.title.trim()) {
      return toast.error("Informe o nome do template");
    }

    if (
      !sections.length ||
      !sections.every((section) => section.content.trim())
    ) {
      return toast.error("Deve haver ao menos uma seção válida criada.");
    }

    const formattedComponents = data.pre_structured_components.map((el) => {
      if (typeof el.id === "number") {
        const { id, ...rest } = el;
        return rest;
      }
      return el;
    });

    let reusable_elements_id = [];
    if (selectedElements) {
      reusable_elements_id = selectedElements.map((item) => item.id);
    }

    if (hasId) {
      api
        .patch(`/templates/${getParamsId()}`, {
          title: data.title,
          sections,
          preStructuredComponents: formattedComponents,
          reusable_elements_id,
        })
        .then(() => {
          toast.success("Template atualizado com sucesso!");
          setLoading(false);
        })
        .catch(() => {
          toast.error("Erro ao atualizar o Template!");
          setLoading(false);
        });
    } else {
      api
        .post("/templates", {
          title: data.title,
          sections,
          preStructuredComponents: formattedComponents,
          reusable_elements_id,
        })
        .then(() => {
          toast.success("Template cadastrado com sucesso!");
          setLoading(false);
          setSections([]);
          reset();
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response?.data?.message));
          setLoading(false);
        });
    }
  };

  function getParamsId() {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  }

  useEffect(() => {
    const id = getParamsId();

    api
      .get(`/reusable-elements?page=1&limit=10000`)
      .then((response) => {
        setAvailableElements(response.data?.reusableElements);
        setElements(
          response.data?.reusableElements?.map((item) => ({
            id: item.id,
            label: item.title,
            value: item.code,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar os elementos!");
        return;
      });

    if (id) {
      api
        .get(`/templates/${id}`)
        .then((response) => {
          setValue("title", response.data.title);
          setSections(
            response.data.sections.sort((a, b) => a.position - b.position),
          );
          if (response?.data?.preStructuredComponents?.length) {
            setDisplayPreStructuredComponents(true);
            for (const component of response.data.preStructuredComponents) {
              append({
                id: component.id,
                key: component.key,
                value: component.value,
                description: component.description,
                type: component.type,
              });
            }
          }
          setSelectedElements(
            response.data?.templateReusableElements.map((elem) => {
              return {
                id: elem.reusableElement.id,
                label: elem.reusableElement.title,
                value: elem.reusableElement.code,
              };
            }),
          );
        })
        .catch((error) => {
          toast.error("Erro ao recuperar o template!");
          return;
        });

      setHasId(true);
    }
  }, []);

  const CustomOption = ({ children, ...props }) => (
    <MultiValueLabel {...props}>
      <CopyToClipboard
        text={"@" + props.data.value}
        onCopy={() => toast.success("Código copiado com sucesso!")}
        title="Clique para copiar o código"
      >
        <button
          style={{ border: "none" }}
          type="button"
          onClick={() => {
            setSelectedElement(
              elements.filter((item) => item.value == props.data.value)[0],
            );
          }}
        >
          {children}
        </button>
      </CopyToClipboard>
    </MultiValueLabel>
  );

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="" xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Cadastrar Template</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Título
                      </label>
                      <input
                        style={{
                          display: "block",
                          width: "100%",
                          height: "calc(1.5em + 1.25rem + 2px)",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        className="form-control-alternative styled-input"
                        id="template-title"
                        placeholder="Informe um título"
                        type="text"
                        {...register("title")}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="question">
                        Elementos reutilizáveis vinculados (selecione para
                        copiar)
                      </label>
                      <Select
                        defaultValue={selectedElements}
                        isMulti
                        name="colors"
                        options={Object.values(elements)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Selecione os elementos"
                        value={selectedElements}
                        components={{
                          MultiValueLabel: CustomOption,
                        }}
                        onChange={(selected) => {
                          setSelectedElements(selected);
                          const lastOption = selected[selected.length - 1];
                          setSelectedElement(lastOption);
                          if (lastOption) {
                            navigator.clipboard.writeText(
                              "@" + lastOption.value,
                            );
                          }
                        }}
                      />
                      <h4
                        style={{
                          backgroundColor: "#117A65",
                          padding: "10px",
                          borderTopRightRadius: "10px",
                          borderTopLeftRadius: "10px",
                          color: "#fff",
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Prévia do elemento: {selectedElement?.label}
                        <CopyToClipboard
                          text={"@" + selectedElement?.value}
                          onCopy={() =>
                            toast.success("Código copiado com sucesso!")
                          }
                          title="Clique para copiar o código"
                        >
                          <Button type="button" color="primary">
                            @{selectedElement?.value}
                          </Button>
                        </CopyToClipboard>
                      </h4>
                      <h4
                        style={{
                          borderRadius: "4px",
                          backgroundColor: "#D6EAF8",
                          padding: "10px",
                        }}
                      >
                        {selectedElement && availableElements
                          ? availableElements.filter(
                              (element) =>
                                element.code == selectedElement?.value,
                            )[0]?.content
                          : ""}
                      </h4>
                    </FormGroup>
                  </Col>
                </div>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    {sections.map((currentSection) => (
                      <FormGroup
                        key={currentSection.id}
                        style={{ position: "relative" }}
                      >
                        <Button
                          className="Button"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "40px",
                            width: "40px",
                            backgroundColor: "#ffaabb",
                            position: "absolute",
                            top: "-10px",
                            right: "-16px",
                            padding: 0,
                          }}
                          title="Remover esta seção"
                          onClick={() => {
                            setSections(
                              sections.filter(
                                (section) => section.id !== currentSection.id,
                              ),
                            );
                            if (currentSection.createdAt) {
                              api
                                .delete(
                                  `/template-sections/${currentSection.id}`,
                                )
                                .then(() => {
                                  toast.success("Seção deletada com sucesso!");
                                })
                                .catch(() => {
                                  toast.error("Erro ao deletar a seção!");
                                });
                            }
                          }}
                        >
                          ❌
                        </Button>
                        {hasId && (
                          <Button
                            className="Button"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "40px",
                              width: "40px",
                              backgroundColor: "#ABB2B9",
                              position: "absolute",
                              top: "40px",
                              right: "-16px",
                              padding: 0,
                            }}
                            title="Salvar esta seção"
                            onClick={() => {
                              if (currentSection.createdAt) {
                                api
                                  .patch(
                                    `/template-sections/${currentSection.id}`,
                                    {
                                      content: currentSection.content,
                                      position: currentSection.position,
                                    },
                                  )
                                  .then(() => {
                                    toast.success(
                                      "Seção atualizada com sucesso!",
                                    );
                                  })
                                  .catch(() => {
                                    toast.error("Erro ao atualizar a seção!");
                                  });
                              } else {
                                api
                                  .post(`/template-sections`, {
                                    content: currentSection.content,
                                    position: currentSection.position,
                                    template_id: getParamsId(),
                                  })
                                  .then((response) => {
                                    setSections(
                                      sections.map((section) => {
                                        if (section.id !== currentSection.id)
                                          return section;

                                        return {
                                          id: response.data.id,
                                          content: response.data.content,
                                          position: response.data.position,
                                          createAt: response.data.createAt,
                                        };
                                      }),
                                    );
                                    toast.success("Seção criada com sucesso!");
                                  })
                                  .catch(() => {
                                    toast.error("Erro ao criar a seção!");
                                  });
                              }
                            }}
                          >
                            💾
                          </Button>
                        )}
                        <textarea
                          style={{
                            display: "block",
                            width: "100%",
                            minHeight: "100px",
                            resize: "vertical",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                            fontStyle: "normal",
                            marginTop: "20px",
                          }}
                          placeholder="Adicione aqui as informações a respeito desta seção."
                          className="form-control-alternative styled-scroll styled-input"
                          type="text"
                          value={currentSection.content}
                          onChange={(evt) => {
                            setSections(
                              sections.map((section) => {
                                if (section.id !== currentSection.id)
                                  return section;
                                return {
                                  id: section.id,
                                  content: evt.target.value,
                                  position: section.position,
                                  createdAt: section.createdAt,
                                };
                              }),
                            );
                          }}
                          autoComplete="off"
                        />
                      </FormGroup>
                    ))}
                  </Col>
                </div>
                <hr className="my-4" />
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <Button
                        type="button"
                        className="w-100"
                        style={{
                          backgroundColor: "#cad1d7",
                        }}
                        onClick={() => {
                          setSections([
                            ...sections,
                            {
                              id: uuidv4(),
                              content: "",
                              position: sections.length,
                            },
                          ]);
                        }}
                      >
                        Adicionar nova seção
                      </Button>
                      <Button
                        type="button"
                        className="mt-4 w-100"
                        color="primary"
                        style={{
                          marginLeft: 0,
                        }}
                        onClick={() => {
                          if (!hasId && displayPreStructuredComponents) {
                            fields.forEach((el, i) => {
                              remove(i);
                            });
                          }

                          if (!fields.length) {
                            append({
                              id: Date.now(),
                              key: null,
                              value: null,
                              description: null,
                              type: null,
                            });
                          }
                          setDisplayPreStructuredComponents((v) => !v);
                        }}
                      >
                        {displayPreStructuredComponents
                          ? "Minimizar visão de sessão pré-estruturada"
                          : "Adicionar sessão pré-estruturada"}
                      </Button>
                    </FormGroup>
                  </Col>
                </div>
                {displayPreStructuredComponents ? (
                  <PreStructuredSession
                    components={fields}
                    register={register}
                    append={append}
                    remove={remove}
                    errors={errors}
                  />
                ) : (
                  ""
                )}

                <Button type="submit">Adicionar</Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default TemplateForm;
