import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal, Row, Col, ButtonGroup, Button, FormGroup } from "reactstrap";
import newApi from "service/newApi";

const Modals = ({ attendance, analysisId, promptId }) => {
  const { register, handleSubmit, setValue } = useForm();
  const [, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [rSelected, setRSelected] = useState(null);
  const [assertiveness, setAssertiveness] = useState(0);

  const onSubmit = (data) => {
    if (typeof rSelected !== "boolean") {
      return toast.error(
        "Necessário escolher se a resposta está correta ou incorreta",
      );
    }

    if (!data.assertiveness) {
      return toast.error("A assertividade precisa ser um número entre 0 e 100");
    }

    data.assertiveness = Number(data.assertiveness);
    if (data.assertiveness < 0 || data.assertiveness > 100) {
      return toast.error("A assertividade precisa ser um número entre 0 e 100");
    }

    newApi
      .post(`/v1/attendances/analysis/${analysisId}/feedback`, {
        attendance,
        promptId,
        is_correct: rSelected,
        assertiveness: data.assertiveness,
        comment: data?.comment?.trim() ?? null,
      })
      .then((response) => {
        toast.success("Feedback registrado com sucesso!");
        setToggle(false);
        return;
      })
      .catch(() => {
        toast.error("Houve um problema ao tentar registrar o feedback!");
        return;
      });
  };

  useEffect(() => {
    setLoading(true);
    if (toggle) {
      newApi
        .get(`/v1/attendances/analysis/${analysisId}/feedback`)
        .then((response) => response.data)
        .then((response) => {
          if (response) {
            setAssertiveness(response.assertiveness);
            setRSelected(response.isCorrect);
            setValue("assertiveness", response.assertiveness);
            setValue("comment", response.comment);
          }
          setLoading(false);
          return;
        })
        .catch((error) => {
          toast.error("Erro ao tentar recuperar o feedback!");
          setLoading(false);
        });
    }
  }, [toggle]);

  useEffect(() => {
    if (rSelected === true && assertiveness === 0)
      setValue("assertiveness", 100);
    if (rSelected === false && assertiveness === 0)
      setValue("assertiveness", 0);
  }, [rSelected]);

  return (
    <Row>
      <Col md="4">
        <Button
          className="Button"
          size="sm"
          color="primary"
          onClick={() => setToggle(true)}
        >
          Feedback
        </Button>
        <Modal
          className="modal-dialog modal-sm"
          isOpen={toggle}
          toggle={() => setToggle((v) => !v)}
        >
          <div className="modal-header">
            <div className="modal-title">
              <h3>Feedback de análise</h3>
            </div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setToggle((v) => !v)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="pl-lg-2">
                <FormGroup>
                  <label className="form-control-label">
                    A resposta gerada está correta?
                  </label>
                  <br />
                  <ButtonGroup>
                    <Button
                      color="primary"
                      outline
                      onClick={() => setRSelected(true)}
                      active={rSelected === true}
                    >
                      Correta
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={() => setRSelected(false)}
                      active={rSelected === false}
                    >
                      Incorreta
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </div>
              <div className="pl-lg-2">
                <FormGroup>
                  <label className="form-control-label">
                    Assertividade ( 0 ~ 100 ) %
                  </label>
                  <input
                    style={{
                      display: "block",
                      width: "100%",
                      height: "calc(1.5em + 1.25rem + 2px)",
                      padding: "0.625rem 0.75rem",
                      fontSize: "0.875rem",
                      fontWeight: "400",
                      lineHeight: "1.5",
                      color: "#8898aa",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #cad1d7",
                      borderRadius: "0.375rem",
                      boxShadow: "none",
                      transition:
                        "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    }}
                    className="form-control-alternative"
                    id="assertiveness"
                    min="0"
                    max="100"
                    type="number"
                    defaultValue={0}
                    {...register("assertiveness", {
                      onChange: (e) => {
                        setAssertiveness(e.target.value);
                      },
                    })}
                    autoComplete="off"
                  />
                </FormGroup>
              </div>
              <div className="pl-lg-2">
                <FormGroup>
                  <label className="form-control-label">Observação</label>
                  <textarea
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100px",
                      resize: "none",
                      padding: "0.625rem 0.75rem",
                      fontSize: "0.875rem",
                      fontWeight: "400",
                      lineHeight: "1.5",
                      color: "#8898aa",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #cad1d7",
                      borderRadius: "0.375rem",
                      boxShadow: "none",
                      transition:
                        "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    }}
                    className="form-control-alternative"
                    id="comment"
                    type="text"
                    {...register("comment")}
                    autoComplete="off"
                  />
                </FormGroup>
              </div>
              <hr className="my-4" />
              <Button type="submit" color="primary">
                Salvar
              </Button>
              <Button type="button" onClick={() => setToggle((v) => !v)}>
                Cancelar
              </Button>
            </form>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default Modals;
