/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from "tinymce";
import { toast } from "react-toastify";

export function NewEditor({ initialContent, contentValue, setContentValue }) {
  useEffect(() => setContentValue(initialContent ?? ""), [initialContent]);

  function filePickerCallback(callback) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      process.env.CONTENT_ALLOWED_TYPES ??
        "image/png, image/jpg, image/jpeg, image/webp",
    );

    input.addEventListener("change", (e) => {
      const file = e.target.files[0];
      const maxSize = process.env.CONTENT_MAX_SIZE_IN_BYTES ?? 2 * 1024 * 1024; // 2MB
      if (!file.type.startsWith("image/")) {
        toast.error("Apenas arquivos de imagem são permitidos.");
        return;
      }

      if (file.size > maxSize) {
        toast.error(
          `O tamanho máximo de arquivo permitido é de ${
            maxSize / (1024 * 1024)
          }MB.`,
        );
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const id = new Date().getTime().toString();
        const blobCache = tinymce.activeEditor.editorUpload.blobCache;
        const base64 = reader.result.split(",")[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        callback(blobInfo.blobUri(), {
          title: file.name,
        });
      });
      reader.readAsDataURL(file);
    });

    input.click();
  }

  return (
    <Editor
      tinymceScriptSrc="%PUBLIC_URL%/tinymce/tinymce.min.js"
      value={contentValue}
      onEditorChange={(newValue) => setContentValue(newValue)}
      initialValue={initialContent}
      init={{
        height: 500,
        menubar: false,
        language: "pt_BR",
        toolbar_mode: "wrap",
        plugins: [
          "autolink",
          "lists",
          "link",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "fullscreen",
          "help",
          "wordcount",
          "emoticons",
          "accordion",
          "quickbars",
          "image",
        ],
        entity_encoding: "raw",
        quickbars_selection_toolbar:
          "bold italic underline strikethrough | quicklink h1 h2 h3 blockquote",
        quickbars_insert_toolbar: false,
        toolbar:
          "undo redo | blocks fontsize |" +
          "bold italic underline strikethrough forecolor | " +
          "alignleft aligncenter alignright alignjustify bullist numlist charmap emoticons | image | help",
        content_style:
          "body { font-family: Helvetica, Arial, sans-serif; font-size:14px; line-height: 1.1; white-space: pre-line;  } p { margin: 4px 0; }",
        help_accessibility: false,
        image_title: true,
        image_dimensions: false,
        file_picker_types: "image",
        images_file_types:
          process.env.EDITOR_ALLOWED_TYPES ?? "jpg,png,jpeg,webp",
        file_picker_callback: filePickerCallback,
        images_upload_handler: (blobInfo) =>
          new Promise((resolve, reject) => {
            const maxSize =
              process.env.CONTENT_MAX_SIZE_IN_BYTES ?? 2 * 1024 * 1024; // 2MB
            if (blobInfo.blob().size > maxSize) {
              reject({
                message: `O tamanho máximo de arquivo permitido é de ${
                  maxSize / (1024 * 1024)
                }MB.`,
                remove: true,
              });
              return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blobInfo.blob());
          }),
      }}
    />
  );
}
