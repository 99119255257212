import { useEffect, useState } from "react";
import qs from "query-string";
import api from "service/api";
import usePagination from "hooks/usepagination";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import { NewEditor } from "components/Editor";
import { ImageService } from "service/image-service";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const ContentForm = () => {
  const contentTemplate = {
    topicName: "",
    subtopic: "",
    content: "",
    reference: "",
    assistant: {
      name: "",
      hash: "",
    },
  };

  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [availableAssistants, setAvailableAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState();
  const { actualPage } = usePagination();
  const [offset] = useState(actualPage || 1);
  const [addTopic, setAddTopic] = useState(true);
  const [content, setContent] = useState(contentTemplate);
  const [textContent, setTextContent] = useState("");
  const [initialContent, setInitialContent] = useState("");
  const [clientId, setClientId] = useState(null);

  const onSubmit = async (data) => {
    if (!selectedAssistant) {
      toast.error("Selecione um assistente!");
      return;
    }
    setLoading(true);

    const base64ImgRegex =
      /<img\s+[^>]*src\s*=\s*["']data:image\/[^"']*["'][^>]*>/i;
    const hasImages = base64ImgRegex.test(textContent);
    let updatedHTML = textContent;
    if (hasImages) {
      const toastId = toast.loading("Carregando imagens do conteúdo...");
      try {
        const verifyImageSize = ImageService.verifyImageSize(textContent);
        if (!verifyImageSize) {
          toast.update(toastId, {
            render: "Limite de 5mb de imagens ultrapassado!",
            type: "error",
            autoClose: 3000,
            isLoading: false,
          });
          setLoading(false);
          return;
        }
        updatedHTML = await ImageService.uploadAndReplaceImages(
          textContent,
          selectedAssistant?.value?.client?.id || clientId,
        );

        if (!updatedHTML) {
          setLoading(false);
          return;
        }
        toast.update(toastId, {
          render: "Imagens carregadas com sucesso!",
          type: "success",
          autoClose: 3000,
          isLoading: false,
        });
      } catch (error) {
        console.error("Error uploading images:", error);
        toast.update(toastId, {
          render: "Erro ao carregar imagens!",
          type: "error",
          autoClose: 3000,
          isLoading: false,
        });
        setLoading(false);
        return;
      }
    }

    if (data.topic.length === 0) data.topic = content.topicName;
    let contentSubmit = {};

    if (hasId) {
      data.topic_name = data.topic.trim();
      data.reference = data.reference.trim();
      data.text = updatedHTML;
      api
        .put(`/contents/${getParamsId()}`, data)
        .then(() => {
          toast.success("Conteúdo atualizado com sucesso!");
          setLoading(false);
        })
        .catch(() => {
          toast.error("Erro ao atualizar conteúdo!");
          setLoading(false);
        });
    } else {
      contentSubmit.topic_name = data.topic.trim();
      contentSubmit.text = updatedHTML;
      contentSubmit.reference = data.reference.trim();
      contentSubmit.type = "text";
      contentSubmit.addTopic = addTopic;
      contentSubmit.assistant = {
        name: selectedAssistant?.value.name,
        hash: selectedAssistant?.value?.hash,
      };
      contentSubmit.client = {
        name: data.client,
      };
      api
        .post("/contents", contentSubmit)
        .then(() => {
          toast.success("Conteúdo criado com sucesso!");
          setLoading(false);
          reset();
        })
        .catch(() => {
          toast.error("Erro ao criar conteúdo!");
          setLoading(false);
        });
    }
  };

  function getParamsId() {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  }

  useEffect(() => {
    const id = getParamsId();
    if (id) {
      api
        .get(`/contents/${id}`)
        .then((response) => {
          setContent(response.data);

          setSelectedClient({
            label: response.data.client.name,
            value: response.data.client.id,
          });
          setSelectedAssistant({
            label: response.data.assistant.name,
            value: response.data.assistant,
          });
          setClientId(response.data.client.id);
          setInitialContent(response.data.text);
          setTextContent(response.data.text);
        })
        .catch(() => {
          toast.error("Erro ao recuperar conteúdo!");
          return;
        });
      setHasId(true);
    } else {
      api
        .get(`/clients?page=1&limit=10000`)
        .then((response) => {
          setClients(
            response.data.clients.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          );
        })
        .catch(() => {
          toast.error("Erro ao recuperar os clientes!");
          return;
        });

      api
        .get(`/assistants?page=${offset}&limit=${Number(100)}`)
        .then((response) => {
          setAvailableAssistants(
            response.data.assistants.map((item) => ({
              label: item.name,
              value: item,
            })),
          );
        })
        .catch(() => {
          toast.error("Erro ao recuperar assistentes!");
          return;
        });
    }
  }, []);

  useEffect(() => {
    if (selectedClient.value) {
      setAvailableAssistants(
        availableAssistants.filter(
          (item) => item.value.client.id === selectedClient.value,
        ),
      );
    }
  }, [selectedClient]);

  return (
    <Container className="mt-4" fluid>
      <Col className="" xl="11">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Adicionar conteúdo</h3>
              </Col>
              <Col className="text-right" xs="4"></Col>
            </Row>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <h6 className="heading-small text-muted mb-2">Cliente</h6>
              <div className="pl-lg-4 mb-4">
                <Row>
                  <Col lg="6">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={clients}
                      placeholder="Selecione um cliente"
                      value={selectedClient}
                      isSearchable={true}
                      isClearable={true}
                      isDisabled={hasId || loading}
                      onChange={setSelectedClient}
                    />
                  </Col>
                </Row>
              </div>
              <h6 className="heading-small text-muted mb-2">Assistente</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={availableAssistants}
                      placeholder={
                        availableAssistants.length === 0
                          ? "Nenhum assistente disponível"
                          : "Selecione um assistente"
                      }
                      value={selectedAssistant}
                      isSearchable={true}
                      isClearable={true}
                      isDisabled={
                        hasId || loading || availableAssistants.length === 0
                      }
                      onChange={setSelectedAssistant}
                    />
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />
              <h6 className="heading-small text-muted mb-2">
                Informações do conteúdo
              </h6>
              <div className="pl-lg-4">
                <Row>
                  <Col md="12" lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-topic"
                      >
                        Tópico
                      </label>
                      <input
                        style={{
                          display: "block",
                          width: "100%",
                          height: "calc(1.5em + 1.25rem + 2px)",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        className="form-control-alternative"
                        defaultValue={content.topicName}
                        id="topic"
                        placeholder="Adicione um tópico para seu conteúdo"
                        type="text"
                        {...register("topic")}
                        autoComplete="off"
                      />
                      <label
                        className="form-control-label mr-1 mt-3"
                        htmlFor="input-topic"
                      >
                        Adicionar tópico como parâmetro de treinamento
                      </label>
                      <input
                        type="checkbox"
                        value={addTopic}
                        checked={addTopic}
                        onClick={() => setAddTopic(!addTopic)}
                        name="addTopic"
                        {...register("addTopic")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-reference"
                      >
                        Referência
                      </label>
                      <input
                        className="form-control-alternative"
                        defaultValue={content.reference}
                        style={{
                          display: "block",
                          width: "100%",
                          height: "calc(1.5em + 1.25rem + 2px)",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        id="input-reference"
                        placeholder="Insira aqui um link ou identificador da referência"
                        type="text"
                        {...register("reference")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="pl-lg-4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-reference"
                  >
                    Conteúdo
                  </label>
                  <NewEditor
                    initialContent={initialContent}
                    contentValue={textContent}
                    setContentValue={setTextContent}
                  />
                </FormGroup>
              </div>
              <Button type="submit" disabled={loading}>
                {initialContent ? "Atualizar" : "Adicionar"}
              </Button>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default ContentForm;
