import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import ModalConfirm from "../components/Modal/ModalConfirm";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  CardTitle,
  Col,
  CardBody,
} from "reactstrap";

const Users = () => {
  const { register, handleSubmit, reset } = useForm();
  const [, setCollapseOpen] = useState();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const [clients, setClient] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [, setOnLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [isdisabled, setDisabled] = useState(false);
  const permissions = localStorage.getItem("permissions");

  useEffect(() => {
    setOnLoad(true);
    api
      .get(`/users?page=${offset}&limit=${Number(limit)}&${search}`)
      .then((response) => response.data)
      .then((response) => {
        setRange(response.totalPages);
        setTotalCount(response.totalCount);
        const users = response.users.map((item) => {
          const created = new Date(item.createdAt).toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return {
            id: item.id,
            client: item.client.name,
            username: item.username,
            externalId: item.externalId,
            createdAt: created,
          };
        });
        setInfo(users);

        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar usuários!");
        return;
      });

    if (!clients.length) {
      api
        .get("/clients")
        .then((response) => {
          const clients = response.data;
          const clientOptions = [...clients.clients];
          setClient(clientOptions);
        })
        .catch(() => {
          toast.error("Erro ao recuperar clientes!");
          return;
        });
    }
  }, [offset, search, limit]);

  const handleDelete = (id) => {
    setDisabled(true);
    api
      .delete(`/users/${id}`)
      .then(() => {
        toast.success("Usuário deletado com sucesso!");
        const infoFilter = info.filter((item) => item.id !== id);
        setInfo(infoFilter);
        setDisabled(false);
      })
      .catch(() => {
        toast.error("Erro ao deletar usuário!");
        setDisabled(false);
      });
  };

  const onSubmit = (data) => {
    const { username, clientName } = data;
    let query = "";
    if (username) {
      query += `username=${username}&`;
    }
    if (clientName) {
      query += `clientName=${clientName}&`;
    }
    setSearch(query);
  };

  const clearSearch = () => {
    let query = "";
    setSearch(query);
    reset();
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-3 pt-md-3 d-flex align-items-center justify-content-center flex-column">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)} className="d-flex">
              <select
                name="username"
                id="username"
                className="Input"
                style={{
                  display: "block",
                  width: "20%",
                  minWidth: "180px",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #cad1d7",
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                  marginRight: "20px",
                }}
                {...register("clientName")}
              >
                <option value="">Selecione um cliente</option>
                {clients.map((client, index) => {
                  return (
                    <option value={client.name} key={index}>
                      {client.name}
                    </option>
                  );
                })}
              </select>
              <input
                name="topic"
                id="topic"
                className="Input"
                placeholder="Filtrar por usuário"
                style={{
                  display: "block",
                  width: "200px",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #cad1d7",
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                  marginRight: "10px",
                }}
                {...register("username")}
              />
              <Button
                className="Button ml-2"
                type="submit"
                style={{ height: "42px" }}
              >
                Buscar
              </Button>
              <Button
                className="Button ml-2"
                onClick={clearSearch}
                style={{ height: "42" }}
              >
                Limpar
              </Button>
            </Form>
          </div>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <div className="container-fluid mb-1">
          <Row>
            <Col lg="6" xl="3">
              <Card className="card-stats ">
                <CardBody className="pb-1">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h6"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Usuários
                      </CardTitle>
                      <span className="h2 mb-0">{totalCount}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0 ">
                <CardBody className="pb-1 ">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Paginas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{range}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Usuário</th>
                    <th>Cliente</th>
                    <th>Criado</th>
                    {permissions?.includes("update:user") && <th>Editar</th>}
                    {permissions?.includes("remove:user") && <th>Excluir</th>}
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>{item.username}</td>
                      <td>{item.client}</td>
                      <td>{item.createdAt}</td>
                      {permissions?.includes("update:user") && (
                        <td>
                          <NavLink
                            to={`/admin/users-form?id=${item.id}`}
                            onClick={closeCollapse}
                            activeClassName="active"
                          >
                            Editar
                          </NavLink>
                        </td>
                      )}
                      {permissions?.includes("remove:user") && (
                        <td>
                          <ModalConfirm
                            id={item.id}
                            modalText={`Esta ação irá excluir o usuário ${item.username}, você tem certeza?`}
                            handler={() => {
                              handleDelete(item.id);
                            }}
                            icon={"fas fa-trash"}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Users;
