import { useState } from "react";
import { Button, Modal, Row, Col, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
const ModalRemoveReusableElement = ({
  id,
  handleDelete,
  icon,
  templateElements,
  promptElements,
}) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  return (
    <Row>
      <Col md="4">
        <Button
          block
          type="button"
          color="danger"
          className="ml-3 mb-2 btn btn-success"
          style={{ width: "200px" }}
          onClick={() => toggleModal("defaultModal")}
        >
          <i className={icon} />
        </Button>
        <Modal
          className="modal-dialog-centered modal-lg"
          isOpen={defaultModal}
          toggle={() => toggleModal("defaultModal")}
        >
          <div className="modal-header ">
            <CardHeader className="bg-white border-0 p-0">
              <Row>
                <Col>
                  <h3 className="mb-0">
                    Tem certeza que deseja remover este elemento reutilizável?
                  </h3>
                  {(templateElements.length > 0 ||
                    promptElements.length > 0) && (
                    <p className="mb-0">
                      Este elemento está sendo utilizado em algum
                      template/prompt, para excluir este elemento você deve
                      remover estes vínculos.
                    </p>
                  )}

                  {templateElements.length > 0 && (
                    <>
                      <h4
                        style={{
                          backgroundColor: "#117A65",
                          padding: "10px",
                          borderTopRightRadius: "10px",
                          borderTopLeftRadius: "10px",
                          color: "#fff",
                          marginTop: "10px",
                        }}
                      >
                        Templates com este elemento
                      </h4>
                      <h3
                        style={{
                          borderRadius: "4px",
                          backgroundColor: "#D6EAF8",
                          padding: "10px",
                        }}
                      >
                        {templateElements.map((element) => (
                          <Link
                            key={element.id}
                            to={`/admin/template-form?id=${element?.template?.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Abrir em uma nova aba"
                            activeClassName="active"
                          >
                            {element?.template?.title}
                          </Link>
                        ))}
                      </h3>
                    </>
                  )}

                  {promptElements.length > 0 && (
                    <>
                      <h4
                        style={{
                          backgroundColor: "#117A65",
                          padding: "10px",
                          borderTopRightRadius: "10px",
                          borderTopLeftRadius: "10px",
                          color: "#fff",
                          marginTop: "10px",
                        }}
                      >
                        Prompts com este elemento
                      </h4>
                      <div
                        style={{
                          borderRadius: "4px",
                          backgroundColor: "#D6EAF8",
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {promptElements.map((element) => (
                          <Link
                            key={element.id}
                            to={`/admin/prompts-form?id=${element?.prompt?.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Abrir em uma nova aba"
                            activeClassName="active"
                          >
                            {element?.prompt?.title}
                          </Link>
                        ))}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <button
              aria-label="Close"
              className="close pb-0"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body pt-0">
            <Button
              type="submit"
              className="btn btn-danger"
              onClick={() => handleDelete(id)}
            >
              Confirmar
            </Button>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default ModalRemoveReusableElement;
