import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, CardBody, Row, Col, FormGroup } from "reactstrap";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import api from "service/api";
import Loading from "components/Loading";

const ModalEditProviderCredentials = (props) => {
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(props.models);
  const [isPasswordVisible, setIsPasswordVisible] = useState(true);
  const { register, handleSubmit, reset, setValue } = useForm();
  const [isDefault, setIsDefault] = useState(props?.clientConfig?.isDefault);
  const [budget, setBudget] = useState(
    props?.clientConfig?.budgetLimit ? props?.clientConfig?.budgetLimit : 0,
  );
  const [budgetText, setBudgetText] = useState(
    props?.clientConfig?.budgetLimit
      ? String(props?.clientConfig?.budgetLimit)
      : "0",
  );
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([{}]);
  const [customFieldsHidden, setCustomFieldsHidden] = useState(false);
  const [customFieldParams, setcustomFieldParams] = useState({});

  const permissions = localStorage.getItem("permissions");

  const onSubmit = (data) => {
    const customFieldsObject = {};
    Object.keys(customFieldParams).forEach((key) => {
      const index = key.replace(/\D/g, "");
      const fieldName = fields[index]["field-" + index];
      customFieldsObject[fieldName] = customFieldParams[key];
    });

    setLoading(true);

    const providerConfig = {
      providerId: props.id,
      modelId: data.modelId,
      organizationId: data.organizationId,
      secretKey: data.secretKey,
      isDefault: data.isDefault,
      budgetLimit: budget,
      customFields: customFieldsObject,
    };
    api
      .put("/providers/configuration/" + props.client.id, providerConfig)
      .then((response) => {
        toast.success("Configuração de provedor registrada com sucesso!");
        props.setUpdated(!props.updated);
        props.updateClientConfig({
          ...providerConfig,
          secretKey: null,
          organizationId: null,
        });
        setIsPasswordVisible(true);
        setOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro ao registrar configuração de provedor!");
        setLoading(false);
      });
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(false);
  };

  function handleDefault(e) {
    e.preventDefault();
    setIsDefault(!isDefault);
  }
  const addCustomForm = () => {
    if (!customFieldsHidden) {
      setCustomFieldsHidden(true);
      return;
    }
    setFields([...fields, { fieldName: "", valor: "" }]);
  };

  const handleChange = (index, event) => {
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
    const newFieldParams = { ...customFieldParams };
    newFieldParams[event.target.name] = event.target.value;
    setcustomFieldParams(newFieldParams);
  };

  return (
    <>
      <Button
        color="primary"
        type="button"
        size="sm"
        onClick={() => setOpen(!open)}
      >
        Configurar Credenciais
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={() => setOpen(!open)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Configurar Credenciais - {props.name}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setOpen(!open)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pl-lg-4">
              <Row>
                <Col md="12" lg="8">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="client">
                      Modelo
                    </label>
                    <select
                      style={{
                        display: "block",
                        width: "100%",
                        height: "calc(1.5em + 1.25rem + 2px)",
                        padding: "0.625rem 0.75rem",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      value={model.id}
                      className="form-control-alternative"
                      id="modelId"
                      type="text"
                      {...register("modelId")}
                      defaultValue={model.id}
                      autoComplete="off"
                      onChange={(e) =>
                        setModel(
                          props.models.map((model) => {
                            if (model.id == e.target.value) {
                              return model;
                            }
                          }),
                        )
                      }
                    >
                      {props.models.map((model) => {
                        return (
                          <option key={model.id} value={model.id}>
                            {model.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="pl-lg-4">
              <Row>
                <Col md="12" lg="8">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      Organization ID
                    </label>
                    <input
                      disabled={
                        !(
                          permissions?.includes("create:provider_config") ||
                          permissions?.includes("update:provider_config")
                        )
                      }
                      style={{
                        display: "block",
                        width: "100%",
                        height: "calc(1.5em + 1.25rem + 2px)",
                        padding: "0.625rem 0.75rem",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      className="form-control-alternative"
                      id="organizationId"
                      placeholder="Organization ID"
                      value={props?.clientConfig?.organizationId}
                      type="text"
                      {...register("organizationId")}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="pl-lg-4">
              <Row>
                <Col md="12" lg="8">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      Secret Key
                    </label>
                    <input
                      style={{
                        display: "block",
                        width: "100%",
                        height: "calc(1.5em + 1.25rem + 2px)",
                        padding: "0.625rem 0.75rem",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      disabled={
                        !(
                          permissions?.includes("create:provider_config") ||
                          permissions?.includes("update:provider_config")
                        )
                      }
                      className="form-control-alternative"
                      id="secretKey"
                      placeholder="Secret Key"
                      required
                      {...register("secretKey")}
                      autoComplete="off"
                      value={props?.clientConfig?.secretKey}
                      type={isPasswordVisible ? "text" : "password"}
                      onClick={(e) => {
                        togglePasswordVisibility();
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="pl-lg-4">
              <Row>
                <Col md="12" lg="8">
                  <FormGroup>
                    {customFieldsHidden &&
                      fields.map((field, index) => (
                        <div key={index}>
                          <label className="form-control-label" htmlFor="name">
                            Nome do campo
                          </label>
                          <input
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              color: "#8898aa",
                              backgroundColor: "#fff",
                              backgroundClip: "padding-box",
                              border: "1px solid #cad1d7",
                              borderRadius: "0.375rem",
                              boxShadow: "none",
                              transition:
                                "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                            }}
                            type="text"
                            placeholder="Nome do Campo"
                            name={`field-${index}`}
                            value={field["nomeCampo" + index]}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <label
                            className="form-control-label pt-2"
                            htmlFor="name"
                          >
                            Valor
                          </label>
                          <input
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              color: "#8898aa",
                              backgroundColor: "#fff",
                              backgroundClip: "padding-box",
                              border: "1px solid #cad1d7",
                              borderRadius: "0.375rem",
                              boxShadow: "none",
                              transition:
                                "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                            }}
                            type="text"
                            placeholder="Valor"
                            name={`value-${index}`}
                            value={field["valor" + index]}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </div>
                      ))}
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="pl-lg-4 pb-4">
              <Row>
                <Col md="12" lg="8">
                  <Button
                    color="default"
                    className="add-button"
                    onClick={addCustomForm}
                  >
                    + Adicionar campo
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="pl-lg-4">
              <Row>
                <Col md="12" lg="8">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      Budget Limit(USD)
                    </label>
                    <CurrencyInput
                      style={{
                        display: "block",
                        width: "100%",
                        height: "calc(1.5em + 1.25rem + 2px)",
                        padding: "0.625rem 0.75rem",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      decimalsLimit={2}
                      prefix="$"
                      className="form-control-alternative"
                      id="budgetLimit"
                      decimalSeparator=","
                      groupSeparator="."
                      value={budgetText}
                      defaultValue={budgetText}
                      onValueChange={(value) => {
                        if (!value) {
                          setBudget(0);
                          setBudgetText("");
                        } else {
                          setBudgetText(value);
                          setBudget(Number(budgetText.replace(",", ".")));
                        }
                      }}
                      required
                      {...register("budgetLimit")}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <FormGroup className="mb-1 ml-4">
              <input
                type="checkbox"
                name="isDefault"
                checked={isDefault}
                onClick={() => {
                  setIsDefault(!isDefault);
                }}
                {...register("isDefault")}
              />
              <label className="form-control-label ml-2" htmlFor="input-topic">
                Provedor padrão
              </label>
            </FormGroup>
            <div className="modal-footer d-block">
              <Button type="submit" disabled={loading}>
                {loading ? (
                  <Loading
                    divStyle={{
                      width: "105px",
                    }}
                    loadingStyle={{
                      width: 30,
                      height: 20,
                    }}
                  />
                ) : (
                  "Atualizar"
                )}
              </Button>
            </div>
          </form>
        </CardBody>
      </Modal>
    </>
  );
};

export default ModalEditProviderCredentials;
