import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import usePagination from "hooks/usepagination";
import PaginationComponent from "components/Pagination/Pagination";
import {
  Button,
  Modal,
  Row,
  Col,
  CardHeader,
  Form,
  Card,
  CardBody,
  CardTitle,
  Table,
  CardFooter,
} from "reactstrap";

const ModalUsers = (props) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);

  const { register, handleSubmit, reset } = useForm();
  const [, setCollapseOpen] = useState();
  const [range, setRange] = useState(0);
  const [users, setUsers] = useState([]);
  const [clients, setClient] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [, setOnLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);

  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setOnLoad(true);
    api
      .get(`/users?page=${offset}&limit=${Number(limit)}&${search}`)
      .then((response) => response.data)
      .then((response) => {
        setRange(response.totalPages);
        setTotalCount(response.totalCount);
        const users = response.users.map((item) => {
          const created = new Date(item.createdAt).toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return {
            id: item.id,
            client: item.client.name,
            username: item.username,
            externalId: item.externalId,
            createdAt: created,
          };
        });
        setUsers(users);

        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar usuários!");
        return;
      });

    if (!clients.length) {
      api
        .get("/clients")
        .then((response) => {
          const clients = response.data;
          const clientOptions = [...clients.clients];
          setClient(clientOptions);
        })
        .catch(() => {
          toast.error("Erro ao recuperar clientes!");
          return;
        });
    }
  }, [offset, search, limit]);

  const onSubmit = (data) => {
    const { username, clientName } = data;
    let query = "";
    if (username) {
      query += `username=${username}&`;
    }
    if (clientName) {
      query += `clientName=${clientName}&`;
    }
    setSearch(query);
  };

  const clearSearch = () => {
    let query = "";
    setSearch(query);
    reset();
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleChangeSelectedUsers = (user) => {
    if (props.selectedUsers.includes(user.id)) {
      props.setSelectedUsers(
        props.selectedUsers.filter((userId) => userId !== user.id),
      );
    } else {
      props.setSelectedUsers([...props.selectedUsers, user.id]);
    }
  };

  return (
    <Row>
      <Col md="4">
        <Button
          block
          color="primary"
          type="button"
          style={{ width: "200px" }}
          onClick={() => {
            toggleModal("defaultModal");
            props.onClick(true);
          }}
        >
          Vincular usuários
        </Button>
        <Modal
          className="modal-dialog-centered modal-xl"
          isOpen={defaultModal}
          toggle={() => {
            toggleModal("defaultModal");
            props.onClick(true);
          }}
        >
          <div className="modal-header flex flex-column">
            <button
              aria-label="Close"
              className="close pb-0"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                toggleModal("defaultModal");
                props.onClick(false);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid mb-2">
              <Form onSubmit={handleSubmit(onSubmit)} className="d-flex">
                <select
                  name="username"
                  id="username"
                  className="Input"
                  style={{
                    display: "block",
                    width: "20%",
                    minWidth: "180px",
                    height: "calc(1.5em + 1.25rem + 2px)",
                    padding: "0.625rem 0.75rem",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginRight: "20px",
                  }}
                  {...register("clientName")}
                >
                  <option value="">Selecione um cliente</option>
                  {clients.map((client, index) => {
                    return (
                      <option value={client.name} key={index}>
                        {client.name}
                      </option>
                    );
                  })}
                </select>
                <input
                  name="topic"
                  id="topic"
                  className="Input"
                  placeholder="Filtrar por usuário"
                  style={{
                    display: "block",
                    width: "200px",
                    height: "calc(1.5em + 1.25rem + 2px)",
                    padding: "0.625rem 0.75rem",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginRight: "10px",
                  }}
                  {...register("username")}
                />
                <Button
                  className="Button ml-2"
                  type="submit"
                  style={{ height: "42px" }}
                >
                  Buscar
                </Button>
                <Button
                  className="Button ml-2"
                  onClick={clearSearch}
                  style={{ height: "42" }}
                >
                  Limpar
                </Button>
              </Form>
            </div>
            <div className="container-fluid mb-1">
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats ">
                    <CardBody className="p-1">
                      <div className="d-flex flex-row align-items-center">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 mr-2"
                        >
                          Usuários:
                        </CardTitle>
                        <span className="h4 mb-0 mr-2">{totalCount}</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0 ">
                    <CardBody className="p-1">
                      <div className="d-flex flex-row align-items-center">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 mr-2"
                        >
                          Paginas:
                        </CardTitle>
                        <span className="h4 font-weight-bold mb-0">
                          {range}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex ml-2">
                  <select
                    style={{}}
                    name="limit"
                    id="limit"
                    className="Input"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Selecione</th>
                      <th>Usuário</th>
                      <th>Cliente</th>
                      <th>Criado</th>
                    </tr>
                  </thead>
                  <tbody className="custom-line">
                    {users.map((item) => (
                      <tr
                        key={item.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleChangeSelectedUsers(item)}
                      >
                        <td>
                          <input
                            className="ml-4"
                            type="checkbox"
                            value={item}
                            checked={props.selectedUsers.includes(item.id)}
                            onChange={() => handleChangeSelectedUsers(item)}
                          />
                        </td>
                        <td>{item.username}</td>
                        <td>{item.client}</td>
                        <td>{item.createdAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="..." className="container-fluid ">
                    <Button
                      className="Button"
                      onClick={() => {
                        props.setSelectedUsers([
                          ...props.selectedUsers,
                          ...users.map((user) => user.id),
                        ]);
                      }}
                    >
                      Selecionar todos
                    </Button>
                    <PaginationComponent
                      limit={limit}
                      total={range}
                      offset={offset}
                      setOffset={setOffset}
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
            <Button
              className="mt-4 ml-4"
              color="primary"
              type="submit"
              onClick={() => {
                toggleModal("defaultModal");
                props.onClick(false);
              }}
            >
              Vincular
            </Button>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default ModalUsers;
