import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Table,
  Alert,
  Button,
  CardText,
} from "reactstrap";
// core components
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";
import api from "service/api";

import { AnalysisStatusService } from "service/analysis-status";

import Loading from "components/Loading";

const AnalysisProcess = () => {
  moment.locale("pt-br");
  const { id: processTraceable } = useParams();
  const [batch, setBatch] = useState({});
  const [loading, setLoading] = useState(false);
  const [lastBatchStatus, setLastBatchStatus] = useState(null);
  const [isProcessComplete, setIsProcessComplete] = useState(false);
  const [analysis, setAnalysis] = useState([]);

  useEffect(() => {
    setLoading(true);

    api
      .get(`/analysis/processes/${processTraceable}`)
      .then((response) => response.data)
      .then((response) => {
        setBatch(response ?? {});
        setLoading(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar as análises do atendimento!");
        setLoading(false);
        return;
      });
  }, [processTraceable]);

  useEffect(() => {
    if (batch?.status?.length) {
      const lastStatus = batch?.status[batch?.status?.length - 1] ?? {};
      setLastBatchStatus(lastStatus);
      if (lastStatus?.status?.status === "COMPLETED") {
        setIsProcessComplete(true);
      }
    }

    if (batch?.attendances?.length) {
      const attendancesStatus = batch?.attendances?.flatMap((at) => {
        return [
          ...at.status.flatMap((atStatus) => {
            return {
              ...atStatus,
              type: "ATTENDANCE",
              attendance: at.attendance,
            };
          }),
          ...at.analysis.flatMap((an) => {
            return an.status.flatMap((anStatus) => {
              return {
                ...anStatus,
                type: "ANALYSIS",
                attendance: at.attendance,
              };
            });
          }),
        ];
      });
      setAnalysis(attendancesStatus);
    }
  }, [batch]);

  return (
    <>
      {/* Page content */}
      <Container className="mt-7" fluid>
        {/* <Row> */}
        <Col className="" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Processamento {processTraceable}</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <Alert
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      backgroundColor: AnalysisStatusService.getStatusCssColor(
                        lastBatchStatus?.status?.status,
                      ).background,
                      color: AnalysisStatusService.getStatusCssColor(
                        lastBatchStatus?.status?.status,
                      ).color,
                    }}
                  >
                    Status atual do processamento:&nbsp;
                    <b>
                      {batch?.status?.length > 0
                        ? lastBatchStatus?.status?.status
                        : "N/A"}
                    </b>{" "}
                    <label
                      style={{
                        width: "30px",
                        height: "30px",
                        position: "relative",
                        margin: 0,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {isProcessComplete ? (
                        <i className="ni ni-check-bold" color="inherit" />
                      ) : (
                        <Loading
                          style={{ backgroundColor: "transparent", margin: 0 }}
                        />
                      )}{" "}
                    </label>
                    Iniciado{" "}
                    {batch.started_at
                      ? moment(batch.started_at).fromNow()
                      : "N/A"}{" "}
                    ( {moment(batch.started_at).format("DD/MM/YYYY HH:mm")} )
                  </Alert>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <div>
                      <label className="form-control-label">
                        Processado por:{" "}
                        <span
                          style={{
                            fontWeight: 300,
                          }}
                        >
                          {batch.username ?? "N/A"}
                        </span>
                      </label>
                      <br />

                      <label className="form-control-label">
                        Segmento:{" "}
                        <span
                          style={{
                            fontWeight: 300,
                          }}
                        >
                          {batch?.segment?.title ?? "N/A"}
                        </span>
                      </label>
                      <br />

                      <label className="form-control-label">
                        Cliente:{" "}
                        <span
                          style={{
                            fontWeight: 300,
                          }}
                        >
                          {batch?.client?.name ?? "N/A"}
                        </span>
                      </label>
                      <br />

                      <label className="form-control-label">
                        Total atendimentos:{" "}
                        <span
                          style={{
                            fontWeight: 300,
                          }}
                        >
                          {batch?.attendances?.length ?? "N/A"}
                        </span>
                      </label>
                      <br />

                      <label className="form-control-label">
                        Iniciado em:{" "}
                        <span
                          style={{
                            fontWeight: 300,
                          }}
                        >
                          {batch?.started_at
                            ? moment(batch?.started_at).format(
                                "DD/MM/YYYY HH:mm",
                              )
                            : "N/A"}
                        </span>
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label">
                      Histórico de status do processamento
                    </label>
                    <div
                      className="styled-scroll"
                      style={{
                        width: "100%",
                        height: "400px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      {loading ? <Loading /> : ""}
                      {batch?.status?.length
                        ? batch?.status?.map((s, i) => {
                            const css = AnalysisStatusService.getStatusCssColor(
                              s.status.status,
                            );
                            return (
                              <Card key={i}>
                                <CardHeader
                                  style={{
                                    backgroundColor: css.background,
                                    color: css.color,
                                  }}
                                  tag="h5"
                                  color="primary"
                                >
                                  {s.status.status}
                                </CardHeader>
                                <CardBody>
                                  <CardText>
                                    <p
                                      style={{
                                        fontSize: "0.9em",
                                      }}
                                    >
                                      Execução{" "}
                                      <b>
                                        {s?.execution_datetime
                                          ? moment(
                                              s?.execution_datetime,
                                            ).format("DD/MM/YYYY HH:mm")
                                          : "N/A"}
                                      </b>
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "0.9em",
                                      }}
                                    >
                                      Mensagem: <i>{s.message}</i>
                                    </p>
                                  </CardText>
                                </CardBody>
                              </Card>
                            );
                          })
                        : ""}
                    </div>
                  </FormGroup>
                </Col>
              </div>
              <br />
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <Row
                      style={{
                        margin: "8px 0",
                      }}
                    >
                      <Col
                        className="text-center d-flex align-items-center justify-content-flex-start"
                        md={12}
                        style={{
                          padding: 0,
                        }}
                      >
                        <label
                          className="form-control-label"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          Histórico de status de análises
                        </label>
                      </Col>
                    </Row>
                    <div
                      className="styled-scroll"
                      style={{
                        width: "100%",
                        height: "400px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      {loading ? <Loading /> : ""}
                      {analysis.map((an, i) => {
                        const css = AnalysisStatusService.getStatusCssColor(
                          an.status.status,
                        );
                        return (
                          <Card key={i}>
                            <CardHeader
                              style={{
                                backgroundColor: css.background,
                                color: css.color,
                              }}
                              tag="h5"
                            >
                              {an.type == "ATTENDANCE"
                                ? "Atendimento"
                                : "Análise"}{" "}
                              - {an.status.status} - {an.attendance}
                            </CardHeader>
                            <CardBody>
                              <CardText>
                                <p
                                  style={{
                                    fontSize: "0.9em",
                                  }}
                                >
                                  Execução{" "}
                                  <b>
                                    {an?.execution_datetime
                                      ? moment(an?.execution_datetime).format(
                                          "DD/MM/YYYY HH:mm",
                                        )
                                      : "N/A"}
                                  </b>
                                </p>
                                <p
                                  style={{
                                    fontSize: "0.9em",
                                  }}
                                >
                                  Mensagem: <i>{an.message}</i>
                                </p>
                              </CardText>
                            </CardBody>
                          </Card>
                        );
                      })}
                    </div>
                  </FormGroup>
                </Col>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* </Row> */}
      </Container>
    </>
  );
};

export default AnalysisProcess;
