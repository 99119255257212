import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Container, Progress } from "reactstrap";
import DatePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";
import qs from "query-string";
import api from "service/api";
import Loading from "components/Loading";

const ClientSummary = () => {
  const [selectedDate, setSelectedDate] = useState(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  });
  const [lastUpdated, setLastUpdated] = useState();
  const [summaryData, setSummaryData] = useState();
  const [loading, setLoading] = useState(true);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchSummaryData(date);
  };
  const getParamsId = () => {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  };

  useEffect(() => {
    fetchSummaryData(selectedDate);
  }, [selectedDate]);

  const fetchSummaryData = async (date) => {
    setLoading(true);
    api
      .get("/client/summary", {
        params: {
          clientId: getParamsId(),
          date: date.toISOString(),
        },
      })
      .then((response) => {
        const summaryBySegment = response.data.segments.map((item) => {
          const analysis = response?.data?.analysisSummary?.analysis?.find(
            (analysis) => analysis?.segment_id === item?.id,
          );

          return {
            name: item?.title,
            isDeleted: item?.deletedAt ? true : false,
            monthlyExpense: analysis?.total_price || 0,
            totalAttendances: analysis?.total_attendances || 0,
            totalAnalyses: analysis?.total_analyses || 0,
          };
        });
        const clientData = {
          name: response?.data?.name,
          assistants: response?.data?.assistants,
          segments: summaryBySegment.filter(
            (segment) => segment.totalAnalyses > 0,
          ),
          totalMonthlyExpense: response?.data?.analysisSummary?.analysis.reduce(
            (acc, segment) => acc + segment.total_price,
            0,
          ),
          totalEvents: response?.data?.analysisSummary?.analysis.reduce(
            (acc, segment) => acc + segment.total_attendances,
            0,
          ),
          totalAnalysis: response?.data?.analysisSummary?.analysis.reduce(
            (acc, segment) => acc + segment.total_analyses,
            0,
          ),
          budgetLimit: response?.data?.budgetLimit,
        };
        setSummaryData(clientData);
        setLastUpdated(response?.data?.analysisSummary?.lastUpdated);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const formatDateTime = (date) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return new Date(date).toLocaleDateString("pt-BR", options).replace(",", "");
  };

  return (
    <Container className="mt-2 p-2" fluid>
      <h1>Resumo do Cliente</h1>
      <CardBody>
        <CardTitle>Selecione o Mês</CardTitle>
        <div
          style={{
            width: "300px",
          }}
        >
          <DatePicker
            inputProps={{
              placeholder: "Selecione o mês...",
            }}
            dateFormat="MM/YYYY"
            timeFormat={false}
            value={selectedDate}
            onChange={handleDateChange}
            viewMode="months"
          />
        </div>
      </CardBody>
      <p>Última atualização: {formatDateTime(lastUpdated)}</p>

      {loading ? <Loading /> : null}

      <Card className="mb-3">
        <CardBody>
          <CardTitle>{summaryData?.name}</CardTitle>
          <p>Quantidade de Assistentes: {summaryData?.assistants}</p>
          <p>Quantidade de Segmentos Ativos: {summaryData?.segments.length}</p>
          <p>
            Orçamento Mensal: ${" "}
            {summaryData?.budgetLimit.length > 0
              ? summaryData?.budgetLimit?.map((item, index) => {
                  return (
                    <span key={index} title={item.provider}>
                      {item.budgetLimit}
                      {index < summaryData.budgetLimit.length - 1 && " / "}
                    </span>
                  );
                })
              : 0}
          </p>
          <p>
            Custo Médio por Analise: ${" "}
            <span
              title={
                isNaN(summaryData?.totalMonthlyExpense) ||
                isNaN(summaryData?.totalAnalysis) ||
                summaryData?.totalAnalysis === 0
                  ? 0
                  : summaryData?.totalMonthlyExpense /
                    summaryData?.totalAnalysis
              }
            >
              {" "}
              {isNaN(summaryData?.totalMonthlyExpense) ||
              isNaN(summaryData?.totalAnalysis) ||
              summaryData?.totalAnalysis === 0
                ? 0
                : summaryData?.totalMonthlyExpense / summaryData?.totalAnalysis}
            </span>
          </p>
          <p>
            Custo Médio por Evento: ${" "}
            <span
              title={
                isNaN(summaryData?.totalMonthlyExpense) ||
                isNaN(summaryData?.totalEvents) ||
                summaryData?.totalEvents === 0
                  ? 0
                  : summaryData?.totalMonthlyExpense / summaryData?.totalEvents
              }
            >
              {isNaN(summaryData?.totalMonthlyExpense) ||
              isNaN(summaryData?.totalEvents) ||
              summaryData?.totalEvents === 0
                ? 0
                : summaryData?.totalMonthlyExpense / summaryData?.totalEvents}
            </span>
          </p>
          <p>Eventos Analisados: {summaryData?.totalEvents}</p>
          <p>Prompts Analisados: {summaryData?.totalAnalysis}</p>
        </CardBody>
      </Card>

      <Card className="mb-3">
        <CardBody>
          <CardTitle>Gasto Mensal Total</CardTitle>
          <p title={summaryData?.totalMonthlyExpense}>
            $ {summaryData?.totalMonthlyExpense.toFixed(2)}
          </p>
          <div className="progress-wrapper">
            <div className="progress-info">
              <div className="progress-label">
                <span>Análises</span>
              </div>
              <div className="progress-percentage">
                <span>
                  {summaryData?.totalMonthlyExpense &&
                  summaryData?.budgetLimit &&
                  summaryData?.budgetLimit > 0
                    ? (
                        (summaryData.totalMonthlyExpense /
                          summaryData.budgetLimit) *
                        100
                      ).toFixed(2)
                    : "0.00"}
                  %
                </span>
              </div>
            </div>
            <Progress
              max={summaryData?.budgetLimit}
              value={summaryData?.totalMonthlyExpense}
              color="default"
            />
          </div>
        </CardBody>
      </Card>
      <h2>Segmentos</h2>
      {summaryData?.segments
        .filter((segment) => segment.totalAnalyses > 0)
        .sort((a, b) => {
          if (a.monthlyExpense > b.monthlyExpense) {
            return -1;
          }
          if (a.monthlyExpense < b.monthlyExpense) {
            return 1;
          }
          return 0;
        })
        .map((segment, index) => (
          <Card className="mb-3" key={index}>
            <CardBody>
              <CardTitle>{segment?.name}</CardTitle>
              <p title={segment?.monthlyExpense}>
                Gasto Mensal: $ {segment?.monthlyExpense.toFixed(2)}
              </p>
              <div className="progress-wrapper">
                <div className="progress-info">
                  <div className="progress-label">
                    <span>Segmento</span>
                  </div>
                  <div className="progress-percentage">
                    <span>
                      {segment?.monthlyExpense &&
                      summaryData?.budgetLimit &&
                      summaryData?.budgetLimit > 0
                        ? (
                            (segment.monthlyExpense / summaryData.budgetLimit) *
                            100
                          ).toFixed(2)
                        : "0.00"}
                      %
                    </span>
                  </div>
                </div>
                <Progress
                  max={summaryData?.budgetLimit}
                  value={segment?.monthlyExpense}
                  color="default"
                />
              </div>
            </CardBody>
          </Card>
        ))}
    </Container>
  );
};

export default ClientSummary;
