import { toast } from "react-toastify";
import api from "service/api";

class ImageService {
  static getMimeType(base64String) {
    const mimeType = base64String.match(
      /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
    );
    return mimeType ? mimeType[1] : null;
  }

  static async uploadImage(base64Image, clientId) {
    const base64Data = base64Image.replace(/^data:image\/\w+;base64,/, "");
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    const mimeType = ImageService.getMimeType(base64Image);
    const blob = new Blob([byteArray], { type: mimeType });
    const formData = new FormData();
    formData.append("file", blob);

    return await api
      .post(`/upload?clientId=${clientId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-mime-type": mimeType,
        },
      })
      .then((response) => response.data.url)
      .catch((error) => {
        console.error("Image upload failed:", error);
        toast.error("Error uploading image.");
        return false;
      });
  }

  static async uploadAndReplaceImages(htmlString, clientId) {
    const container = document.createElement("div");
    container.innerHTML = htmlString;
    let id = 0;
    const imgTags = container.querySelectorAll('img[src^="data:image"]');

    for (const img of imgTags) {
      const base64Data = img.src;
      const uniqueId = `img-${id++}`;
      const newImg = document.createElement("img");
      newImg.id = uniqueId;
      newImg.src = "";
      img.parentNode.replaceChild(newImg, img);
      const newUrl = await ImageService.uploadImage(base64Data, clientId);

      if (!newUrl) {
        return false;
      }

      newImg.src = newUrl;
    }

    return container.innerHTML;
  }

  static verifyImageSize(htmlString) {
    const container = document.createElement("div");
    container.innerHTML = htmlString;
    const imgTags = container.querySelectorAll('img[src^="data:image"]');
    let imageSize = 0;
    for (const img of imgTags) {
      const base64Data = img.src;
      const padding =
        base64Data.charAt(base64Data.length - 2) === "="
          ? 2
          : base64Data.charAt(base64Data.length - 1) === "="
            ? 1
            : 0;
      imageSize += (base64Data.length * 3) / 4 - padding;
    }
    if (imageSize > 5 * 1024 * 1024) {
      return false;
    } else {
      return true;
    }
  }
}

export { ImageService };
