import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import { NavLink as NavLinkRRD } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  CardTitle,
  Col,
  CardBody,
  FormGroup,
  Badge,
} from "reactstrap";
import ModalRemoveReusableElement from "components/Modal/ModalRemoveReusableElement";

const ReusableElements = () => {
  const { register, handleSubmit, reset } = useForm();
  const [, setCollapseOpen] = useState();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [, setOnLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [, setDisabled] = useState(false);

  const permissions = localStorage.getItem("permissions");

  useEffect(() => {
    setOnLoad(true);
    api
      .get(`/reusable-elements?page=${offset}&limit=${Number(limit)}&${search}`)
      .then((response) => response.data)
      .then((response) => {
        setRange(response.totalPages);
        setTotalCount(response.totalCount);
        setInfo(response.reusableElements);
        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar elementos!");
        return;
      });
  }, [offset, search, limit]);

  const handleDelete = (id) => {
    setDisabled(true);
    api
      .delete(`/reusable-elements/${id}`)
      .then(() => {
        toast.success("Elemento deletado com sucesso!");
        const infoFilter = info.filter((item) => item.id !== id);
        setInfo(infoFilter);
        setDisabled(false);
      })
      .catch(() => {
        toast.error("Erro ao deletar elementos!");
        setDisabled(false);
      });
  };

  const onSubmit = (data) => {
    const { title } = data;
    let query = "";
    if (title) {
      query += `title=${title}&`;
    }
    setSearch(query);
  };

  const clearSearch = () => {
    let query = "";
    setSearch(query);
    reset();
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-2 pt-md-5 d-flex align-items-center justify-content-center flex-column ">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={5}>
                  <FormGroup>
                    <input
                      name="title"
                      id="title"
                      className="Input"
                      placeholder="Título"
                      style={{
                        display: "block",
                        width: "100%",
                        height: "50px",
                        padding: "0.625rem 0.75rem",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      {...register("title")}
                    />
                  </FormGroup>
                </Col>
                <Col lg={1} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Button
                      className="Button ml-2"
                      type="submit"
                      style={{ height: "50px" }}
                    >
                      Buscar
                    </Button>
                  </FormGroup>
                </Col>
                <Col lg={1} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Button
                      className="Button ml-2"
                      onClick={clearSearch}
                      style={{ height: "50px" }}
                    >
                      Limpar
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody className="pb-1">
                      <Row>
                        <div className="d-flex flex-row align-items-center">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 mr-2"
                          >
                            Elementos
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalCount}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody className="pb-1 ">
                      <Row>
                        <div className="d-flex flex-row align-items-center">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 mr-2"
                          >
                            Paginas
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {range}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <NavLink
                    to="/admin/reusable-element-form"
                    tag={NavLinkRRD}
                    activeClassName="active"
                  >
                    <Button
                      className="Button mb-4 mb-xl-0"
                      type="submit"
                      color="success"
                    >
                      <h4 className="text-white font-weight-bold mt-1 mb-1">
                        Cadastrar um novo elemento
                      </h4>
                    </Button>
                  </NavLink>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Título</th>
                    <th>Descrição</th>
                    <th>Conteúdo</th>
                    <th>Código</th>
                    <th>Prompts</th>
                    <th>Templates</th>
                    {permissions?.includes("update:reusable_element") && (
                      <th>Editar</th>
                    )}
                    {permissions?.includes("remove:reusable_element") && (
                      <th>Excluir</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>{item.title}</td>
                      <td>{item.description}</td>
                      <td title={item.content}>
                        {item.content.substring(0, 12)}
                      </td>

                      <td>
                        <CopyToClipboard
                          text={"@" + item.code}
                          onCopy={() =>
                            toast.success("Código copiado com sucesso!")
                          }
                          title="Clique para copiar o código"
                        >
                          <Button type="button" color="primary">
                            @{item.code}
                          </Button>
                        </CopyToClipboard>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {item.promptReusableElements.map((reu, index) => (
                            <Badge
                              key={index}
                              color="info"
                              pill
                              className="mr-1 mt-1"
                            >
                              {reu?.prompt?.title}
                              {index < item.promptReusableElements.length - 1
                                ? ", "
                                : ""}
                            </Badge>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {item.templateReusableElements.map((reu, index) => (
                            <Badge
                              key={index}
                              color="success"
                              pill
                              className="mr-1 mt-1"
                            >
                              {reu?.template?.title}
                              {index < item.templateReusableElements.length - 1
                                ? ", "
                                : ""}
                            </Badge>
                          ))}
                        </div>
                      </td>
                      {permissions?.includes("update:reusable_element") && (
                        <td>
                          <NavLink
                            to={`/admin/reusable-element-form?id=${item.id}`}
                            onClick={closeCollapse}
                            activeClassName="active"
                          >
                            Editar
                          </NavLink>
                        </td>
                      )}
                      {permissions?.includes("remove:reusable_element") && (
                        <td>
                          <ModalRemoveReusableElement
                            id={item.id}
                            icon={"fas fa-trash"}
                            templateElements={item.templateReusableElements}
                            promptElements={item.promptReusableElements}
                            handleDelete={() => {
                              handleDelete(item.id);
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>
  );
};

export default ReusableElements;
