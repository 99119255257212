import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, CardBody, Row, Col, FormGroup } from "reactstrap";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import api from "service/api";
import Loading from "components/Loading";

const ModalEditBudget = (props) => {
  const [open, setOpen] = useState(false);
  const [budget, setBudget] = useState(
    props?.clientConfig?.budgetLimit ? props?.clientConfig?.budgetLimit : 0,
  );
  const [budgetText, setBudgetText] = useState(
    props?.clientConfig?.budgetLimit
      ? String(props?.clientConfig?.budgetLimit)
      : "0",
  );
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    const providerId = props.id;
    api
      .patch(`/providers/budget-limit/${props.client.id}`, {
        budgetLimit: budget,
        providerId: providerId,
      })
      .then(() => {
        toast.success("Budget Limit atualizado com sucesso!");
        props.updateClientConfig({
          ...props.clientConfig,
          budgetLimit: budget,
        });
        props.setUpdated(!props.updated);
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        toast.error("Erro ao atualizar o Budget Limit");
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        color="primary"
        type="button"
        size="sm"
        onClick={() => setOpen(!open)}
      >
        Editar Budget Limit
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={() => setOpen(!open)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Editar Budget Limit - {props.name}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setOpen(!open)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pl-lg-4">
              <Row>
                <Col md="12" lg="8">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      Budget Limit(USD)
                    </label>
                    <CurrencyInput
                      style={{
                        display: "block",
                        width: "100%",
                        height: "calc(1.5em + 1.25rem + 2px)",
                        padding: "0.625rem 0.75rem",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      decimalsLimit={2}
                      prefix="$"
                      className="form-control-alternative"
                      id="budgetLimit"
                      decimalSeparator=","
                      groupSeparator="."
                      value={budgetText}
                      defaultValue={budgetText}
                      onValueChange={(value) => {
                        if (!value) {
                          setBudget(0);
                          setBudgetText("");
                        } else {
                          setBudgetText(value);
                          setBudget(Number(budgetText.replace(",", ".")));
                        }
                      }}
                      required
                      {...register("budgetLimit")}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer d-block">
              <Button type="submit" disabled={loading}>
                {loading ? (
                  <Loading
                    divStyle={{
                      width: "105px",
                    }}
                    loadingStyle={{
                      width: 30,
                      height: 20,
                    }}
                  />
                ) : (
                  "Atualizar"
                )}
              </Button>
            </div>
          </form>
        </CardBody>
      </Modal>
    </>
  );
};

export default ModalEditBudget;
