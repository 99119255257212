import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "service/api";
import { toast } from "react-toastify";
import ModalEditMetric from "components/Modal/ModalEditMetric";
import ModalConfirm from "../components/Modal/ModalConfirm";
import { Card, Table, Container, Row, Form, Button } from "reactstrap";

const Metrics = () => {
  const { register, handleSubmit, reset } = useForm();
  const [info, setInfo] = useState([]);
  const [, setOnLoad] = useState(false);
  const [update, setUpdate] = useState(false);
  const permissions = localStorage.getItem("permissions");

  useEffect(() => {
    api.get("/metrics").then((response) => {
      setInfo(response.data.metrics);
      setOnLoad(true);
    });
  }, [update]);

  const handleDelete = (id) => {
    api
      .delete(`/metrics/${id}`)
      .then(() => {
        toast.success("Métrica deletada com sucesso!");
        setUpdate(!update);
      })
      .catch(() => {
        toast.error("Erro ao deletar métrica!");
      });
  };

  const onSubmit = (data) => {
    data.customKey = data.customKey.toUpperCase().trim();
    api
      .post("/metrics", data)
      .then(() => {
        toast.success("Métrica adicionada com sucesso!");
        reset();
        setUpdate(!update);
      })
      .catch(() => {
        toast.error("Erro ao adicionar métrica!");
      });
  };

  const handleEdit = (id) => {
    api
      .put(`/metrics/${id}`)
      .then(() => {
        toast.success("Métrica editada com sucesso!");
        setUpdate(!update);
      })
      .catch(() => {
        toast.error("Erro ao editar métrica!");
      });
  };

  return (
    <>
      <Container className="mt-0" fluid>
        <div className="header bg-gradient-info pb-8 pt-2 d-flex align-items-center justify-content-center flex-column ">
          <h1 className="text-uppercase mb-0 mr-2 text-white bold mb-2">
            Métricas
          </h1>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Código</th>
                    {permissions?.includes("update:metric") && (
                      <th scope="col">Editar</th>
                    )}
                    {permissions?.includes("remove:metric") && (
                      <th scope="col">Excluir</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.type}</td>
                      <td>{item.customKey}</td>
                      {permissions?.includes("update:metric") && (
                        <td>
                          <ModalEditMetric
                            onSubmit={handleEdit}
                            name={item.name}
                            type={item.type}
                            id={item.id}
                            update={update}
                            setUpdate={setUpdate}
                          />
                        </td>
                      )}
                      {permissions?.includes("remove:metric") && (
                        <td>
                          <ModalConfirm
                            id={item.id}
                            modalText={`Esta ação irá excluir a métrica ${item.name}, você tem certeza?`}
                            handler={() => {
                              handleDelete(item.id);
                            }}
                            icon={"fas fa-trash"}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Metrics;
