import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import Login from "views/Login";

const Auth = () => {
  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  return (
    <div className="main-content">
      <div className="header bg-gradient-info py-7 py-lg-8">
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Switch>
            <Route path="/auth/login" component={Login} key={0} />
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </Row>
      </Container>
    </div>
  );
};

export default Auth;
