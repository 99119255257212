import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import Loading from "components/Loading";
import Select from "react-select";
import { DatePicker } from "components/DatePicker";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  Col,
  FormGroup,
} from "reactstrap";

const AnalysisProcesses = () => {
  const { register, handleSubmit, reset } = useForm();
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [onLoad, setOnLoad] = useState(false);
  const [limit, setLimit] = useState(100);
  const [date, setDate] = useState(moment(new Date()).startOf("day"));
  const [processes, setProcesses] = useState([]);
  const [segments, setSegments] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (!clients.length) {
      setOnLoad(true);
      api
        .get(`/clients?page=1&limit=10000`)
        .then((response) => response.data)
        .then((response) => {
          setClients(
            response.clients.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          );
          setOnLoad(false);
        })
        .catch(() => {
          toast.error("Erro ao recuperar clientes!");
          setOnLoad(false);
          return;
        });
    }
  }, []);

  useEffect(() => {
    if (selectedClient?.value) {
      api
        .get(`/segments?page=1&limit=10000&client=${selectedClient.value}`)
        .then((response) => response.data)
        .then((response) => {
          setSegments(
            response.segments.map((item) => ({
              label: item.title,
              value: item.id,
            })),
          );
        })
        .catch(() => {
          toast.error("Erro ao recuperar segmentos!");
          return;
        });
    }
  }, [selectedClient]);

  const onSubmit = (data) => {
    const { traceable } = data;
    let query = "";

    if (traceable) {
      query += `traceable=${traceable}&`;
    }

    if (selectedSegment?.value) {
      query += `segment=${selectedSegment.value}&`;
    }

    if (selectedClient?.value) {
      query += `client=${selectedClient.value}&`;
    }

    setQuery(query);

    handleGetProcesses(query);
  };

  const handleGetProcesses = (query, newLimit) => {
    if (date && typeof date.isValid !== "function") {
      return toast.error("Data invalida!");
    }

    if (!date) {
      return toast.error("Preencha a data inicial e final corretamente!");
    }

    const formatedDate = date.format("YYYY-MM-DDTHH:mm:ss");

    setProcesses([]);
    setOnLoad(true);
    api
      .get(
        `/analysis/processes?page=${offset}&limit=${Number(
          newLimit ?? limit,
        )}&createdAt=${formatedDate}&${query}`,
      )
      .then((response) => {
        if (response.status === 204) {
          toast.info("Não foram encontrados processamentos para esta busca!");
          setOnLoad(false);
          return;
        }
        setProcesses(response.data.processes ?? []);
        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Não foi possível recuperar os processamentos de análise!");
        setOnLoad(false);
        return;
      });
  };

  const clearSearch = () => {
    setDate(moment(new Date()).startOf("day"));
    setSelectedClient(null);
    setSelectedSegment(null);
    setQuery("");
    reset();
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    handleGetProcesses(query, event.target.value);
  };

  useEffect(() => {
    if (offset !== actualPage) {
      handleGetProcesses(query);
    }
  }, [offset]);

  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-2 pt-md-5 d-flex align-items-center justify-content-center flex-column ">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Cliente</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Escolha um cliente"
                      value={selectedClient}
                      onChange={setSelectedClient}
                      isClearable={true}
                      isSearchable={true}
                      options={clients}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Segmento</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={
                        selectedClient?.value ? "Selecione um segmento" : ""
                      }
                      value={selectedSegment}
                      onChange={setSelectedSegment}
                      isClearable={true}
                      isSearchable={true}
                      options={segments}
                      isDisabled={!selectedClient?.value}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Código</label>
                    <input
                      name="traceable"
                      id="traceable"
                      placeholder="Código de status do processamento"
                      className="Input custom-input"
                      {...register("traceable")}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Data</label>
                    <DatePicker
                      value={date}
                      onChange={setDate}
                      timeFormat={false}
                      dateFormat={"DD/MM/YYYY"}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={2} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Button
                      className="Button ml-2"
                      type="submit"
                      style={{ height: "50px", width: "100%" }}
                    >
                      Buscar
                    </Button>
                  </FormGroup>
                </Col>
                <Col lg={2} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Button
                      className="Button ml-2"
                      onClick={clearSearch}
                      style={{ height: "50px", width: "100%" }}
                    >
                      Limpar
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex flex-column ml-2 pl-2">
                <p className="m-0 pl-2"> Limite </p>
                <select
                  name="limit"
                  id="limit"
                  className="Input custom-select"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                {onLoad ? <Loading /> : ""}
                <thead className="thead-light">
                  <tr>
                    <th>Processamento</th>
                    <th>Processado por</th>
                    <th>Segmento</th>
                    <th>Cliente</th>
                    <th>Status</th>
                    <th>Registro</th>
                  </tr>
                </thead>
                <tbody>
                  {processes.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <NavLink
                          to={`/admin/analysis-processes/${item.id}`}
                          activeClassName="active"
                        >
                          {item.id}
                        </NavLink>
                      </td>
                      <td>{item?.username ?? "N/A"}</td>
                      <td>{item.segment ?? "N/A"}</td>
                      <td>{item.client ?? "N/A"}</td>
                      <td>
                        {item?.status?.length
                          ? item.status[item.status.length - 1].status
                          : "N/A"}
                      </td>
                      <td>
                        {new Date(item.started_at).toLocaleString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <p>Processamentos retornados: {processes.length}</p>
                </nav>
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AnalysisProcesses;
