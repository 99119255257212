import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import ModalConfirm from "../components/Modal/ModalConfirm";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardTitle,
  Col,
  CardBody,
  Badge,
} from "reactstrap";

const Clients = () => {
  const [, setCollapseOpen] = useState();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [, setOnLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search] = useState("");
  const [limit, setLimit] = useState(100);
  const [, setDisabled] = useState(false);
  const permissions = localStorage.getItem("permissions");

  useEffect(() => {
    setOnLoad(true);
    api
      .get(`/clients?page=${offset}&limit=${Number(limit)}&${search}`)
      .then((response) => response.data)
      .then((response) => {
        setRange(response.totalPages);
        setTotalCount(response.totalCount);
        const client = response.clients.map((item) => {
          const features = [];
          item.features.map((feature) => {
            if (feature === "chat") features.push("chat");
            if (feature === "chat_history")
              features.push("Historico de mensagens");
            if (feature === "content_edit") features.push("Ediçao de conteúdo");
            if (feature === "feedback") features.push("Feedback");
            if (feature === "favorites") features.push("Favoritos");
            if (feature === "top_favorites") features.push("Top Favoritos");
            if (feature === "notes") features.push("Notas");
            if (feature === "meu_sputi") features.push("Meu sputi");
          });
          return {
            id: item.id,
            name: item.name,
            assistants: item.assistants,
            users: item.users,
            ipGroups: item.clientIpGroups,
            features,
          };
        });
        setInfo(client);

        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar clientes!");
        return;
      });
  }, [offset, search, limit]);

  const handleDelete = (id) => {
    setDisabled(true);
    api
      .delete(`/clients/${id}`)
      .then(() => {
        toast.success("Cliente deletado com sucesso!");
        const infoFilter = info.filter((item) => item.id !== id);
        setInfo(infoFilter);
        setDisabled(false);
      })
      .catch(() => {
        toast.error("Erro ao deletar usuário!");
        setDisabled(false);
      });
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-0 pt-md-0 d-flex align-items-center justify-content-center flex-column">
          <div className="container-fluid"></div>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <div className="container-fluid mb-1">
          <Row>
            <Col lg="6" xl="3">
              <Card className="card-stats ">
                <CardBody className="pb-1">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h6"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Clientes
                      </CardTitle>
                      <span className="h2 mb-0">{totalCount}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0 ">
                <CardBody className="pb-1 ">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Paginas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{range}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Cliente</th>
                    <th>Grupos de ips</th>
                    <th>Funcionalidades</th>
                    {permissions?.includes("update:client") && <th>Editar</th>}
                    {permissions?.includes("remove:client") && <th>Excluir</th>}
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>
                        {item.name}
                        <NavLink
                          style={{
                            marginLeft: "10px",
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          to={`/admin/client-summary?id=${item.id}`}
                          title="Ver detalhes"
                        >
                          <i className="fas fa-eye" />
                        </NavLink>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "95px",
                          }}
                        >
                          {item.ipGroups?.map((item, index) => {
                            return (
                              <Badge
                                color="warning"
                                key={index}
                                style={{ marginTop: "5px" }}
                                onClick={(e) => e.preventDefault()}
                              >
                                {item.ipGroup.name}
                              </Badge>
                            );
                          })}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "95px",
                          }}
                        >
                          {item.features.map((feature, index) => (
                            <Badge
                              color="success"
                              key={index}
                              style={{ marginTop: "5px" }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {feature}
                            </Badge>
                          ))}
                        </div>
                      </td>
                      {permissions?.includes("update:client") && (
                        <td>
                          <NavLink
                            to={`/admin/clients-form?id=${item.id}`}
                            onClick={closeCollapse}
                            activeClassName="active"
                          >
                            Editar
                          </NavLink>
                        </td>
                      )}

                      {permissions?.includes("remove:client") && (
                        <td>
                          <ModalConfirm
                            id={item.id}
                            modalText={`Esta ação irá excluir o cliente ${item.name}, você tem certeza?`}
                            handler={() => {
                              handleDelete(item.id);
                            }}
                            disableButton={
                              item.assistants.length > 0 ? true : false
                            }
                            icon={"fas fa-trash"}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Clients;
