import "../assets/css/PromptForm.css";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import qs from "query-string";
import { toast } from "react-toastify";
import api from "service/api";
import { NavLink as NavLinkRRD } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  NavLink,
} from "reactstrap";

const ReusableElementForm = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    if (!data.title.trim()) {
      return toast.error("Informe o título do elemento.");
    }

    if (!data.description.trim()) {
      return toast.error("Informe uma descrição para este elemento.");
    }

    if (hasId) {
      api
        .patch(`/reusable-elements/${getParamsId()}`, {
          title: data.title.trim(),
          description: data.description.trim(),
          content: data.content.trim(),
        })
        .then(() => {
          toast.success("Elemento atualizado com sucesso!");
          setLoading(false);
        })
        .catch(() => {
          toast.error("Erro ao atualizar o elemento!");
          setLoading(false);
        });
    } else {
      api
        .post("/reusable-elements/", {
          title: data.title,
          description: data.description.trim(),
          content: data.content.trim(),
        })
        .then(() => {
          toast.success("Elemento cadastrado com sucesso!");
          setLoading(false);
          reset();
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response?.data?.message));
          setLoading(false);
        });
    }
  };

  function getParamsId() {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  }

  useEffect(() => {
    const id = getParamsId();
    if (id) {
      api
        .get(`/reusable-elements/${id}`)
        .then((response) => response.data)
        .then((response) => {
          setValue("title", response.title);
          setValue("description", response.description);
          setValue("content", response.content);
        })
        .catch((error) => {
          toast.error("Erro ao recuperar o elemento!");
          return;
        });
      setHasId(true);
    }
  }, []);

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="" xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Cadastrar elemento reutilizável</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Título
                      </label>
                      <input
                        style={{
                          display: "block",
                          width: "100%",
                          height: "calc(1.5em + 1.25rem + 2px)",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        className="form-control-alternative styled-input"
                        id="element-title"
                        placeholder="Título"
                        type="text"
                        {...register("title")}
                        autoComplete="off"
                        required
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="client">
                        Descrição
                      </label>
                      <input
                        style={{
                          display: "block",
                          width: "100%",
                          height: "calc(1.5em + 1.25rem + 2px)",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        className="form-control-alternative styled-input"
                        id="element-description"
                        placeholder="Descrição"
                        type="text"
                        {...register("description")}
                        autoComplete="off"
                        required
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <label className="form-control-label" htmlFor="content">
                      Conteúdo
                    </label>
                    <FormGroup>
                      <textarea
                        style={{
                          display: "block",
                          width: "100%",
                          height: "100px",
                          resize: "none",
                          padding: "0.625rem 0.75rem",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#8898aa",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #cad1d7",
                          borderRadius: "0.375rem",
                          boxShadow: "none",
                          fontStyle: "normal",
                          transition:
                            "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                        }}
                        className="form-control-alternative styled-scroll styled-input"
                        id="content"
                        type="text"
                        placeholder="Informe aqui o conteúdo principal deste elemento."
                        {...register("content")}
                        autoComplete="off"
                        required
                      />
                    </FormGroup>
                  </Col>
                </div>
                <hr className="my-4" />
                <div className=" d-flex flex-row">
                  <NavLink
                    to="/admin/reusable-elements"
                    tag={NavLinkRRD}
                    activeClassName="active"
                  >
                    <Button>Voltar</Button>
                  </NavLink>
                  <Button type="submit">Adicionar</Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default ReusableElementForm;
