import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "service/api";
import { NewEditor } from "components/Editor";
import Select from "react-select";
import { Button, CardBody, FormGroup, Modal, Row, Col } from "reactstrap";

const CustomInput = ({
  label,
  type,
  value,
  onChange,
  text,
  handleButton,
  multiFields,
  handleRemoveClick,
  key,
  hasId,
}) => {
  const inputProps = {
    value,
    onChange,
  };

  const [selectField, setSelectField] = useState([
    {
      label: "",
      value: "",
    },
  ]);

  const [multiFieldList, setMultiFieldList] = useState([]);

  useEffect(() => {
    let regexContent = new RegExp(
      "<" + "list-" + label + ">(.*?)<" + "list-" + label + ">",
    );
    if (type === "select") {
      let content = text.match(regexContent);
      if (content?.length)
        setSelectField(
          content[1].split(",").map((item) => ({ label: item, value: item })),
        );
    }
    if (type === "multi-field") {
      let content = text.match(regexContent);
      if (content?.length) {
        if (!hasId) {
          setMultiFieldList(content[1].split("|"));
        }
      }
    }
  }, [hasId, label, text, type]);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div key={key} className="mt-3">
      <label className="form-control-label">
        {capitalize(label.replace(/-/g, " "))}
      </label>
      {type === "textarea" ? (
        <textarea
          className="form-control-alternative styled-scroll styled-input input-text-area"
          id="rule"
          type="text"
          value={value}
          onChange={onChange}
          autoComplete="off"
        />
      ) : type === "multi-field" ? (
        <>
          <ul className="pt-1 pb-2">
            {multiFieldList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
            {multiFields[label] &&
              multiFields[label].map((item, index) => (
                <li key={index}>
                  {item}{" "}
                  <span className="btn-inner--icon">
                    <i
                      className="ni ni-fat-remove red"
                      onClick={(event) => handleRemoveClick(event, index)}
                      style={{
                        cursor: "pointer",
                        marginLeft: 5,
                        color: "red",
                      }}
                    ></i>
                  </span>
                </li>
              ))}
          </ul>
          <div className="d-flex ml-3">
            <input
              type="text"
              value={value}
              onChange={onChange}
              className="form-control-alternative styled-input custom-input"
              style={{ maxWidth: "80%" }}
            />
            <Button
              onClick={handleButton}
              style={{
                width: "20%",
                height: "100%",
                marginLeft: 5,
                marginTop: 0,
                paddingTop: 10,
                paddingBottom: 10,
              }}
              color="primary"
            >
              Adicionar
            </Button>
          </div>
        </>
      ) : type == "select" ? (
        <>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder=""
            value={value}
            onChange={onChange}
            isClearable={true}
            isSearchable={true}
            options={selectField}
          />
        </>
      ) : (
        <input
          className="form-control-alternative styled-input custom-input"
          id="question"
          type="text"
          value={value}
          onChange={onChange}
          autoComplete="off"
        />
      )}
    </div>
  );
};

export default CustomInput;
