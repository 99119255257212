import { Button, FormGroup, Modal, Row, Col, CardHeader } from "reactstrap";
import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { NewEditor } from "components/Editor";

const ModalEditRules = (props) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [formData, setFormData] = useState([]);
  const [ruleName, setRuleName] = useState("");
  const [textContent, setTextContent] = useState("");
  const [initialContent, setInitialContent] = useState("");
  const [isClear, setIsClear] = useState(false);
  const [formCount, setFormCount] = useState(1);
  const [conditionalIndex, setConditionalIndex] = useState([]);
  const [removeButtonIndex, setRemoveButtonIndex] = useState([]);
  const [metrics, setMetrics] = useState([{}]);

  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        setIsClear(false);
        setConditionalIndex([]);
        setFormCount(1);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  function handleRuleName(event) {
    event.preventDefault();
    setRuleName(event.target.value);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const index = name.split(".")[1] || "message";
    const field = name.split(".")[0];
    formData[index] = {
      ...formData[index],
      [field]:
        field === "condition"
          ? value.toUpperCase().split(" ").join("_")
          : value,
    };
    setFormData(formData);
  };

  const onSubmit = (data) => {
    const ruleDto = {
      customMessage: textContent,
      name: ruleName,
      metricIds: data.metricIds,
    };
    if (isClear) ruleDto.rule = data.rule;
    api
      .put(`/rules/${props.ruleId}`, ruleDto)
      .then(() => {
        toast.success("Regra adicionada com sucesso!");
        toggleModal("defaultModal");
        props.setUpdate(!props.update);
      })
      .catch((error) => {
        console.error(error.response.data.message);
        toast.error("Erro ao adicionar regra!");
      });
  };

  useEffect(() => {
    if (props.ruleId) {
      api
        .get(`/rules/${props.ruleId}`)
        .then((response) => {
          const getRules = response.data;
          setRuleName(getRules.name);
          setInitialContent(getRules.customMessage);
        })
        .catch((error) => {
          toast.error("Erro ao carregar regra!");
        });
    }
  }, [props.update]);

  useEffect(() => {
    api
      .get("/metrics")
      .then((response) => {
        setMetrics(response.data.metrics);
      })
      .catch((error) => {
        console.error(error.response?.data?.message);
        toast.error("Erro ao carregar as metricas");
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    let outputArray = [];
    const metricIds = [];
    formData.map((item) => {
      if (item.metric) {
        metricIds.push(item.metric);
      }
      return item;
    });

    for (let i = 0; i < formData.length; i++) {
      const item = formData[i];

      if (i === 0) {
        outputArray.push([
          { metricId: item.metric, type: item.condition, value: item.value },
        ]);
      } else {
        const previousItem = formData[i - 1];
        const previousCondition = previousItem.conditional;

        if (previousCondition === "OR") {
          outputArray.push([
            { metricId: item.metric, type: item.condition, value: item.value },
          ]);
        } else if (previousCondition === "AND") {
          outputArray[outputArray.length - 1].push({
            metricId: item.metric,
            type: item.condition,
            value: item.value,
          });
        }
      }
    }

    const ruleDTO = {
      metricIds: metricIds,
      rule: outputArray,
    };
    onSubmit(ruleDTO);
  };

  const handleConditional = (index) => {
    conditionalIndex.push(index);
    if (conditionalIndex.includes(index - 1)) {
      setRemoveButtonIndex([...removeButtonIndex, index - 1]);
    }
    setConditionalIndex(conditionalIndex);
    setFormCount(formCount + 1);
    if (Array.isArray(formData)) {
      setFormData([...formData, ""]);
    } else {
      setFormData([""]);
    }
  };

  const handleRemoveConditional = (index) => {
    conditionalIndex.splice(index, 1);
    setConditionalIndex(conditionalIndex);
    setRemoveButtonIndex(
      removeButtonIndex.filter((item) => item !== index - 1),
    );
    setFormCount(formCount - 1);
    if (Array.isArray(formData)) {
      setFormData([...formData, ""]);
    } else {
      setFormData([""]);
    }
  };

  const handleClearRule = () => {
    setIsClear(true);
  };
  return (
    <>
      <Row>
        <Col md="4">
          <Button
            block
            color="primary"
            type="button"
            className="ml-3 mb-2"
            style={{ width: "auto" }}
            onClick={() => toggleModal("defaultModal")}
          >
            Editar
          </Button>
          <Modal
            className="modal-dialog-centered modal-lg"
            isOpen={defaultModal}
            toggle={() => toggleModal("defaultModal")}
          >
            <div className="modal-header ">
              <CardHeader className="bg-white border-0 p-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Editar Mensagem</h3>
                  </Col>
                </Row>
              </CardHeader>
              <button
                aria-label="Close"
                className="close pb-0"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal("defaultModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="pl-lg-4">
                  <Row>
                    <FormGroup className="d-flex">
                      <label className="form-control-label p-2" htmlFor="name">
                        Nome:
                      </label>
                      <input
                        className="form-control-alternative custom-input"
                        id="name"
                        placeholder="Nome da regra"
                        type="text"
                        name="name"
                        onChange={handleRuleName}
                        value={ruleName}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Row>
                  <hr className="my-3 pb-3" />
                  <Row className="justify-content-center">
                    {isClear &&
                      Array.from({ length: formCount }).map((_, index) => {
                        return (
                          <div
                            key={index}
                            className="transition-ease transition-duration-2s"
                          >
                            <div className="d-flex">
                              <FormGroup className="d-flex mr-2">
                                <select
                                  name={`metric.${index}`}
                                  id="metric1"
                                  className="form-control-alternative custom-input"
                                  required
                                  value={formData[`metric.${index}`]}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Métrica</option>
                                  {metrics.map((item, index) => {
                                    return (
                                      <option value={item.id} key={index}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </FormGroup>
                              <FormGroup className="d-flex mr-2">
                                <select
                                  name={`condition.${index}`}
                                  id="condition"
                                  placeholder="Condição"
                                  className="form-control-alternative custom-input"
                                  required
                                  onChange={handleInputChange}
                                >
                                  <option value="">Condição</option>
                                  <option value="Greater than or Equal">
                                    Maior ou Igual
                                  </option>
                                  <option value="Less than or Equal">
                                    Menor ou Igual
                                  </option>
                                  <option value="Greater than">
                                    Maior que
                                  </option>
                                  <option value="Less than">Menor que</option>
                                  <option value="Equal to">Igual a</option>
                                  <option value="Not Equal to">
                                    Diferente de
                                  </option>
                                  <option value="Null">Nulo ou Vazio</option>
                                </select>
                              </FormGroup>
                              <FormGroup className="d-flex mr-2">
                                <input
                                  className="form-control-alternative custom-input ml-2"
                                  id="value"
                                  placeholder="Valor"
                                  type="text"
                                  name={`value.${index}`}
                                  onChange={handleInputChange}
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </div>
                            <FormGroup className="d-flex justify-content-center">
                              {conditionalIndex.includes(index) && (
                                <div className="d-flex justify-content-center ">
                                  <select
                                    className="custom-input"
                                    name={`conditional.${index}`}
                                    value={formData[`conditional.${index}`]}
                                    onChange={handleInputChange}
                                  >
                                    <option value=""></option>
                                    <option value="AND">E</option>
                                    <option value="OR">OU</option>
                                  </select>
                                  {!removeButtonIndex.includes(index) && (
                                    <Button
                                      color="danger"
                                      className="ml-2 remove-button"
                                      onClick={() =>
                                        handleRemoveConditional(index)
                                      }
                                    >
                                      -
                                    </Button>
                                  )}
                                </div>
                              )}
                            </FormGroup>
                            <div className="d-flex justify-content-center condition-btn">
                              {!conditionalIndex.includes(index) && (
                                <Button
                                  color="default"
                                  className="add-button"
                                  onClick={() => handleConditional(index)}
                                >
                                  {" "}
                                  + Adicionar Condicional
                                </Button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    {!isClear && (
                      <FormGroup className="d-flex w-100 justify-content-around">
                        <label
                          className="form-control-label p-2"
                          htmlFor="name"
                        >
                          Regra:
                        </label>
                        <div className="pl-3 align-self-center text-12">
                          {props.ruleView}
                        </div>
                        <Button onClick={handleClearRule}>Alterar Regra</Button>
                      </FormGroup>
                    )}
                  </Row>
                  <hr className="my-3 pb-3" />
                  <Row>
                    <FormGroup>
                      <NewEditor
                        initialContent={initialContent}
                        contentValue={textContent}
                        setContentValue={setTextContent}
                      />
                    </FormGroup>
                  </Row>
                  <hr className="my-4" />
                </div>
                <Button type="submit">Atualizar</Button>
              </form>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default ModalEditRules;
