import { useEffect, useState } from "react";
import { Button, Modal, Row, Col } from "reactstrap";

const ModalOriginalContent = (props) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [, setContent] = useState("");
  const [, setOriginalContent] = useState("");

  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setContent(props.content.replace(/(<([^>]+)>)/gi, ""));
    setOriginalContent(props.originalContent.replace(/(<([^>]+)>)/gi, ""));
  }, [props.content, props.originalContent]);

  return (
    <Row>
      <Col md="4">
        <Button
          block
          className="mb-3"
          color="default"
          type="button"
          style={{ width: "auto" }}
          onClick={() => toggleModal("defaultModal")}
        >
          Visualizar conteúdo.
        </Button>
        <Modal
          className="modal-dialog-centered"
          isOpen={defaultModal}
          toggle={() => toggleModal("defaultModal")}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div
            className="modal-body fav-content"
            dangerouslySetInnerHTML={{ __html: props.content }}
          ></div>
        </Modal>
      </Col>
    </Row>
  );
};

export default ModalOriginalContent;
