import { Modal, Button, FormGroup, Alert } from "reactstrap";

const Modals = ({ toggle, setToggle }) => {
  return (
    <Modal
      className="modal-dialog modal-md"
      isOpen={toggle}
      toggle={() => setToggle((v) => !v)}
    >
      <div className="modal-header">
        <div className="modal-title">
          <h3>Estimativa de custo</h3>
        </div>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setToggle((v) => !v)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <form>
          <div className="pl-lg-2">
            <FormGroup>
              <Alert color="danger">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>{" "}
                Tempo para estimar o custo atingido!
              </Alert>
              <label className="form-control-label">
                Devido a quantidade de atendimentos, o tempo para estimar o
                custo foi extrapolado e não será possivel finalizar a
                estimativa.
              </label>
              <hr className="my-4" />
              <label
                className="form-control-label"
                style={{ fontSize: "14px" }}
              >
                <b>
                  A opção para iniciar as análises está disponível para ser
                  usada.
                </b>
              </label>
            </FormGroup>
          </div>
          <hr className="my-4" />
          <Button
            type="button"
            color="primary"
            onClick={() => setToggle((v) => !v)}
          >
            Entendido
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default Modals;
