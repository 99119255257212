import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import qs from "query-string";
import { toast } from "react-toastify";
import api from "service/api";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const SegmentForm = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);

  const [, setSegment] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState("");
  const [providers, setProviders] = useState([]);
  const [providerId, setProviderId] = useState("");
  const [segmentClient, setSegmentClient] = useState(null);
  const [webhook, setWebhook] = useState(null);
  const [sendStatusToWebhook, setSendStatusToWebhook] = useState(null);
  const [enableSelectProvider, setEnableSelectProvider] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);

    if (!data.name) {
      return toast.error("Informe o nome do segmento");
    }

    if (!clientId) {
      return toast.error("Informe o cliente");
    }
    const defaultProvider = providers.find((item) => item.isDefault === true);
    let provider = providerId;
    if (defaultProvider && providerId === defaultProvider.provider.id) {
      provider = "";
    }
    if (hasId) {
      api
        .patch(`/segments/${getParamsId()}`, {
          title: data.name,
          client_id: clientId,
          provider_id: provider,
          webhook: data.webhook,
          send_status_to_webhook: data.send_status_to_webhook,
          minMessageCount: data.minMessageCount || 5,
        })
        .then(() => {
          toast.success("Segmento atualizado com sucesso!");
          setLoading(false);
        })
        .catch(() => {
          toast.error("Erro ao atualizar o segmento!");
          setLoading(false);
        });
    } else {
      api
        .post("/segments", {
          title: data.name,
          client_id: clientId,
          provider_id: provider,
          webhook: data.webhook,
          send_status_to_webhook: data.send_status_to_webhook,
          minMessageCount: data.minMessageCount || 5,
        })
        .then(() => {
          toast.success("Segmento cadastrado com sucesso!");
          setLoading(false);
          reset();
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response?.data?.message));
          setLoading(false);
        });
    }
  };

  function getParamsId() {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  }

  function getClientProviderConfiguration() {
    api.get("/providers/configuration/" + clientId).then((response) => {
      setProviders(
        response.data.map((config) => {
          return {
            id: config.provider.id,
            name: config.provider.name,
          };
        }),
      );
      const defaultProvider = response.data.find(
        (item) => item.isDefault === true,
      );

      if (defaultProvider && !hasId) {
        setProviderId(defaultProvider.provider.id);
      }
    });
  }

  useEffect(() => {
    if (clientId) {
      getClientProviderConfiguration();
    }
  }, [clientId]);

  useEffect(() => {
    if (providers) {
      setEnableSelectProvider(!providers.length > 1);
    }
  }, [providers]);

  useEffect(() => {
    api
      .get(`/clients?page=1&limit=10000`)
      .then((response) => {
        setClients(response.data.clients);
      })
      .catch(() => {
        toast.error("Erro ao recuperar os clientes!");
        return;
      });

    const id = getParamsId();
    if (id) {
      api
        .get(`/segments/${id}`)
        .then((response) => {
          setSegment(response.data);
          setValue("name", response.data.title);
          setClientId(response.data.client.id);
          setSegmentClient(response.data.client);
          setProviderId(response.data.provider?.id);
          setValue("webhook", response.data.webhook);
          setSendStatusToWebhook(response.data.send_status_to_webhook);
          setValue("minMessageCount", response.data.minMessageCount);
        })
        .catch((err) => {
          toast.error("Erro ao recuperar o segmento!");
          return;
        });
      setHasId(true);
    }
  }, []);

  useEffect(() => {
    setHasId(!!getParamsId());
  });

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="" xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">
                    {hasId ? "Editar Segmento" : "Cadastrar Segmento"}
                  </h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="name">
                          Nome
                        </label>
                        <input
                          style={{
                            display: "block",
                            width: "100%",
                            height: "calc(1.5em + 1.25rem + 2px)",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                            transition:
                              "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                          }}
                          className="form-control-alternative"
                          id="segment-name"
                          placeholder="Nome"
                          required={true}
                          type="text"
                          {...register("name")}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="name">
                          Quantidade minima de mensagens
                        </label>
                        <select
                          className="form-control-alternative custom-input"
                          id="segment-min-message-count"
                          {...register("minMessageCount")}
                        >
                          <option value="">
                            Selecione a quantidade mínima de mensagens
                          </option>
                          <option value="1">1</option>
                          <option value="3">3</option>
                          <option value="5">5</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="client">
                          Cliente
                        </label>
                        {hasId ? (
                          <input
                            className="form-control-alternative custom-input"
                            disabled={true}
                            value={
                              clients?.find((client) => client.id === clientId)
                                ?.name
                            }
                          />
                        ) : (
                          <select
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              color: "#8898aa",
                              backgroundColor: "#fff",
                              backgroundClip: "padding-box",
                              border: "1px solid #cad1d7",
                              borderRadius: "0.375rem",
                              boxShadow: "none",
                              transition:
                                "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                            }}
                            value={clientId}
                            className="form-control-alternative"
                            id="client"
                            type="text"
                            required={true}
                            {...register("client")}
                            autoComplete="off"
                            onChange={(e) => {
                              const client = clients.find(
                                (client) => client.id == e.target.value,
                              );

                              if (!client) {
                                setClientId("");
                              } else {
                                setClientId(e.target.value);
                              }
                            }}
                          >
                            <option key={0}>Escolha um cliente</option>
                            {clients.map((client) => {
                              return (
                                <option key={client.id} value={client.id}>
                                  {client.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="client">
                          Provedor
                        </label>
                        {!enableSelectProvider ? (
                          <select
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              color: "#8898aa",
                              backgroundColor: "#fff",
                              backgroundClip: "padding-box",
                              border: "1px solid #cad1d7",
                              borderRadius: "0.375rem",
                              boxShadow: "none",
                              transition:
                                "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                            }}
                            value={providerId}
                            className="form-control-alternative"
                            id="providerId"
                            type="text"
                            {...register("providerId")}
                            autoComplete="off"
                            onChange={(e) => {
                              const provider = providers.find(
                                (provider) => provider.id == e.target.value,
                              );

                              if (!provider) {
                                setProviderId("");
                              } else {
                                setProviderId(e.target.value);
                              }
                            }}
                          >
                            {providers?.map((provider) => {
                              return (
                                <option key={provider.id} value={provider.id}>
                                  {provider.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <input
                            className="form-control-alternative custom-input"
                            disabled={true}
                            value={
                              providers?.find(
                                (provider) => provider.id === providerId,
                              )?.name
                            }
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="client-webhook"
                        >
                          Webhook padrão
                        </label>
                        <input
                          style={{
                            display: "block",
                            width: "100%",
                            height: "calc(1.5em + 1.25rem + 2px)",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                            transition:
                              "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                          }}
                          className="form-control-alternative"
                          id="client-webhook"
                          placeholder="N/A"
                          required={true}
                          disabled={true}
                          value={segmentClient?.webhook}
                          type="text"
                          {...register("client-webhook")}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="segment-webhook"
                        >
                          Webhook opcional ( customizado para o segmento )
                        </label>
                        <input
                          style={{
                            display: "block",
                            width: "100%",
                            height: "calc(1.5em + 1.25rem + 2px)",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                            transition:
                              "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                          }}
                          className="form-control-alternative"
                          id="segment-webhook"
                          placeholder="Webhook"
                          type="text"
                          {...register("webhook")}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup className="mb-1">
                        <input
                          type="checkbox"
                          id="send_status_to_webhook"
                          checked={sendStatusToWebhook}
                          {...register("send_status_to_webhook")}
                          onChange={(e) => {
                            setSendStatusToWebhook(e.target.checked);
                          }}
                        />
                        <label
                          className="form-control-label ml-2"
                          htmlFor="send_status_to_webhook"
                        >
                          Habilitar envio de status para o webhook
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <Button type="submit">
                  {hasId ? "Atualizar" : "Adicionar"}
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default SegmentForm;
