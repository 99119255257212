import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import Loading from "components/Loading";
import Select from "react-select";
import qualityApi from "service/qualityApi";
import { DatePicker } from "components/DatePicker";
import { DateTool } from "utils/DateTool";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  Col,
  FormGroup,
} from "reactstrap";
import { TextTool } from "utils/TextTool";

const QualityAttendances = () => {
  const { register, handleSubmit, reset } = useForm();
  const [info, setInfo] = useState([]);
  const { setActualPage, actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [onLoad, setOnLoad] = useState(false);
  const [limit, setLimit] = useState(100);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState([]);
  const [date, setDate] = useState(moment(new Date()).startOf("day"));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf("day"));
  const [processId, setProcessId] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (!clients.length) {
      setOnLoad(true);
      api
        .get(`/clients?page=1&limit=10000`)
        .then((response) => response.data)
        .then((response) => {
          setClients(
            response.clients.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          );
          setOnLoad(false);
        })
        .catch(() => {
          toast.error("Erro ao recuperar clientes!");
          setOnLoad(false);
          return;
        });
    }
  }, []);

  useEffect(() => {
    if (selectedClient?.value) {
      setOnLoad(true);
      api
        .get(`/segments?page=1&limit=10000&client=${selectedClient.value}`)
        .then((response) => response.data)
        .then((response) => {
          setSegments(
            response.segments.map((item) => ({
              label: item.title,
              value: item.id,
            })),
          );
          setOnLoad(false);
        })
        .catch(() => {
          toast.error("Erro ao recuperar segmentos!");
          setOnLoad(false);
          return;
        });
    }
  }, [selectedClient]);

  const onSubmit = (data) => {
    if (date && typeof date.isValid !== "function") {
      return toast.error("Data invalida!");
    }

    if (!date || !endDate) {
      return toast.error("Preencha a data inicial e final corretamente!");
    }

    const formattedDate = date.format("YYYY-MM-DDTHH:mm:ss");
    const formattedEndDate = endDate.format("YYYY-MM-DDTHH:mm:ss");

    const range = DateTool.calculateMonthsBetween(
      formattedDate,
      formattedEndDate,
    );

    if (range < 0) {
      return toast.info("A data final não pode ser menor que a data inicial!");
    }

    if (range > 1) {
      return toast.info("O período de busca não pode ser superior a 1 mês!");
    }

    const {
      attendance,
      processId,
      consultantExternalId,
      supervisorExternalId,
    } = data;

    let query = "";

    if (selectedClient?.value && selectedSegment?.value) {
      query += `clientId=${selectedClient.value}&`;
      query += `segmentId=${selectedSegment.value}&`;
    }

    if (attendance) {
      query += `attendance=${attendance}&`;
    }

    if (processId) {
      query += `processId=${processId}&`;
    }

    if (consultantExternalId) {
      query += `consultantExternalId=${consultantExternalId}&`;
    }
    if (supervisorExternalId) {
      query += `supervisorExternalId=${supervisorExternalId}&`;
    }

    if (!selectedSegment?.value && !attendance && !processId) {
      return toast.info(
        "Deve ser selecionado ao menos um dos seguintes parâmetros: Segmento, Atendimento ou ID do processamento",
      );
    }

    setQuery(query);

    handleGetAttendances(query);
  };

  const handleGetAttendances = (query, newLimit) => {
    if (date && typeof date.isValid !== "function") {
      return toast.error("Data invalida!");
    }

    if (!date || !endDate) {
      return toast.error("Preencha a data inicial e final corretamente!");
    }

    const formattedDate = date.format("YYYY-MM-DDTHH:mm:ss");
    const formattedEndDate = endDate.format("YYYY-MM-DDTHH:mm:ss");

    const startTime = moment();
    setInfo([]);
    setOnLoad(true);
    qualityApi
      .get(
        `/attendances?page=${offset}&limit=${Number(
          newLimit ?? limit,
        )}&startDate=${formattedDate}&endDate=${formattedEndDate}&${query}`,
      )
      .then((response) => {
        const endTime = moment();
        const requestDurationMs = endTime.diff(startTime);

        if (response.status === 204) {
          toast.info("Não foram encontrados atendimentos para esta busca!");
          setOnLoad(false);
          return;
        }

        if (response.data.attendances.length === 1) {
          toast.info(
            `Foi retornado ${
              response.data.attendances.length
            } atendimento em ${DateTool.requestDurationFormat(
              requestDurationMs,
            )}!`,
          );
        } else {
          toast.info(
            `Foram retornados ${
              response.data.attendances.length
            } atendimentos em ${DateTool.requestDurationFormat(
              requestDurationMs,
            )}!`,
          );
        }

        setInfo(response.data.attendances ?? []);
        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar os atendimentos!");
        setOnLoad(false);
        return;
      });
  };

  const clearSearch = () => {
    setDate(moment(new Date()).startOf("day"));
    setEndDate(moment(new Date()).endOf("day"));
    setSelectedSegment([]);
    reset();
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    handleGetAttendances(query, event.target.value);
  };

  useEffect(() => {
    setActualPage(offset);
    if (offset !== actualPage) {
      handleGetAttendances(query);
    }
  }, [offset]);

  const validProcessId = (processId) => {
    if (TextTool.validateUUID(processId)) {
      setProcessId(processId.trim());
    } else {
      setProcessId(null);
    }
  };

  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-2 pt-md-5 d-flex align-items-center justify-content-center flex-column ">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={2}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Cliente</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Selecione um cliente"
                      value={selectedClient}
                      onChange={setSelectedClient}
                      isClearable={true}
                      isSearchable={true}
                      options={clients}
                    />
                  </FormGroup>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Segmento</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={
                        selectedClient?.value ? "Selecione um segmento" : ""
                      }
                      value={selectedSegment}
                      onChange={setSelectedSegment}
                      isClearable={true}
                      isSearchable={true}
                      options={segments}
                      isDisabled={!selectedClient?.value}
                    />
                  </FormGroup>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Atendimento</label>
                    <input
                      name="attendance"
                      id="code"
                      className="Input custom-input"
                      {...register("attendance")}
                    />
                  </FormGroup>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">
                      ID de Processamento
                    </label>
                    <input
                      name="processId"
                      id="code"
                      className="Input custom-input"
                      {...register("processId", {
                        onChange: (evt) => validProcessId(evt.target.value),
                      })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={2}>
                  <label className="text-white mr-2 ml-2">Data Inicial</label>
                  <DatePicker
                    value={date}
                    onChange={setDate}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                  />
                </Col>
                <Col lg={2}>
                  <label className="text-white mr-2 ml-2">Data Final</label>
                  <DatePicker
                    value={endDate}
                    onChange={setEndDate}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                  />
                </Col>
                {(selectedSegment?.value || processId) && (
                  <>
                    <Col lg={2}>
                      <FormGroup>
                        <label className="text-white mr-2 ml-2">
                          Login de rede (consultor)
                        </label>
                        <input
                          name="consultantExternalId"
                          id="code"
                          className="Input custom-input"
                          {...register("consultantExternalId")}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={2}>
                      <FormGroup>
                        <label className="text-white mr-2 ml-2">
                          Login de rede (supervisor)
                        </label>
                        <input
                          name="supervisorExternalId"
                          id="code"
                          className="Input custom-input"
                          {...register("supervisorExternalId")}
                        />
                      </FormGroup>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col lg={2} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Card>
                      <Button
                        className="Button"
                        disabled={onLoad}
                        type="submit"
                        style={{ height: "40px" }}
                      >
                        Buscar
                      </Button>
                    </Card>
                  </FormGroup>
                </Col>
                <Col lg={2} className="justify-content-end d-flex flex-column ">
                  <FormGroup>
                    <Card>
                      <Button
                        className="Button"
                        disabled={onLoad}
                        onClick={clearSearch}
                        style={{ height: "40px" }}
                      >
                        Limpar
                      </Button>
                    </Card>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex flex-column ml-2 pl-2">
                <p className="m-0 pl-2"> Limite </p>
                <select
                  name="limit"
                  id="limit"
                  className="Input custom-select"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                {onLoad ? <Loading /> : ""}
                <thead className="thead-light">
                  <tr>
                    <th>Atendimento</th>
                    <th>Processado por</th>
                    <th>Segmento</th>
                    <th>Registro</th>
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <NavLink
                          to={`/admin/quality-attendance/${item.id}`}
                          activeClassName="active"
                        >
                          {item.attendance}
                        </NavLink>
                      </td>
                      <td>{item?.user?.username ?? "N/A"}</td>
                      <td>{item.segment?.title ?? item.segmentId}</td>
                      <td>
                        {new Date(item.createdAt).toLocaleString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  {info.length ? (
                    <p>Atendimentos retornados: {info.length}</p>
                  ) : (
                    <p>
                      Selecione ao menos um dos seguintes filtros para iniciar a
                      sua busca:
                      <br />
                      cliente e segmento, ID do atendimento, ID do processamento
                      <br />
                      OBS: Período máximo de busca: 1 MÊS
                    </p>
                  )}
                </nav>
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    offset={offset}
                    setOffset={setOffset}
                    maxReached={info.length < limit}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default QualityAttendances;
