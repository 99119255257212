import moment from "moment";

export class DateTool {
  static calculateMonthsBetween(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, "months", true);
  }

  static requestDurationFormat(requestDurationMs) {
    let formattedDuration = "";
    const seconds = Math.floor(requestDurationMs / 1000);
    formattedDuration += seconds > 0 ? `${seconds}seg, e ` : "";
    formattedDuration += `${requestDurationMs % 1000}ms`;
    return formattedDuration;
  }
}
