import { useEffect, useState } from "react";
import { Button, Modal, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import api from "service/api";

const ModalPreViewPrompt = ({
  prompt,
  promptData,
  promptId,
  selectedElements,
  availableElements,
}) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [formattedPrompt, setFormattedPrompt] = useState(prompt);

  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };
  const onSubmit = () => {
    if (!promptData.segment_id) {
      return toast.error("Informe o segmento");
    }
    if (!promptData.prompt) {
      return toast.error("Informe o prompt");
    }
    if (!promptData.template_id) {
      return toast.error("Informe o template");
    }
    if (!promptData.title) {
      return toast.error("Informe o título");
    }

    if (selectedElements?.length) {
      promptData.reusable_elements_id = selectedElements.map((item) => item.id);
    }

    if (promptId) {
      return api
        .patch("/prompts/" + promptId, promptData)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Prompt atualizado com sucesso");
            toggleModal("defaultModal");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Erro ao atualizar prompt");
        });
    }

    api
      .post("/prompts", promptData)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Prompt adicionado com sucesso");
          toggleModal("defaultModal");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao adicionar prompt");
      });
  };

  useEffect(() => {
    const templateElements = availableElements.filter((element) =>
      element.templateReusableElements?.some(
        (templateElement) =>
          templateElement.template?.id === promptData.template_id,
      ),
    );
    const promptElements = availableElements.filter((availableElement) =>
      selectedElements?.some(
        (selectedElement) => selectedElement.id === availableElement.id,
      ),
    );

    let formattedPromptWithElements = promptElements.reduce((acc, element) => {
      const regex = new RegExp(`@${element.code}`, "g");
      return acc.replace(regex, element.content);
    }, prompt);

    formattedPromptWithElements = templateElements.reduce((acc, element) => {
      const regex = new RegExp(`@${element.code}`, "g");
      return acc.replace(regex, element.content);
    }, formattedPromptWithElements);

    setFormattedPrompt(formattedPromptWithElements);
  }, [selectedElements, promptData, promptId, prompt, availableElements]);

  return (
    <Row>
      <Col md="4">
        <Button
          block
          className="mb-3"
          type="button"
          style={{ width: "auto" }}
          onClick={() => toggleModal("defaultModal")}
        >
          Visualizar Prompt
        </Button>
        <Modal
          className="modal-dialog-centered"
          isOpen={defaultModal}
          toggle={() => toggleModal("defaultModal")}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <textarea
              className="styled-scroll input-text-area"
              style={{
                height: "600px",
                width: "100%",
                fontSize: "0.875rem",
              }}
              disabled
              value={formattedPrompt}
            ></textarea>
            <hr className="my-4" />
            <Button type="submit" color="primary" onClick={onSubmit}>
              Adicionar Prompt
            </Button>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default ModalPreViewPrompt;
