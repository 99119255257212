import React, { useEffect, useState } from "react";

const LifecycleStage = ({ currentStage, stages, origins }) => {
  const [lifecycleStage, setLifecycleStage] = useState("");
  const [messages, setMessages] = useState([]);

  const lifecycleStages = {
    INITIAL_DISPUTE: "Contestação",
    INITIAL_DECISION: "Decisão Inicial",
    FIRST_REPLY: "Réplica",
    FIRST_REJOINDER: "Tréplica",
    SECOND_REPLY: "Segunda Réplica",
    FINAL_DECISION: "Decisão Final",
  };

  const originMap = {
    dispute: "INITIAL_DISPUTE",
    initial_decision: "INITIAL_DECISION",
    reply: "FIRST_REPLY",
    reply_response: "FIRST_REJOINDER",
    second_reply: "SECOND_REPLY",
    final_decision: "FINAL_DECISION",
  };

  const iconMap = {
    INITIAL_DISPUTE: "fas fa-gavel",
    INITIAL_DECISION: "fas fa-balance-scale",
    FIRST_REPLY: "fas fa-reply",
    FIRST_REJOINDER: "fas fa-reply-all",
    SECOND_REPLY: "fas fa-reply",
    FINAL_DECISION: "fas fa-check-circle",
  };

  useEffect(() => {
    let stage = "";
    switch (currentStage) {
      case "INITIAL_DISPUTE":
        stage = lifecycleStages.INITIAL_DISPUTE;
        break;
      case "INITIAL_DECISION":
        stage = lifecycleStages.INITIAL_DECISION;
        break;
      case "FIRST_REPLY":
        stage = lifecycleStages.FIRST_REPLY;
        break;
      case "FIRST_REJOINDER":
        stage = lifecycleStages.FIRST_REJOINDER;
        break;
      case "SECOND_REPLY":
        stage = lifecycleStages.SECOND_REPLY;
        break;
      case "FINAL_DECISION":
        stage = lifecycleStages.FINAL_DECISION;
        break;
      default:
        break;
    }
    setLifecycleStage(stage);

    const updatedMessages = origins.map((origin) => ({
      ...origin,
      lifecycleStage: lifecycleStages[originMap[origin.origin]],
    }));
    setMessages(updatedMessages);
  }, [currentStage, origins]);

  return (
    <div className="lifecycle-container">
      {stages.map((stage, index) => {
        const stageKey = Object.keys(lifecycleStages).find(
          (key) => lifecycleStages[key] === stage,
        );
        const isHighlightedStage =
          stageKey === "INITIAL_DISPUTE" ||
          stageKey === "FIRST_REPLY" ||
          stageKey === "SECOND_REPLY";
        return (
          <div
            key={index}
            className={`lifecycle-stage ${
              stage === lifecycleStage ? "current" : ""
            } ${isHighlightedStage ? "highlighted-stage" : ""}`}
          >
            <div className="stage-name">
              <i className={iconMap[stageKey]}></i> {stage}
            </div>
            <div
              className={`stage-date ${
                stage === lifecycleStage ? "current" : ""
              }`}
            >
              {messages[index] &&
                new Date(messages[index].createdAt).toLocaleString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LifecycleStage;
