import * as yup from "yup";

export const createSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
    .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
    .matches(/\d/, "A senha deve conter pelo menos um número")
    .matches(
      /[^a-zA-Z0-9]/,
      "A senha deve conter pelo menos um caractere especial",
    )
    .test(
      "notSenha",
      'A senha não pode ser a palavra "senha"',
      function (value) {
        return !value || !value.toLowerCase().includes("senha");
      },
    )
    .test("sequence", "A senha não pode conter sequências", function (value) {
      const sequencePattern =
        /(0123|1234|2345|3456|4567|5678|6789|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/i;
      return !value || !sequencePattern.test(value);
    }),
});

export const updateSchema = yup.object().shape({
  password: yup.string(),
});
