import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/AuthLayout.js";
import { ToastContainer } from "react-toastify";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/msalConfig.js";
import { MsalProvider } from "@azure/msal-react";

import API from "service/api";
import qualityApi from "service/qualityApi.js";
import newApi from "service/newApi.js";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "react-toastify/dist/ReactToastify.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/css/custom-styles.css";
import "./assets/css/scroll.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const msalInstance = new PublicClientApplication(msalConfig);

export const ProtectedRoute = () => {
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");

  if (!accessToken || !refreshToken) {
    localStorage.clear();
    return <Redirect to="/auth/login" />;
  }

  API.defaults.headers["x-authorization"] = `Bearer ${accessToken}`;
  newApi.defaults.headers["x-authorization"] = `Bearer ${accessToken}`;
  qualityApi.defaults.headers["x-authorization"] = `Bearer ${accessToken}`;

  return <AdminLayout />;
};

root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <Switch>
        <Route path="/admin" render={() => <ProtectedRoute />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </MsalProvider>
    <ToastContainer limit={3} />
  </BrowserRouter>,
);
