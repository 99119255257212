import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "service/api";
import { Modal, Col, Button, FormGroup, Badge } from "reactstrap";

const ModalCustomMessage = (props) => {
  const [toggle, setToggle] = useState(false);
  const [, setMetrics] = useState([]);

  useEffect(() => {
    api
      .get(`/metrics?limit=100`)
      .then((response) => {
        setMetrics(response.data.metrics);
      })
      .catch(() => {
        toast.error("Erro ao carregar as métricas!");
      });
  }, []);
  const { titleModal, message, buttonName } = props;

  return (
    <Col lg="12" style={{ padding: 0 }}>
      <label
        className="form-control-label"
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
        onClick={() => setToggle(true)}
      >
        <Badge color="success">{buttonName}...</Badge>
      </label>
      <Modal
        className="modal-dialog modal-xl"
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h3>{titleModal}</h3>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="pl-lg-12">
            <Col className="pl-lg-12">
              <FormGroup>
                <div
                  className="styled-scroll"
                  style={{
                    width: "100%",
                    height: "auto",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                  dangerouslySetInnerHTML={{ __html: message }}
                ></div>
              </FormGroup>
            </Col>
          </div>
          <Button type="button" onClick={() => setToggle((v) => !v)}>
            Fechar
          </Button>
        </div>
      </Modal>
    </Col>
  );
};

export default ModalCustomMessage;
