import React, { useCallback } from "react";
import ModalConfirm from "components/Modal/ModalConfirm";
import { FormGroup } from "reactstrap";

const RenderContestationAction = ({
  currentStage,
  status,
  permissions,
  newMessage,
  setNewMessage,
  score,
  changeScore,
  contestation,
  loading,
  onSubmit,
  nextOrigin,
  originMap,
}) => {
  const handlerNewMessage = useCallback(
    (e) => {
      setNewMessage(e.target.value);
    },
    [setNewMessage],
  );
  switch (currentStage) {
    case "INITIAL_DISPUTE":
      if (
        permissions.includes("respond:quality:contestation") &&
        status !== "APPROVED"
      ) {
        return (
          <>
            <label className="form-control-label">
              Comentário para a {originMap[nextOrigin]}:
            </label>
            <textarea
              type="textarea"
              value={newMessage}
              onChange={handlerNewMessage}
              placeholder="Digite sua resposta..."
              style={{
                resize: "none",
                width: "80%",
                height: "100px",
                padding: "10px",
                marginBottom: "10px",
              }}
            />
            <>
              <FormGroup>
                <label
                  className="form-control-label align-self-center"
                  htmlFor="score"
                >
                  Nova nota:
                </label>
                <input
                  className="form-control custom-input ml-2"
                  style={{ width: "50px" }}
                  onChange={(e) => changeScore(e)}
                  value={score}
                />
              </FormGroup>
              <div className="pl-lg-2 d-flex mt-3 mb-3">
                <ModalConfirm
                  id={contestation.id}
                  buttonColor="success"
                  type="button"
                  buttonStyle={{ width: "auto" }}
                  modalText={`Esta ação irá APROVAR a contestação`}
                  disableButton={loading}
                  buttonText="Aprovar"
                  handler={() => onSubmit("APPROVED")}
                />
                <ModalConfirm
                  id={contestation.id}
                  buttonColor="danger"
                  type="button"
                  buttonStyle={{ width: "auto" }}
                  modalText={`Esta ação irá RECUSAR a contestação`}
                  disableButton={loading}
                  buttonText="Recusar"
                  handler={() => onSubmit("REJECTED")}
                />
              </div>
            </>
          </>
        );
      }
      break;

    case "INITIAL_DECISION":
      if (
        status !== "APPROVED" &&
        permissions.includes("create:quality:reply")
      ) {
        return (
          <>
            <label className="form-control-label">
              Comentário para a {originMap[nextOrigin]}:
            </label>
            <textarea
              type="textarea"
              value={newMessage}
              onChange={handlerNewMessage}
              placeholder="Digite sua resposta..."
              style={{
                resize: "none",
                width: "80%",
                height: "100px",
                padding: "10px",
                marginBottom: "10px",
              }}
            />
            <ModalConfirm
              id={contestation.id}
              buttonColor="default"
              type="button"
              buttonStyle={{ width: "auto" }}
              modalText={`Esta ação irá enviar uma réplica para a contestação`}
              disableButton={loading}
              buttonText="Enviar"
              handler={() => onSubmit("PENDING")}
            />
          </>
        );
      }
      break;

    case "FIRST_REPLY":
      if (
        permissions.includes("respond:quality:reply") &&
        status !== "APPROVED"
      ) {
        return (
          <>
            <label className="form-control-label">
              Comentário para a {originMap[nextOrigin]}:
            </label>
            <textarea
              type="textarea"
              value={newMessage}
              onChange={handlerNewMessage}
              placeholder="Digite sua resposta..."
              style={{
                resize: "none",
                width: "80%",
                height: "100px",
                padding: "10px",
                marginBottom: "10px",
              }}
            />
            <>
              <FormGroup>
                <label
                  className="form-control-label align-self-center"
                  htmlFor="score"
                >
                  Nova nota:
                </label>
                <input
                  className="form-control custom-input ml-2"
                  style={{ width: "50px" }}
                  onChange={(e) => changeScore(e)}
                  value={score}
                />
              </FormGroup>
              <div className="pl-lg-2 d-flex mt-3 mb-3">
                <ModalConfirm
                  id={contestation.id}
                  buttonColor="success"
                  type="button"
                  buttonStyle={{ width: "auto" }}
                  modalText={`Esta ação irá APROVAR a contestação`}
                  disableButton={loading}
                  buttonText="Aprovar"
                  handler={() => onSubmit("APPROVED")}
                />
                <ModalConfirm
                  id={contestation.id}
                  buttonColor="danger"
                  type="button"
                  buttonStyle={{ width: "auto" }}
                  modalText={`Esta ação irá RECUSAR a contestação`}
                  disableButton={loading}
                  buttonText="Recusar"
                  handler={() => onSubmit("REJECTED")}
                />
              </div>
            </>
          </>
        );
      }
      break;

    case "FIRST_REJOINDER":
      if (
        permissions.includes("create:quality:reply") &&
        status !== "APPROVED"
      ) {
        return (
          <>
            <label className="form-control-label">
              Comentário para a {originMap[nextOrigin]}:
            </label>
            <textarea
              type="textarea"
              value={newMessage}
              onChange={handlerNewMessage}
              placeholder="Digite sua resposta..."
              style={{
                resize: "none",
                width: "80%",
                height: "100px",
                padding: "10px",
                marginBottom: "10px",
              }}
            />
            <ModalConfirm
              id={contestation.id}
              buttonColor="default"
              type="button"
              buttonStyle={{ width: "auto", marginBottom: "10px" }}
              modalText={`Esta ação irá enviar uma replica para a contestação`}
              disableButton={loading}
              buttonText="Enviar"
              handler={() => onSubmit("PENDING")}
            />
          </>
        );
      }
      break;

    case "SECOND_REPLY":
      if (
        permissions.includes("respond:quality:final_reply") &&
        status !== "APPROVED"
      ) {
        return (
          <>
            <label className="form-control-label">
              Comentário para a {originMap[nextOrigin]}:
            </label>
            <textarea
              type="textarea"
              value={newMessage}
              onChange={handlerNewMessage}
              placeholder="Digite sua resposta..."
              style={{
                resize: "none",
                width: "80%",
                height: "100px",
                padding: "10px",
                marginBottom: "10px",
              }}
            />
            <>
              <FormGroup>
                <label
                  className="form-control-label align-self-center"
                  htmlFor="score"
                >
                  Nova nota:
                </label>
                <input
                  className="form-control custom-input ml-2"
                  style={{ width: "50px" }}
                  onChange={(e) => changeScore(e)}
                  value={score}
                />
              </FormGroup>
              <div className="pl-lg-2 d-flex mt-3 mb-3">
                <ModalConfirm
                  id={contestation.id}
                  buttonColor="success"
                  type="button"
                  buttonStyle={{ width: "auto" }}
                  modalText={`Esta ação irá APROVAR a contestação`}
                  disableButton={loading}
                  buttonText="Aprovar"
                  handler={() => onSubmit("APPROVED")}
                />
                <ModalConfirm
                  id={contestation.id}
                  buttonColor="danger"
                  type="button"
                  buttonStyle={{ width: "auto" }}
                  modalText={`Esta ação irá RECUSAR a contestação`}
                  disableButton={loading}
                  buttonText="Recusar"
                  handler={() => onSubmit("REJECTED")}
                />
              </div>
            </>
          </>
        );
      }
      break;

    case "FINAL_DECISION":
      return null;

    default:
      return null;
  }
};

export default RenderContestationAction;
