import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import ModalConfirm from "../components/Modal/ModalConfirm";
import usePagination from "hooks/usepagination";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  CardTitle,
  Col,
  CardBody,
  FormGroup,
  Badge,
} from "reactstrap";

const Prompts = () => {
  const { register, handleSubmit, reset } = useForm();
  const [, setCollapseOpen] = useState();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [, setOnLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [, setDisabled] = useState(false);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState([]);

  const permissions = localStorage.getItem("permissions");

  useEffect(() => {
    setOnLoad(true);
    api
      .get(`/prompts?page=${offset}&limit=${Number(limit)}&${search}`)
      .then((response) => response.data)
      .then((response) => {
        setRange(response.totalPages);
        setTotalCount(response.totalCount);
        setInfo(response.prompts);
        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar prompts!");
        return;
      });

    api
      .get(`/segments?page=1&limit=10000`)
      .then((response) => response.data)
      .then((response) => {
        setSegments(
          response.segments.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        );
        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar segmentos!");
        return;
      });
  }, [offset, search, limit]);

  const handleDelete = (id) => {
    setDisabled(true);
    api
      .delete(`/prompts/${id}`)
      .then(() => {
        toast.success("Prompt deletado com sucesso!");
        const infoFilter = info.filter((item) => item.id !== id);
        setInfo(infoFilter);
        setDisabled(false);
      })
      .catch(() => {
        toast.error("Erro ao deletar prompt!");
        setDisabled(false);
      });
  };

  const onSubmit = (data) => {
    const { title } = data;
    let query = "";
    if (title) {
      query += `title=${title}&`;
    }
    if (selectedSegment?.value) {
      query += `segmentId=${selectedSegment.value}&`;
    }
    setSearch(query);
  };

  const clearSearch = () => {
    let query = "";
    setSearch(query);
    reset();
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-2 pt-md-5 d-flex align-items-center justify-content-center flex-column ">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={5}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Segmentos</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Escolha um segmento"
                      value={selectedSegment}
                      onChange={setSelectedSegment}
                      isClearable={true}
                      isSearchable={true}
                      options={segments}
                    />
                  </FormGroup>
                </Col>
                <Col lg={5}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Título</label>
                    <input
                      name="title"
                      id="title"
                      className="Input"
                      style={{
                        display: "block",
                        width: "100%",
                        height: "calc(1.5em + 1.25rem + 2px)",
                        padding: "0.625rem 0.75rem",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      {...register("title")}
                    />
                  </FormGroup>
                </Col>
                <Col lg={1} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Button
                      className="Button ml-2"
                      type="submit"
                      style={{ height: "50px" }}
                    >
                      Buscar
                    </Button>
                  </FormGroup>
                </Col>
                <Col lg={1} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Button
                      className="Button ml-2"
                      onClick={clearSearch}
                      style={{ height: "50px" }}
                    >
                      Limpar
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody className="pb-1">
                      <Row>
                        <div className="d-flex flex-row align-items-center">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 mr-2"
                          >
                            Prompts
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalCount}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody className="pb-1 ">
                      <Row>
                        <div className="d-flex flex-row align-items-center">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 mr-2"
                          >
                            Paginas
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {range}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Título</th>
                    <th>Segmento</th>
                    <th>Cliente</th>
                    <th>Elementos reutilizáveis</th>
                    {permissions?.includes("update:prompt") && <th>Editar</th>}
                    {permissions?.includes("remove:prompt") && <th>Excluir</th>}
                  </tr>
                </thead>
                <tbody>
                  {info?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.title}</td>
                      <td>{item.segment.title}</td>
                      <td>{item.segment.client.name}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {item?.promptReusableElements?.map((pre, index) => {
                            return (
                              <Badge
                                key={index}
                                color="info"
                                pill
                                className="mr-1 mt-1"
                                title={pre?.reusableElement?.description}
                              >
                                {pre?.reusableElement?.title}
                              </Badge>
                            );
                          })}
                        </div>
                      </td>
                      {permissions?.includes("update:prompt") && (
                        <td>
                          <NavLink
                            to={`/admin/prompts-form?id=${item.id}`}
                            onClick={closeCollapse}
                            activeClassName="active"
                          >
                            Editar
                          </NavLink>
                        </td>
                      )}
                      {permissions?.includes("remove:prompt") && (
                        <td>
                          <ModalConfirm
                            id={item.id}
                            modalText={`Esta ação irá excluir o prompt ${item.title}, você tem certeza?`}
                            handler={() => {
                              handleDelete(item.id);
                            }}
                            icon={"fas fa-trash"}
                          />
                        </td>
                      )}
                      <td>
                        {item.template ? (
                          "-"
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="25"
                            height="25"
                          >
                            <path
                              fill="#c80000"
                              d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
                            />
                          </svg>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Prompts;
