import { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import qs from "query-string";
import { toast } from "react-toastify";
import api from "service/api";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import ModalUsers from "components/Modal/ModalUsers";

const RoleForm = () => {
  const { register, handleSubmit, reset } = useForm();
  const [, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [usersModal, setUsersModal] = useState(false);

  const onSubmit = (data) => {
    if (!usersModal) {
      setLoading(true);

      data.name = data.name.trim() ? data.name.trim() : name.trim();
      data.description = data.description.trim()
        ? data.description.trim()
        : description.trim();

      data.users = [...new Set(selectedUsers)];

      data.permissions = selectedPermissions.map(
        (permission) => permission.value,
      );

      if (hasId) {
        api
          .patch(`/role/${getParamsId()}`, data)
          .then(() => {
            toast.success("Cargo atualizado com sucesso!");
            setLoading(false);
          })
          .catch((error) => {
            if (error.response.data.statusCode === 409) {
              toast.error("Nome do cargo já está sendo utilizado!");
              setLoading(false);
              return;
            }
            toast.error("Erro ao atualizar cargo!");
            setLoading(false);
          });
      } else {
        api
          .post("/role", data)
          .then(() => {
            toast.success("Cargo cadastrado com sucesso!");
            setLoading(false);
            setSelectedPermissions([]);
            reset();
          })
          .catch((error) => {
            setLoading(false);

            if (error.response.data.statusCode === 409) {
              toast.error("Nome do cargo já está sendo utilizado!");
              return;
            }
            toast.error(JSON.stringify(error.response?.data?.message));
          });
      }
    }
  };

  const getPermissions = async () => {
    await api
      .get(`/permission`)
      .then((response) => {
        const keyValueArray = [];
        for (const key in response.data) {
          const label = response.data[key];
          keyValueArray.push({ value: key, label });
        }
        setPermissions(keyValueArray);
      })
      .catch(() => {
        toast.error("Erro ao recuperar permissões!");
        return;
      });
  };

  const getRole = async () => {
    const id = getParamsId();
    if (id) {
      await api
        .get(`/role/${id}`)
        .then((response) => {
          setName(response.data.name);
          setDescription(response.data.description);
          const rolePermissions = permissions.filter((permission) =>
            response.data?.permissions?.includes(permission.value),
          );
          setSelectedUsers(response.data.users.map((user) => user.id));
          setSelectedPermissions(rolePermissions);
        })
        .catch(() => {
          toast.error("Erro ao recuperar conteúdo!");
          return;
        });
      setHasId(true);
    }
  };

  useEffect(() => {
    if (!permissions.length) {
      getPermissions();
    }
    getRole();
  }, [permissions]);

  function getParamsId() {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  }

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="" xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Cadastro de cargo</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form id="create-role" onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Nome
                        </label>
                        <input
                          style={{
                            display: "block",
                            width: "100%",
                            height: "calc(1.5em + 1.25rem + 2px)",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                          }}
                          defaultValue={name}
                          className="form-control-alternative"
                          id="name"
                          placeholder="Nome"
                          type="text"
                          {...register("name")}
                          autoComplete="off"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Descrição
                        </label>
                        <input
                          style={{
                            display: "block",
                            width: "100%",
                            height: "calc(1.5em + 1.25rem + 2px)",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                          }}
                          defaultValue={description}
                          className="form-control-alternative"
                          id="description"
                          placeholder="Opcional"
                          type="text"
                          {...register("description")}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Permissões
                        </label>
                        <Select
                          defaultValue={selectedPermissions}
                          isMulti
                          name="colors"
                          options={Object.values(permissions)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Selecione as permissões"
                          value={selectedPermissions}
                          onChange={setSelectedPermissions}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" lg="8">
                      <ModalUsers
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        onClick={(value) => setUsersModal(value)}
                      />
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <Button type="submit">
                  {hasId ? "Atualizar" : "Adicionar"}
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default RoleForm;
