import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "service/api";
import { Modal, Col, Button, Table, FormGroup, Badge } from "reactstrap";

const RuleExample = () => {
  const [toggle, setToggle] = useState(false);
  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    api
      .get(`/metrics?limit=100`)
      .then((response) => {
        setMetrics(response.data.metrics);
      })
      .catch(() => {
        toast.error("Erro ao carregar as métricas!");
      });
  }, []);

  return (
    <Col lg="12" style={{ padding: 0 }}>
      <label
        className="form-control-label"
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
        onClick={() => setToggle(true)}
      >
        <Badge color="primary">Exemplo</Badge>
      </label>
      <Modal
        className="modal-dialog modal-xl"
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h3>Exemplo de formatação</h3>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="pl-lg-12">
            <Col className="pl-lg-12">
              <FormGroup>
                <div
                  className="styled-scroll"
                  style={{
                    width: "100%",
                    height: "auto",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <h3>Regra:</h3>
                  <p>NPS maior ou igual a 8</p>

                  <h3>Exemplo de mensagem gravada:</h3>
                  <p>Seu NPS é [NPS], continue assim.</p>

                  <h3>Exemplo de mensagem exibida para o usuário:</h3>
                  <p>Seu NPS é 9, continue assim.</p>
                </div>
                <div
                  className="styled-scroll"
                  style={{
                    width: "100%",
                    height: "auto",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <h3>Dicas:</h3>
                  <p>
                    Para inserir o valor de alguma métrica na mensagem, basta
                    colocar o código da métrica entre colchetes ( [ ] ){" "}
                  </p>
                </div>
                <h3>Códigos:</h3>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Código</th>
                      <th scope="col">Nome</th>
                    </tr>
                  </thead>
                  {metrics.length > 0 &&
                    metrics.map((metric, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>{metric.customKey}</td>
                            <td>{metric.name}</td>
                          </tr>
                        </tbody>
                      );
                    })}
                  <tbody>
                    <tr>
                      <td>NAME</td>
                      <td>Nome do usuário</td>
                    </tr>
                  </tbody>
                </Table>
              </FormGroup>
            </Col>
          </div>
          <Button type="button" onClick={() => setToggle((v) => !v)}>
            Fechar
          </Button>
        </div>
      </Modal>
    </Col>
  );
};

export default RuleExample;
