import { useState } from "react";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal, Col, Button } from "reactstrap";

const Modals = ({ response }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Col lg="12" style={{ padding: 0 }}>
      <label
        className="form-control-label"
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
        onClick={() => setToggle(true)}
      >
        {response}
      </label>
      <Modal
        className="modal-dialog modal-xl"
        style={{ marginTop: "100px" }}
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h3>Pesos das chaves após análise</h3>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <textarea
            style={{
              display: "block",
              width: "100%",
              maxHeight: "600px",
              height: "200px",
              resize: "none",
              padding: "4px",
              fontSize: "0.875rem",
              fontWeight: "400",
              lineHeight: "1.5",
              color: "#8898aa",
              backgroundColor: "#fff",
              backgroundClip: "padding-box",
              border: "1px solid #cad1d7",
              borderRadius: "0.375rem",
              boxShadow: "none",
              transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
            }}
            disabled
            value={response}
          ></textarea>
          <hr className="my-4" />
          <CopyToClipboard
            text={response}
            onCopy={() => toast.success("Resposta copiado com sucesso!")}
          >
            <Button type="button" color="primary">
              Copiar
            </Button>
          </CopyToClipboard>
          <Button type="button" onClick={() => setToggle((v) => !v)}>
            Fechar
          </Button>
        </div>
      </Modal>
    </Col>
  );
};

export default Modals;
