import { useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { Button, Modal, Row, Col, CardHeader } from "reactstrap";

const ModalProcessRules = ({ client }) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);

  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .post("/process/rules", { client_id: client.id })
      .then(() => {
        toast.success("Regras processadas com sucesso!");
        toggleModal("defaultModal");
      })
      .catch((error) => {
        console.error(error.response?.data?.message);
        toast.error("Erro ao processar as regras!");
        toast.error(error.response?.data?.message);
      });
  };

  return (
    <Row>
      <Col md="4">
        <Button
          block
          type="button"
          title="Gerar os conteúdos com as métricas a partir das regras abaixo."
          className="ml-3 mb-2 btn btn-success"
          style={{ width: "200px" }}
          onClick={() => toggleModal("defaultModal")}
        >
          Processar regras
        </Button>
        <Modal
          className="modal-dialog-centered modal-lg"
          isOpen={defaultModal}
          toggle={() => toggleModal("defaultModal")}
        >
          <div className="modal-header ">
            <CardHeader className="bg-white border-0 p-0">
              <Row>
                <Col>
                  <h3 className="mb-0">
                    Tem certeza que deseja processar regras e gerar conteúdos a
                    partir delas?
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <button
              aria-label="Close"
              className="close pb-0"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <Button type="submit" className="btn btn-success">
                Confirmar
              </Button>
            </form>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default ModalProcessRules;
