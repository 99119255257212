import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import qs from "query-string";
import ModalFavoriteForm from "components/Modal/ModalFavoriteForm";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardTitle,
  Col,
  CardBody,
} from "reactstrap";
import ModalConfirm from "../components/Modal/ModalConfirm";

const FavoritesUsers = () => {
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [totalCount, setTotalCount] = useState(0);
  const [search] = useState("");
  const [limit, setLimit] = useState(100);
  const [disabled] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [favoriteByClient, setFavoriteByClient] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [selectedFavorites, setSelectedFavorites] = useState([]);
  const [updateItem, setUpdateItem] = useState();
  const permissions = localStorage.getItem("permissions");

  function getParamsClient() {
    const queryParams = qs.parse(window.location.search);
    const client = queryParams.client;
    return client ? client : undefined;
  }

  function getParamsUsername() {
    const queryParams = qs.parse(window.location.search);
    const username = queryParams.username;
    return username ? username : undefined;
  }

  function getParamsTop() {
    const queryParams = qs.parse(window.location.search);
    const top = queryParams.top;
    return top ? top : false;
  }

  useEffect(() => {
    const client = getParamsClient();
    const username = getParamsUsername();
    const top = getParamsTop();
    if (!info.length) {
      if (top) {
        api
          .get(
            `/top-favorites?page=${offset}&limit=${Number(
              limit,
            )}&clientName=${client}&username=${username}`,
          )
          .then((response) => response.data)
          .then((response) => {
            setRange(response.totalPages);
            setTotalCount(response.totalCount);
            const favorites = response.topFavorites
              .map((item) => {
                const created = new Date(item.createdAt).toLocaleString(
                  "pt-BR",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  },
                );
                return {
                  id: item.id,
                  user: item.user.username,
                  title: item.title,
                  client: item.content.assistant.client.name,
                  category: item.category,
                  description: item.description,
                  status: item.status,
                  assistantName: item.content.assistant.name,
                  originalContent: item.content.text,
                  content_status: item.content.status,
                  content_id: item.content.id,
                  updated_at: item.updated_at,
                  created_at: created,
                };
              })
              .filter((item) => item.client === client);
            setInfo(favorites);
            checkedState.length
              ? setCheckedState(checkedState)
              : setCheckedState(new Array(favorites.length).fill(false));
            checkedState.length ??
              localStorage.setItem(
                "favorites" + username,
                JSON.stringify(favorites),
              );
            checkedState.length ??
              localStorage.setItem(
                "favoritesChecked" + username,
                JSON.stringify(new Array(favorites.length).fill(false)),
              );
            return favorites;
          })
          .then((favorites) => {
            const uniqueUser = Array.from(
              new Set(favorites.map((item) => item.user)),
            );
            const newArray = uniqueUser.map((user) => {
              return {
                user,
                favorites: favorites.filter((item) => item.user === user),
              };
            });
            setFavoriteByClient(newArray);
          });
      }
    }

    if (!top) {
      api
        .get(
          `/favorites?page=${offset}&limit=${Number(
            limit,
          )}&clientName=${client}&username=${username}`,
        )
        .then((response) => response.data)
        .then((response) => {
          setRange(response.totalPages);
          setTotalCount(response.totalCount);
          const favorites = response.favorites
            .map((item) => {
              const created = new Date(item.createdAt).toLocaleString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              });
              return {
                id: item.id,
                user: item.user.username,
                title: item.title,
                client: item.content.assistant.client.name,
                category: item.category,
                description: item.description,
                status: item.status,
                assistantName: item.content.assistant.name,
                originalContent: item.content.text,
                content_status: item.content.status,
                content_id: item.content.id,
                updated_at: item.updated_at,
                created_at: created,
              };
            })
            .filter((item) => item.client === client);
          setInfo(favorites);
          checkedState.length
            ? setCheckedState(checkedState)
            : setCheckedState(new Array(favorites.length).fill(false));
          checkedState.length ??
            localStorage.setItem(
              "favorites" + username,
              JSON.stringify(favorites),
            );
          checkedState.length ??
            localStorage.setItem(
              "favoritesChecked" + username,
              JSON.stringify(new Array(favorites.length).fill(false)),
            );
          return favorites;
        })
        .then((favorites) => {
          const uniqueUser = Array.from(
            new Set(favorites.map((item) => item.user)),
          );
          const newArray = uniqueUser.map((user) => {
            return {
              user,
              favorites: favorites.filter((item) => item.user === user),
            };
          });
          setFavoriteByClient(newArray);
        });
    }
  }, [offset, search, limit, updated]);

  useEffect(() => {
    if (updateItem) {
      const favorites = info.map((item) => {
        if (item.id === updateItem.id) {
          item.title = updateItem.title;
          item.description = updateItem.description;
          item.category = updateItem.category;
        }
        return item;
      });
      setInfo(favorites);
    }
  }, [updateItem]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  const handleOnChange = (position, favorite) => {
    const isChecked = checkedState[position];
    const updatedCheckedState = checkedState.map((item, index) => {
      if (index === position) {
        return !isChecked;
      } else {
        return item;
      }
    });

    let updatedSelectedFavorites;
    if (isChecked) {
      updatedSelectedFavorites = selectedFavorites.filter(
        (item) => item !== favorite,
      );
    } else {
      updatedSelectedFavorites = [...selectedFavorites, favorite];
    }
    setCheckedState(updatedCheckedState);
    setSelectedFavorites(updatedSelectedFavorites);
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    setSelectedFavorites(selectedFavorites);
    const top = getParamsTop();
    if (top) {
      const data = selectedFavorites.map((item) => {
        return {
          title: item.title,
          description: item.description,
          category: item.category,
          client: item.client,
          username: item.user,
          content_id: item.content_id,
          favorite_id: item.id,
        };
      });
      api
        .put("/top-favorites", data)
        .then(() => {
          toast.success("Top favoritos atualizados com sucesso!");
        })
        .catch(() => {
          toast.error("Erro ao atualizar top favoritos!");
        });
    } else {
      const data = selectedFavorites.map((item) => {
        return {
          title: item.title,
          description: item.description,
          category: item.category,
          client: item.client,
          username: item.user,
          content_id: item.content_id,
          favorite_id: item.id,
        };
      });
      api
        .post("/top-favorites", data)
        .then(() => {
          toast.success("Top favoritos enviados com sucesso!");
        })
        .catch(() => {
          toast.error("Erro ao enviar top favoritos!");
        });
    }
  };

  const handleDelete = (id) => {
    api
      .delete(`/top-favorites/${id}`)
      .then(() => {
        toast.success("Favorito removido com sucesso!");
        setInfo([]);
        setUpdated(!updated);
      })
      .catch(() => {
        toast.error("Erro ao remover favorito!");
      });
  };

  return (
    <>
      <Container className="mt-1" fluid>
        <div className="header bg-gradient-info pb-8 pt-0 pt-md-0 d-flex align-items-center justify-content-center flex-column"></div>
      </Container>
      <Container className="mt--7" fluid>
        <div className="container-fluid mb-1">
          <Row>
            <Col lg="6" xl="3">
              <Card className="card-stats ">
                <CardBody className="pb-1">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h6"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Favoritos
                      </CardTitle>
                      <span className="h2 mb-0">{totalCount}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0 ">
                <CardBody className="pb-1 ">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Paginas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{range}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <div className="align-items-center table-flush">
                {favoriteByClient &&
                  favoriteByClient.map((item, index) => {
                    return (
                      <CardBody key={index}>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th>Selecione</th>
                              <th>Categoria</th>
                              <th>Titulo</th>
                              <th>Descrição</th>
                              <th>Assistente</th>
                              {permissions?.includes("remove:top_favorite") && (
                                <>{getParamsTop() && <th>Excluir</th>}</>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {item.favorites.map((favorite, index) => (
                              <tr key={index + favorite.id}>
                                <td>
                                  <input
                                    type="checkbox"
                                    value={favorite}
                                    checked={checkedState[index]}
                                    onChange={() =>
                                      handleOnChange(index, favorite)
                                    }
                                  />
                                </td>
                                <td>{favorite.category}</td>
                                <td title={favorite.title}>
                                  {favorite.title.substring(0, 20)}
                                </td>
                                <td>
                                  <ModalFavoriteForm
                                    content={favorite}
                                    setUpdated={setUpdated}
                                    updated={updated}
                                    setUpdateItem={setUpdateItem}
                                    updateItem={updateItem}
                                  />
                                </td>
                                <td>{favorite.assistantName}</td>
                                {permissions?.includes(
                                  "remove:top_favorite",
                                ) && (
                                  <>
                                    {getParamsTop() && (
                                      <td>
                                        <ModalConfirm
                                          id={item.id}
                                          modalText={`Esta ação irá excluir o favorito ${favorite.title}, você tem certeza?`}
                                          handler={() => {
                                            handleDelete(item.id);
                                          }}
                                          icon={"fas fa-trash"}
                                        />
                                      </td>
                                    )}
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {(permissions?.includes("create:top_favorite") ||
                          permissions?.includes("update:top_favorite")) && (
                          <Button
                            className="btn btn-success"
                            onClick={handleSubmitData}
                          >
                            {getParamsTop() ? "Atualizar" : "Selecionar"}
                          </Button>
                        )}
                      </CardBody>
                    );
                  })}
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default FavoritesUsers;
