import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import Modals from "components/Modal/ModalContent";
import ModalOriginalContent from "components/Modal/ModalOriginalContent";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  CardTitle,
  Col,
  CardBody,
} from "reactstrap";

const Favorites = () => {
  const { register, handleSubmit, reset } = useForm();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [, setOnLoad] = useState(false);
  const [, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [disabled, setIsdisabled] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [clients, setClient] = useState([]);
  const permissions = localStorage.getItem("permissions");

  const favoriteStatus = {
    PENDING: "Pendente",
    READY: "Pronto",
    FAILED: "Falhou",
  };

  useEffect(() => {
    setOnLoad(true);
    api
      .get(
        `/favorites?page=${offset}&limit=${Number(
          limit,
        )}&status=PENDING&${search}`,
      )
      .then((response) => response.data)
      .then((response) => {
        setRange(response.totalPages);
        setTotalCount(response.totalCount);

        const favorites = response.favorites.map((item) => {
          const created = new Date(item.createdAt).toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return {
            id: item.id,
            user: item.user.username,
            title: item.title,
            category: item.category,
            description: item.description,
            status: item.status,
            assistantName: item.content.assistant.name,
            originalContent: item.content.text,
            content_status: item.content_status,
            content_id: item.content_id,
            updated_at: item.updated_at,
            created_at: created,
          };
        });
        setInfo(favorites);
      })
      .catch(() => {
        toast.error("Erro ao recuperar favoritos!");
        return;
      });

    if (!clients.length) {
      api
        .get("/clients")
        .then((response) => {
          const clients = response.data;
          const clientOptions = [...clients.clients];
          setClient(clientOptions);
        })
        .catch(() => {
          toast.error("Erro ao recuperar clientes!");
          return;
        });
    }
  }, [offset, search, limit, updated]);

  const handleEdit = (id, status) => {
    const data = {
      status,
    };
    api
      .patch(`/favorites/${id}`, data)
      .then(() => {
        toast.success("Favorito aprovado!");
        const infoFilter = info.filter((item) => item.id !== id);
        setInfo(infoFilter);
      })
      .catch(() => {
        toast.error("Erro ao aprovar favorito!");
      })
      .catch(() => {
        toast.error("Erro ao atualizar favorito!");
        return;
      });
  };

  const handleDelete = (id) => {
    setIsdisabled(true);
    api
      .delete(`/favorites/${id}`)
      .then(() => {
        toast.success("Favorito deletado com sucesso!");
        const infoFilter = info.filter((item) => item.id !== id);
        setInfo(infoFilter);
        setIsdisabled(false);
      })
      .catch(() => {
        toast.error("Erro ao deletar favorito!");
        setIsdisabled(false);
      });
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const onSubmit = (data) => {
    const { username, clientName } = data;
    let query = "";
    if (username) {
      query += `username=${username}&`;
    }
    if (clientName) {
      query += `clientName=${clientName}&`;
    }
    setSearch(query);
  };

  const clearSearch = () => {
    let query = "";
    setSearch(query);
    reset();
  };

  return (
    <>
      <Container className="mt-1" fluid>
        <div className="header bg-gradient-info pb-8 pt-3 pt-md-3 d-flex align-items-center justify-content-center flex-column">
          <div className="container-fluid ">
            <Form onSubmit={handleSubmit(onSubmit)} className="d-flex">
              <select
                name="username"
                id="username"
                className="Input"
                style={{
                  display: "block",
                  width: "20%",
                  minWidth: "180px",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #cad1d7",
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                  marginRight: "20px",
                }}
                {...register("clientName")}
              >
                <option value="">Selecione um cliente</option>
                {clients.map((client, index) => {
                  return (
                    <option value={client.name} key={index}>
                      {client.name}
                    </option>
                  );
                })}
              </select>
              <input
                id="user"
                name="user"
                placeholder="Filtrar por usuário"
                className="Input"
                style={{
                  display: "block",
                  width: "200px",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #cad1d7",
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                  marginRight: "10px",
                }}
                {...register("username")}
              />
              <Button
                className="Button ml-2"
                type="submit"
                style={{ height: "42px" }}
              >
                Buscar
              </Button>
              <Button
                className="Button ml-2"
                onClick={clearSearch}
                style={{ height: "42px" }}
              >
                Limpar
              </Button>
            </Form>
          </div>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <div className="container-fluid mb-3">
          <Row>
            <Col lg="6" xl="3">
              <Card className="card-stats ">
                <CardBody className="pb-1">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h6"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Favoritos pendentes
                      </CardTitle>
                      <span className="h2 mb-0">{info.length}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0 ">
                <CardBody className="pb-1 ">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Paginas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{range}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Usuário</th>
                    <th>Categoria</th>
                    <th>Status</th>
                    <th>Descrição</th>
                    <th>Conteúdo Original</th>
                    <th>Assistente</th>
                    <th>Titulo</th>
                    <th>Data de criação</th>
                    {permissions?.includes("approve:favorite") &&
                      permissions?.includes("disapprove:favorite") && (
                        <th>Ações</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>{item.user}</td>
                      <td>{item.category}</td>
                      <td>{favoriteStatus[item.status]}</td>
                      <td>
                        <Modals
                          content={item}
                          setUpdated={setUpdated}
                          updated={updated}
                        />
                      </td>
                      <td>
                        <ModalOriginalContent
                          content={item.originalContent}
                          originalContent={item.originalContent}
                        />
                      </td>
                      <td>{item.assistantName}</td>
                      <td title={item.title}>{item.title.substring(0, 20)}</td>
                      <td>{item.created_at}</td>
                      {permissions?.includes("approve:favorite") &&
                        permissions?.includes("disapprove:favorite") && (
                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                className="Button"
                                color="success"
                                onClick={() => handleEdit(item.id, "OK")}
                                disabled={disabled}
                              >
                                Aprovar
                              </Button>
                              <Button
                                className="Button"
                                color="danger"
                                onClick={() => handleDelete(item.id)}
                                disabled={disabled}
                              >
                                Reprovar
                              </Button>
                            </div>
                          </td>
                        )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Favorites;
