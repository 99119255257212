import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import ModalConfirm from "../components/Modal/ModalConfirm";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  CardTitle,
  Col,
  CardBody,
} from "reactstrap";

const Roles = () => {
  const [, setCollapseOpen] = useState();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [, setOnLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [, setDisabled] = useState(false);
  const permissions = localStorage.getItem("permissions");

  useEffect(() => {
    setOnLoad(true);
    api
      .get(`/role?page=${offset}&limit=${Number(limit)}&${search}`)
      .then((response) => response.data)
      .then((response) => {
        setRange(response.totalPages ?? 0);
        setTotalCount(response.totalCount ?? 0);
        setInfo(response.roles);
        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar cargos!");
        return;
      });
  }, [offset, search, limit]);

  const handleDelete = (id) => {
    setDisabled(true);
    api
      .delete(`/role/${id}`)
      .then(() => {
        toast.success("Cargo deletado com sucesso!");
        const infoFilter = info.filter((item) => item.id !== id);
        setInfo(infoFilter);
        setDisabled(false);
      })
      .catch(() => {
        toast.error("Erro ao deletar cargo!");
        setDisabled(false);
      });
  };

  const onSubmit = (data) => {
    const { name } = data;
    let query = "";

    if (name) {
      query += `name=${name}&`;
    }
    setSearch(query);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-2 pt-md-5 d-flex align-items-center justify-content-center flex-column ">
          <div className="container-fluid">
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody className="pb-1">
                    <Row>
                      <div className="d-flex flex-row align-items-center">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 mr-2"
                        >
                          Cargos
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {totalCount}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody className="pb-1 ">
                    <Row>
                      <div className="d-flex flex-row align-items-center">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 mr-2"
                        >
                          Paginas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {range}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>Descrição</th>
                    {permissions?.includes("update:role") && <th>Editar</th>}
                    {permissions?.includes("remove:role") && <th>Excluir</th>}
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      {permissions?.includes("update:role") && (
                        <td>
                          <NavLink
                            to={`/admin/roles-form?id=${item.id}`}
                            onClick={closeCollapse}
                            activeClassName="active"
                          >
                            Editar
                          </NavLink>
                        </td>
                      )}
                      {permissions?.includes("remove:role") && (
                        <td>
                          <ModalConfirm
                            id={item.id}
                            modalText={`Esta ação irá excluir o cargo ${item.name}, você tem certeza?`}
                            handler={() => {
                              handleDelete(item.id);
                            }}
                            icon={"fas fa-trash"}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Roles;
