const AnalysisStatuses = Object.freeze({
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
  EXCEEDED_TOKENS: "EXCEEDED_TOKENS",
  NOT_ENOUGH_BUDGET: "NOT_ENOUGH_BUDGET",
  INVALID_ATTENDANCE: "INVALID_ATTENDANCE",
});
class AnalysisStatusService {
  static getTranslatedStatus(status) {
    switch (status) {
      case AnalysisStatuses.PROCESSING:
        return "Em andamento";
      case AnalysisStatuses.COMPLETED:
        return "Finalizado";
      case AnalysisStatuses.ERROR:
        return "Erro";
      case AnalysisStatuses.EXCEEDED_TOKENS:
        return "Tokens excedidos";
      case AnalysisStatuses.NOT_ENOUGH_BUDGET:
        return "Saldo insuficiente para análise";
      case AnalysisStatuses.INVALID_ATTENDANCE:
        return "Atendimento com parâmetros inválidos";
      default:
        return "N/A";
    }
  }

  static getStatusClassColor(status) {
    switch (status) {
      case AnalysisStatuses.PROCESSING:
        return "primary";
      case AnalysisStatuses.COMPLETED:
        return "success";
      case AnalysisStatuses.ERROR:
        return "error";
      case AnalysisStatuses.EXCEEDED_TOKENS:
        return "warning";
      case AnalysisStatuses.NOT_ENOUGH_BUDGET:
        return "warning";
      case AnalysisStatuses.INVALID_ATTENDANCE:
        return "error";
      default:
        return "primary";
    }
  }

  static getStatusCssColor(status) {
    switch (status) {
      case AnalysisStatuses.PROCESSING:
        return {
          color: "#084298",
          background: "#CFE2FF",
        };
      case AnalysisStatuses.COMPLETED:
        return {
          color: "#0F5132",
          background: "#D1E7DD",
        };
      case AnalysisStatuses.ERROR:
        return {
          color: "#842029",
          background: "#F8D7DA",
        };
      case AnalysisStatuses.EXCEEDED_TOKENS:
        return {
          color: "#664D03",
          background: "#FFF3CD",
        };
      case AnalysisStatuses.NOT_ENOUGH_BUDGET:
        return {
          color: "#664D03",
          background: "#FFF3CD",
        };
      case AnalysisStatuses.INVALID_ATTENDANCE:
        return {
          color: "#842029",
          background: "#F8D7DA",
        };
      default:
        return {
          color: "#084298",
          background: "#CFE2FF",
        };
    }
  }
}

export { AnalysisStatuses, AnalysisStatusService };
