import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import Select from "react-select";
import { Modal, Button, FormGroup } from "reactstrap";
import qualityApi from "service/qualityApi";

const ModalUserAttendanceFeedback = ({
  title,
  disabled,
  consultant,
  attendanceId,
  setUpdateAttendance,
  updateAttendance,
}) => {
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchableConsultant, setSearchableConsultant] = useState("");
  const [enableSelect, setEnableSelect] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [users, setUsers] = useState([]);

  const getConsultantByExternalId = async (data) => {
    api
      .get(`/users?externalId=${data}`)
      .then((response) => {
        setSearchableConsultant(response.data.users[0].username);
        setSelectedUser({
          value: response.data.users[0].id,
          label: response.data.users[0].username,
        });
        setUsers(response.data.users);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (consultant && toggle) {
      setLoading(true);
      getConsultantByExternalId(consultant);
    }
  }, [consultant, toggle]);

  const onSearchConsultant = async () => {
    if (!enableSelect) {
      api
        .get(`/users?username=${searchableConsultant}`)
        .then((response) => {
          setUsers(
            response?.data?.users?.map((user) => ({
              value: user.id,
              label: user.username,
            })),
          );
          setSelectedUser({
            value: response?.data?.users?.[0]?.id,
            label: response?.data?.users?.[0]?.username,
          });
          setEnableSelect(true);
        })
        .catch(() => {
          toast.error("Erro ao buscar consultor!");
        });
    } else {
      setEnableSelect(false);
    }
  };

  const createUserFeedback = () => {
    qualityApi
      .post(`/user-attendance-feedback`, {
        attendanceId,
        receivedByUserId: selectedUser.value,
      })
      .then(() => {
        toast.success("Feedback aplicado com sucesso!");
        setToggle(false);
        setUpdateAttendance(!updateAttendance);
      })
      .catch((e) => {
        if (
          e.response.data.message == "User attendance feedback already exists."
        ) {
          toast.error("Já existe um feedback pendente para este atendimento");
        } else {
          toast.error("Erro ao aplicar feedback!");
        }
      });
  };

  return (
    <div>
      <Button
        className="Button ml-1"
        size="sm"
        color={disabled ? "success" : "primary"}
        onClick={() => setToggle(true)}
        disabled={disabled}
      >
        {title}
      </Button>
      <Modal
        className="modal-dialog modal-md"
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <p>Confirme o consultor que receberá o feedback: </p>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <div className="pl-lg-2">
            {loading ? (
              <div className="text-center">
                <i className="fa fa-spinner fa-spin fa-2x"></i>
              </div>
            ) : (
              <FormGroup>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Consultor :
                  </label>
                  {!enableSelect ? (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pesquise o consultor..."
                      style={{
                        display: "block",
                        width: "200px",
                        height: "40px",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                        marginBottom: "4px",
                        transition:
                          "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                      }}
                      value={searchableConsultant}
                      onChange={(e) => setSearchableConsultant(e.target.value)}
                    />
                  ) : (
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: "40px",
                          width: "200px",
                          minHeight: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "4px",
                        }),
                      }}
                      defaultValue={selectedUser}
                      value={selectedUser}
                      onChange={setSelectedUser}
                      isClearable={true}
                      isSearchable={true}
                      options={users}
                    />
                  )}
                  <Button
                    className="ml-2 mb-1"
                    color="primary"
                    style={{
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={onSearchConsultant}
                  >
                    {!enableSelect ? (
                      <i
                        className="fas fa-search"
                        style={{ color: "white" }}
                      ></i>
                    ) : (
                      <i
                        className="fas fa-times"
                        style={{ color: "white" }}
                      ></i>
                    )}
                  </Button>
                </div>
                <div></div>

                <br />
                <Button
                  type="submit"
                  color="success"
                  onClick={createUserFeedback}
                >
                  Salvar e enviar
                </Button>
              </FormGroup>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalUserAttendanceFeedback;
