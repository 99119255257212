/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import { Modal, Col, Button, Table, FormGroup, Badge } from "reactstrap";

const Modals = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <Col lg="12" style={{ padding: 0 }}>
      <label
        className="form-control-label"
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
        onClick={() => setToggle(true)}
      >
        <Badge color="primary">Exemplo</Badge>
      </label>
      <Modal
        className="modal-dialog modal-xl"
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h3>Exemplo formato ( csv )</h3>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="pl-lg-12">
            <Col className="pl-lg-12">
              <FormGroup>
                <label className="form-control-label">Exemplo</label>
                <div
                  className="styled-scroll"
                  style={{
                    width: "100%",
                    height: "330px",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <Table
                    style={{
                      tableLayout: "fixed",
                      width: "100%",
                    }}
                  >
                    <thead className="thead-ligth">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">DIRECTION</th>
                        <th scope="col">MESSAGE</th>
                        <th scope="col">DATE</th>
                        <th scope="col">CONSULTANT_EXTERNAL_ID</th>
                        <th scope="col">SUPERVISOR_EXTERNAL_ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Atendimento-3054_20230721-114528_19983722768_1689950718
                        </td>
                        <td>OPERADOR</td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          obrigado pela confirmação representante da web health
                          operadora tim motivo
                        </td>
                        <td>20/03/2023</td>
                        <td>silva.fulano</td>
                        <td>santos.ciclano</td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Atendimento-3054_20230721-114528_19983722768_1689950718
                        </td>
                        <td>CLIENTE</td>
                        <td>é ela</td>
                        <td>20/03/2023</td>
                        <td>silva.fulano</td>
                        <td>santos.ciclano</td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Atendimento-3054_20230721-114528_19983722768_1689950718
                        </td>
                        <td>CLIENTE</td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          olha, eu já fiz esse acordo, ficou 1 boleto pra
                          segunda eu tô recebendo ligação a cada 15 minutos
                        </td>
                        <td>20/03/2023</td>
                        <td>silva.fulano</td>
                        <td>santos.ciclano</td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Atendimento-3054_20230721-114528_19983722768_1689950718
                        </td>
                        <td>OPERADOR</td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          confirmou
                        </td>
                        <td>20/03/2023</td>
                        <td>silva.fulano</td>
                        <td>santos.ciclano</td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Atendimento-3054_20230721-114528_19983722768_1689950718
                        </td>
                        <td>OPERADOR</td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          é o helica ponto penteado arroba
                        </td>
                        <td>20/03/2023</td>
                        <td>silva.fulano</td>
                        <td>santos.ciclano</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </FormGroup>
            </Col>
          </div>
          <div className="pl-lg-12">
            <Col className="pl-lg-12">
              <label className="form-control-label">
                Obs*: As colunas precisam estar em caixa alta: ID, DIRECTION,
                MESSAGE, DATE, CONSULTANT_EXTERNAL_ID, SUPERVISOR_EXTERNAL_ID
              </label>
              <label className="form-control-label">
                As colunas CONSULTANT_EXTERNAL_ID, SUPERVISOR_EXTERNAL_ID
                referentes ao login de rede do consultor e supervisor, são
                opcionais.
              </label>
            </Col>
          </div>
          <hr className="my-4" />
          <Button type="button" onClick={() => setToggle((v) => !v)}>
            Fechar
          </Button>
        </div>
      </Modal>
    </Col>
  );
};

export default Modals;
